@font-face {
  font-family: 'Andes_Regular';
  src: url('/Content/Fonts/Andes.eot');
  src: url('/Content/Fonts/Andes.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/Andes.woff2') format('woff2'),
       url('/Content/Fonts/Andes.woff') format('woff'),
       url('/Content/Fonts/Andes.ttf') format('truetype'),
       url('/Content/Fonts/Andes.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'andesbold';
  src: url('/Content/Fonts/andesbold-webfont.eot');
  src: url('/Content/Fonts/andesbold-webfont.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/andesbold-webfont.woff') format('woff'),
       url('/Content/Fonts/andesbold-webfont.ttf') format('truetype'),
       url('/Content/Fonts/andesbold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'andesbook';
  src: url('/Content/Fonts/andesbook-webfont.eot');
  src: url('/Content/Fonts/andesbook-webfont.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/andesbook-webfont.woff') format('woff'),
       url('/Content/Fonts/andesbook-webfont.ttf') format('truetype'),
       url('/Content/Fonts/andesbook-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'andescondensedblack';
  src: url('/Content/Fonts/andescondensedblack-webfont.eot');
  src: url('/Content/Fonts/andescondensedblack-webfont.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/andescondensedblack-webfont.woff') format('woff'),
       url('/Content/Fonts/andescondensedblack-webfont.ttf') format('truetype'),
       url('/Content/Fonts/andescondensedblack-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'andescondensedbold';
  src: url('/Content/Fonts/andescondensedbold-webfont.eot');
  src: url('/Content/Fonts/andescondensedbold-webfont.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/andescondensedbold-webfont.woff') format('woff'),
       url('/Content/Fonts/andescondensedbold-webfont.ttf') format('truetype'),
       url('/Content/Fonts/andescondensedbold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AndesCondensed_ExtraBold';
  src: url('/Content/Fonts/andescondensedextrabold-webfont.eot');
  src: url('/Content/Fonts/andescondensedextrabold-webfont.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/andescondensedextrabold-webfont.woff') format('woff'),
       url('/Content/Fonts/andescondensedextrabold-webfont.ttf') format('truetype'),
       url('/Content/Fonts/andescondensedextrabold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'andescondensedsemibold';
  src: url('/Content/Fonts/andescondensedsemibold-webfont.eot');
  src: url('/Content/Fonts/andescondensedsemibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/andescondensedsemibold-webfont.woff') format('woff'),
       url('/Content/Fonts/andescondensedsemibold-webfont.ttf') format('truetype'),
       url('/Content/Fonts/andescondensedsemibold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AndesExtraBold';
  src: url('/Content/Fonts/AndesExtraBold.eot');
  src: url('/Content/Fonts/AndesExtraBold.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/AndesExtraBold.woff') format('woff'),
       url('/Content/Fonts/AndesExtraBold.ttf') format('truetype'),
       url('/Content/Fonts/AndesExtraBold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AndesLight';
  src: url('/Content/Fonts/AndesLight.eot');
  src: url('/Content/Fonts/AndesLight.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/AndesLight.woff') format('woff'),
       url('/Content/Fonts/AndesLight.ttf') format('truetype'),
       url('/Content/Fonts/AndesLight.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Andes_Medium';
  src: url('/Content/Fonts/andesmedium-webfont.eot');
  src: url('/Content/Fonts/andesmedium-webfont.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/andesmedium-webfont.woff') format('woff'),
       url('/Content/Fonts/andesmedium-webfont.ttf') format('truetype'),
       url('/Content/Fonts/andesmedium-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Andes_Semibold';
  src: url('/Content/Fonts/AndesSemiBold.eot');
  src: url('/Content/Fonts/AndesSemiBold.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/AndesSemiBold.woff') format('woff'),
       url('/Content/Fonts/AndesSemiBold.ttf') format('truetype'),
       url('/Content/Fonts/AndesSemiBold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'andessemibold';
  src: url('/Content/Fonts/andessemibold-webfont.eot');
  src: url('/Content/Fonts/andessemibold-webfont.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/andessemibold-webfont.woff') format('woff'),
       url('/Content/Fonts/andessemibold-webfont.ttf') format('truetype'),
       url('/Content/Fonts/andessemibold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'fontello';
  src: url('/Content/Fonts/fontello.eot');
  src: url('/Content/Fonts/fontello.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/fontello.woff') format('woff'),
       url('/Content/Fonts/fontello.ttf') format('truetype'),
       url('/Content/Fonts/fontello.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura_Std_Heavy';
  src: url('/Content/Fonts/Futura Std Heavy.eot');
  src: url('/Content/Fonts/Futura Std Heavy.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/Futura Std Heavy.woff') format('woff'),
       url('/Content/Fonts/Futura Std Heavy.ttf') format('truetype'),
       url('/Content/Fonts/Futura Std Heavy.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FuturaStd-Medium';
  src: url('/Content/Fonts/FuturaStd-Medium.eot');
  src: url('/Content/Fonts/FuturaStd-Medium.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/FuturaStd-Medium.woff') format('woff'),
       url('/Content/Fonts/FuturaStd-Medium.ttf') format('truetype'),
       url('/Content/Fonts/FuturaStd-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham-Bold';
  src: url('/Content/Fonts/Gotham-Bold.eot');
  src: url('/Content/Fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/Gotham-Bold.woff') format('woff'),
       url('/Content/Fonts/Gotham-Bold.ttf') format('truetype'),
       url('/Content/Fonts/Gotham-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GothamBook';
  src: url('/Content/Fonts/GothamBook.eot');
  src: url('/Content/Fonts/GothamBook.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/GothamBook.woff') format('woff'),
       url('/Content/Fonts/GothamBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KelloggsSans-Bold';
  src: url('/Content/Fonts/KelloggsSans-Bold.eot');
  src: url('/Content/Fonts/KelloggsSans-Bold.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/KelloggsSans-Bold.woff') format('woff'),
       url('/Content/Fonts/KelloggsSans-Bold.ttf') format('truetype'),
       url('/Content/Fonts/KelloggsSans-Bold.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KelloggsSans-Medium';
  src: url('/Content/Fonts/KelloggsSans-Medium.eot');
  src: url('/Content/Fonts/KelloggsSans-Medium.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/KelloggsSans-Medium.woff') format('woff'),
       url('/Content/Fonts/KelloggsSans-Medium.ttf') format('truetype'),
       url('/Content/Fonts/KelloggsSans-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'KelloggsSans-Regular';
  src: url('/Content/Fonts/KelloggsSans-Regular.eot');
  src: url('/Content/Fonts/KelloggsSans-Regular.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/KelloggsSans-Regular.woff') format('woff'),
       url('/Content/Fonts/KelloggsSans-Regular.ttf') format('truetype'),
       url('/Content/Fonts/KelloggsSans-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Pringles-SemiSerif';
  src: url('/Content/Fonts/Pringles-SemiSerif.eot');
  src: url('/Content/Fonts/Pringles-SemiSerif.eot?#iefix') format('embedded-opentype'),
       url('/Content/Fonts/Pringles-SemiSerif.woff') format('woff'),
       url('/Content/Fonts/Pringles-SemiSerif.ttf') format('truetype'),
       url('/Content/Fonts/Pringles-SemiSerif.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

//fontello
[class^="icon-"]:before, [class*=" icon-"]:before {
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	text-align: center;

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;
	
	/* fix buttons height, for twitter bootstrap */
	// line-height: 1em;

	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: none;
	-moz-osx-font-smoothing: none;

	/* Uncomment for 3D effect */
	text-shadow: none;
}
.icon-twitter:before { content: '\e800'; } /* '' */
.icon-facebook:before { content: '\e801'; } /* '' */
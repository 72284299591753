section.comingsoon-page,
section.closed {
        .content {
            h1{
                @include font-vw($mobile: 36px, $tablet: 26px, $desktop: 30px);
            }
            a.button {
                width: auto;
                margin: 7vw auto 0;
                @include respond-to(tablet){
                    margin: 2vw auto 0;
                }
            }
        }
        p {
            @include font-vw($mobile: 26px, $tablet: 22px, $desktop: 22px);
            font-family: $font-andes-regular;
            font-weight: 400;
            margin: 0 auto 2%;
            padding: 0;
            width: auto;
            line-height: 1.3em;
            @include respond-to(tablet) {
                margin-bottom: 1vw;
                padding: 0;
                // width: 75%;
            }
            p {
                margin: 0 auto 7vw;
                @include respond-to(tablet) {
                    margin: 0 auto 4vw;
                }
            }
        }
        footer {
            background-color: transparent;
            padding: 0;
            margin: 0 auto 6%;
            text-align: center;
            .footer-menu {
                width: 100%;
                text-align: center;
            }
            li a,
            li p {
                @include font-vw($mobile: 24px, $tablet: 15px, $desktop: 15px);
                font-weight: 400;
                margin: 0 auto;
                border: none;
            }
        }
}
.it_it {
    .batchcode-page .twocols > div:first-child #upcCodeForm input[type="text"] {
        margin-bottom: 1vw !important;
    }
    .batchcode-page .twocols > div:first-child .button.small {
        @include border-radius(20px);
        font-size: 15px;
    }
    .home {
        button span {
            @include respond-to(desktop) {
                font-size: 34px;
            }
        }
    }
    .home .twocols > div button div {
        @include respond-to(desktop) {
            padding: 0.3em 0.4em 0.45em;
        }
    }
    &.mac {
        .login,
        .register {
            a.button {
                div {
                    @include respond-to(mobile-only) {
                        padding: 0.5em 0.4em 0.6em;
                    }
                }
            }
        }
    }
    .home .twocols > div h2 {
        @include font-vw($mobile: $h2-fs--mobile, $tablet: 25px, $desktop-lg: 27px);
        text-transform: uppercase;
    }
    .thankyou .content .button {
        @include respond-to(tablet-only) {
            .btn-copy {
                font-size: 2.5vw;
                line-height: 1.2em;
                padding-left: 0.15em;
                padding-right: 0.15em;
            }
        }
    }
    @include respond-to(tablet-only) {
        .thankyou-pdf .twocols .button {
            max-width: 100%;
        }
    }
    .thankyou .container .col-one {
        .thankyou-pdf .twocols .button,
        .button {
            @include respond-to(mobile-only) {
                width: 85%;
            }
        }
    }
    .thankyou .container .col-one .thankyou-pdf .twocols .button {
        @include respond-to(desktop-xl) {
            width: 70%;
            max-width: 70%;
        }
    }
    .comingsoon {
        .col-one {
            @include respond-to(tablet-only) {
                p {
                    width: 80%;
                }
            }

            @include respond-to(desktop-xl) {
                h2 {
                    font-size: 44px;
                }
                P {
                    max-width: 70%;
                }
            }
        }
    }
}
.ru_ru {
    &.mac {
        .home .twocols > div button div {
            padding-top: 0.35em;
        }
    }
}
.sr_rs,
.ro_ro,
.sq_al,
.en_gr,
.sr_rs{
    #KSTL-Registration-wrapper-Country div > div:nth-of-type(2) > div{
        margin-bottom: 5px;
    }
}

.en_gr,
.en_gb,
.en_eg,
.pt_pt,
.et_ee {
    @include respond-to(mobile-only) {
        .thankyou {
            button,
            a.button {
                &,
                div {
                    font-size: initial;
                }
                div {
                    padding: 0.5em 0.2em 0.5em;
                }
            }
        }
    }
    @include respond-to(tablet-only) {
        .thankyou {
            .button,
            button {
                div {
                    padding: 0.5em 0.3em 0.5em;
                }
            }
        }
        .thankyou .container .col-one .thankyou-pdf .twocols {
            button,
            a.button {
                width: 90%;
            }
        }
    }
}
//iphone fixes
.mac {
    &.en_gr,
    &.en_eg,
    &.en_gb,
    &.et_ee,
    &.pt_pt {
        @include respond-to(mobile-only) {
            .home .twocols > div button div {
                padding-top: 0.5em;
                padding-bottom: 0.55em;
            }
        }
    }
}

.mac,
.iphone,
.ipad {
    #reg-container .KSTL-Registration .pod .ng-form button > span {
        margin: 0 auto;
    }
    .crossword .words-to-find .tooltip-top-left .checked-word p {
        margin: 0;
    }
}
.iphone,
.ipad {
    button,
    a.button,
    label.button,
    span.button {
        padding: 14px 1em 10px;
    }
}
.android {
    button,
    a.button,
    label.button,
    span.button {
        padding: 10px 1em 12px;
    }
}
.no_no,
.es_es,
.bs_ba,
.bg_bg,
.cs_cz,
.hu_hu,
.ro_md,
.pl_pl,
.ro_ro,
.sl_si,
.sk_sk,
.sr_rs,
.el_gr,
.ar_eg,
.ar_ae,
.lv_lv,
.lt_lt,
.da_dk,
.mk_mk,
.hr_hr,
.tr_tr,
.ru_ru,
.uk_ua,
.az_az,
.be_by,
.ka_ge {
    section button,
    section a.button {
        padding: 0.7em 1em;
        @include font-vw($mobile: 26px, $tablet: 20px, $desktop-lg: 27px);
        span {
            @include font-vw($mobile: 26px, $tablet: 20px, $desktop-lg: 27px);
            font-weight: 400;
            font-family: Impact, HelveticaNeue-CondensedBlack, Charcoal, sans-serif-condensed, sans-serif;
            @include respond-to(mobile-only) {
                letter-spacing: normal;
                padding: 0.5em 1em;
            }
        }
    }
    .home {
        .twocols {
            button,
            a.button {
                > div {
                    padding: 0.35em 0.4em 0.35em;
                    @include respond-to(desktop) {
                        padding: 0.35em 0.4em 0.35em;
                    }
                }
            }
        }
        .button.small {
            font-size: 4.375vw;
            letter-spacing: inherit;
            @include respond-to(tablet) {
                font-size: 1.5625vw;
            }
            @include respond-to(desktop) {
                font-size: 15px;
            }
        }
    }
    .download-app a#appStoreBtn > div {
        padding: 0.45em 1em 0.45em;
        @include respond-to(tablet) {
            padding: 0.62em 1em 0.62em;
        }
    }
}
.hr_hr,
.pl_pl,
.mk_mk,
.bs_ba,
.el_gr,
.no_no,
.da_dk,
.lt_lt,
.ro_ro,
.uk_ua,
.be_by {
    .registerBtn {
        button,
        a.button {
            padding: 0.7em 1em 0.3em;
        }
    }
}
.uk_ua,
.az_az,
.be_by {
    #reg-container {
        .KSTL-Registration {
            button,
            button span {
                @include font-vw($mobile: 26px, $tablet: 16px, $desktop-lg: 20px);
            }
        }
    }
    section button,
    section a.button {
        @include font-vw($mobile: 26px, $tablet: 16px, $desktop-lg: 20px);
        span {
            @include font-vw($mobile: 26px, $tablet: 16px, $desktop-lg: 20px);
        }
    }
}
/** vv Font fixes for locales where andes custom font doesn't support vv **/
.pl_pl {
    .thankyou .container .col-one h4 {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    }
}
.bg_bg,
.el_gr,
.ar_eg,
.ar_ae,
.pl_pl,
.uk_ua {
    header .header-content .header-text .header-sub-para p {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    }
    h1,
    h2,
    p,
    label,
    input,
    h3,
    a {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    }
    footer .footer-menu li a,
    footer .footer-menu li.copyrights span p,
    footer p {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    }
    #reg-container .KSTL-Registration .pod .ng-form label {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    }
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-TermsConditions label,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-TermsandConditions label,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-HeaderSubscription label,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-PrivacyPolicy label {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    }
    .col-one h3,
    .col-one h3 > p,
    .col-one p > p a,
    .col-one .error-thankyou > p {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    }
    .col-one p,
    .col-one p > p,
    .col-one p > p a {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    }
    .popup .popup-content h2,
    .popup .text {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    }
}
// .cs_cz {
//     header .header-content .header-text .header-sub-para p{
//         font-family: $font-andes !important;
//     }
//     h2, p, label, input, h3, a{
//         font-family: $font-andes !important;
//     }
//     footer .footer-menu li a, footer .footer-menu li.copyrights span p, footer p{
//         font-family: $font-andes !important;
//     }
//     #reg-container .KSTL-Registration .pod .ng-form label{
//         font-family: $font-andes !important;
//     }
//     #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-TermsConditions label, #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-TermsandConditions label, #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-HeaderSubscription label, #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-PrivacyPolicy label{
//         font-family: $font-andes !important;
//     }
//     .col-one h3, .col-one h3 > p, .col-one p > p a, .col-one .error-thankyou > p{
//         font-family: $font-andes !important;
//     }
//     .col-one p, .col-one p > p, .col-one p > p a{
//         font-family: $font-andes !important;
//     }
//     .popup .popup-content h2, .popup .text{
//         font-family: $font-andes !important;
//     }
// }
.hu_hu {
    .home .twocols > div h2,
    .home .twocols > div h3 {
        @include font-vw($mobile: 40px, $tablet: 25px, $desktop-lg: 25px);
    }
    .thankyou-pdf {
        .twocols > div:last-child {
            button div,
            a.button div,
            label.button div,
            span.button div {
                @include respond-to(tablet-only) {
                    padding: 0.45em 0.8em 0.45em;
                }
            }
        }
    }
    .batchcode-page .twocols > div:first-child .error-message p {
        @include font-vw($mobile: 16px, $tablet: 16px, $desktop: 16px);
        font-weight: bold;
    }
    @include respond-to(tablet) {
        .batchcode-page .twocols > div:first-child > div {
            margin-right: 3vw;
        }
    }
    .section a.button > div {
        padding: 0;
    }
    section {
        button,
        a.button {
            line-height: 1em;
        }
    }
}
.ro_ro {
    .thankyou-pdf {
        .twocols > div:first-child {
            button div,
            a.button div,
            label.button div,
            span.button div {
                @include respond-to(tablet) {
                    padding: 0.55em 0.8em 0.5em;
                }
                @include respond-to(desktop) {
                    padding: 0.55em 2em 0.5em;
                }
            }
        }
    }
}
.cs_cz,
.sk_sk {
    #reg-container .KSTL-Registration-button button i[ng-show="processing"] {
        right: -2em;
        @include respond-to(tablet) {
            right: -1.5em;
        }
    }
}
.sk_sk {
    .download-app a#appStoreBtn > div .btn-copy {
        font-family: $font-andes-regular;
    }
}
.pl_pl,
.sk_sk {
    h3.register-heading {
        @include respond-to(mobile-only) {
            font-size: 6vw;
        }
    }
}
.ar_eg,
.ar_ae {
    direction: rtl;
    .twocols > div {
        &:last-child > div {
            @include respond-to(tablet) {
                margin-right: $col-beetween-space/4;
                margin-left: 0;
            }
            @include respond-to(desktop) {
                margin-right: $col-beetween-space/2;
                margin-left: 0;
            }
        }
        &:first-child > div.bg-home {
            @include respond-to(tablet) {
                margin-left: $col-beetween-space/4;
                margin-right: 0;
            }
            @include respond-to(desktop) {
                margin-left: $col-beetween-space/2;
                margin-right: 0;
            }
        }
    }
    .KSTL-Registration .KSTL-Registration-SocialMetaData div.regbutton {
        float: right;
    }
    .register #reg-container .KSTL-Registration-SocialMetaData .regbutton {
        right: 5%;
    }
    .thankyou .container .col-one .thankyou-pdf .twocols > div:first-child {
        @include respond-to(tablet) {
            border-left: 1px solid #2c2b26;
            border-right: 0px;
            padding-left: 2vw;
            padding-right: 0;
        }
        @include respond-to(desktop) {
            border-right: 0px;
            padding-left: 1vw;
            padding-right: 0;
        }
    }
    .thankyou .container .col-one .thankyou-pdf .twocols > div:last-child div {
        @include respond-to(tablet) {
            margin-left: 0;
            margin-right: 2vw;
        }
        @include respond-to(desktop) {
            margin-left: 0;
            margin-right: 1vw;
        }
    }
    #reg-container .KSTL-Registration-button button i[ng-show="processing"] {
        right: 4em;
        margin-left: 0;
    }
    button#homeBtn .loader-wrapper {
        left: 15px;
        margin-left: 0;
        right: auto;
    }
    #reg-container .KSTL-Registration {
        .KSTL-Registration-SocialMetaData p,
        .KSTL-Registration-SocialMetaData h2 {
            text-align: right;
        }
        .KSTL-Registration-SocialMetaData > div > div > h2,
        .KSTL-Registration-SocialMetaData > div > div > p {
            @include respond-to(tablet) {
                float: right;
                clear: right;
            }
            text-align: right;
        }
        .pod .ng-form {
            #KSTL-Registration-wrapper-TermsConditions,
            #KSTL-Registration-wrapper-PrivacyPolicy,
            #KSTL-Registration-wrapper-TermsConditions1,
            #KSTL-Registration-wrapper-TermsConditionPrvcyPolicyCombo,
            #KSTL-Registration-wrapper-HeaderSubscription {
                label {
                    text-align: right;
                }
            }
            #KSTL-Registration-Country input[type="radio"] + label:before {
                margin-right: 0;
                right: 0;
            }
            #KSTL-Registration-ForgotPasswordLink a {
                @include font-vw($mobile: 18px, $tablet: 14px, $desktop-lg: 12px);
            }
            label {
                font-weight: 600;
                a {
                    font-weight: inherit;
                }
            }
            #KSTL-Registration-Country {
                text-align: right;
            }
            #KSTL-Registration-wrapper-HeaderSubscription {
                @include respond-to(tablet) {
                    float: right;
                }
            }
            input[type="checkbox"] + label {
                padding: 4px 45px 0 0;
                @include respond-to(tablet) {
                    padding: 0 3em 0 0;
                }
                span {
                    right: 1px;
                }
            }
            div[label] {
                text-align: right;
            }
            #KSTL-Registration-wrapper-ForgotPasswordLink {
                float: left;
            }
            #KSTL-Registration-wrapper-Password {
                .error-field {
                    clear: both;
                }
            }
            .error-field p {
                text-align: right;
            }
            button {
                @include respond-to(desktop) {
                    padding: 12px 0.4em 12px;
                }
            }
        }
    }
    .login {
        .container {
            margin-top: 30px;
            .KSTL-Registration-ForgotPassword {
                h2,
                p {
                    text-align: right;
                }
            }
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-align: right;
        }
        #KSTL-Registration-wrapper-ForgotPwEmailCnfrmHeaderCopy {
            text-align: right;
        }
        .login-container {
            @include respond-to(tablet) {
                border-right: 0;
                border-left: 1px solid #dedede;
            }
        }
        #reg-container .KSTL-Registration {
            .KSTL-Registration-Login.pod.ng-isolate-scope {
                @include respond-to(tablet) {
                    padding-left: $login-leftCol-paddingright--tablet;
                }
                @include respond-to(desktop) {
                    padding-right: 6%;
                    padding-left: $login-leftCol-paddingright--desktop;
                }
            }
        }
    }
    .register .register-form [id*="KSTL-Registration-wrapper"] {
        @include respond-to(tablet) {
            margin-left: 1;
            margin-right: 2.5%;
        }
        &:nth-child(2n + 1) {
            @include respond-to(tablet) {
                margin-right: 0;
                margin-left: 2.5%;
            }
        }
    }
    #reg-container .KSTL-Registration .pod .ng-form label.required:after {
        right: auto;
    }
    footer {
        .container .social-sharing div:last-child {
            border-right: 2px solid #d2d2d2;
            border-left: none;
            @include respond-to(tablet) {
                border-right: none;
            }
        }
        .footer-menu {
            //direction: ltr;
            li {
                @include respond-to(tablet) {
                    border-left: 1px solid #2c2b26;
                    border-right: none;
                    &:last-of-type {
                        border-left: none;
                    }
                }
            }
        }
    }
    .popup .extra-country-nav li {
        @include respond-to(mobile-only) {
            margin-bottom: 10px;
        }
        @include respond-to(tablet) {
            border-left: 2px solid #434343;
            border-right: none;
        }
        &:last-of-type {
            border-left: none;
        }
    }
}
.en_gr,
.en_eg {
    .login .container {
        @include respond-to(desktop) {
            max-width: 900px !important;
            #reg-container .KSTL-Registration .KSTL-Registration-Login.pod.ng-isolate-scope {
                padding-left: 6%;
                padding-right: 10%;
            }
        }
        @include respond-to(mobile-only) {
            .twocols > div:last-child > div .button {
                max-width: 90%;
            }
            #reg-container .KSTL-Registration {
                .KSTL-Registration-Login.pod.ng-isolate-scope {
                    padding-left: 5%;
                    padding-right: 5%;
                }
                .pod .ng-form label {
                    font-size: 3.49375vw;
                }
            }
        }
    }
}
.el_gr,
.bg_bg,
.mk_mk,
.ru_ru {
    header .header-content .header-text .header-sub-para p {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    }
    h2,
    p,
    label,
    input,
    h3,
    h4,
    section button span,
    section a.button span,
    .error {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
        font-weight: bold;
    }
    .batchcode-page .twocols > div:first-child p.tnclink a,
    .thankyoucol .col-one p.ref-copy p,
    .thankyoucol .col-one p.ref-copy a,
    p,
    label,
    input,
    .error {
        font-weight: normal;
    }
    footer .footer-menu li a,
    footer .copyrights span,
    footer p,
    footer .footer-menu + .copyrights p {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: bold;
    }
    #reg-container .KSTL-Registration .pod .ng-form label {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    }
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-TermsConditions label,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-TermsandConditions label,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-HeaderSubscription label,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-PrivacyPolicy label {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    }
    .col-one h3,
    .col-one h3 > p,
    .col-one p > p a,
    .col-one .error-thankyou > p {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    }
    .col-one p,
    .col-one p > p,
    .col-one p > p a {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    }
    .popup .popup-content h2,
    .popup .text,
    .popup p {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-weight: normal;
    }
}
.ru_ru {
    .thankyou .content .button {
        max-width: 85%;
        div {
            padding: 0.5em 0.1em 0.65em;
        }
        @include respond-to(desktop) {
            .btn-copy {
                font-size: 14px;
            }
        }
        @include respond-to(desktop-xl) {
            .btn-copy {
                font-size: 19px;
            }
        }
    }
    .thankyou .container .col-one .thankyou-pdf .twocols .button {
        width: 90%;
        max-width: 90%;
    }
    .thankyou-pdf .button .btn-copy {
        @include respond-to(tablet-only) {
            font-size: 2.3vw;
            line-height: 1.2em;
            padding-left: 0.6em;
            padding-right: 0.6em;
        }
    }
    @include respond-to(tablet-only) {
        .thankyou .container {
            max-width: 80vw;
            .col-one .thankyou-pdf .twocols button.button {
                width: 100%;
                max-width: 100%;
                span {
                    padding-left: 0.5em;
                    padding-right: 0.5em;
                }
            }
        }
    }
    .batchcode-page .twocols .form-control .upload-receipt .receipt-wrapper .button {
        @include respond-to(mobile-only) {
            padding: 10px 15px;
        }
        &.small {
            @include border-radius(20px);
        }
    }
}
.ru_ru {
    #TERMS {
        ol.bbc-list {
            padding-left: 6%;
            li:not(:last-of-type) {
                margin-bottom: 20px;
            }
            li:first-of-type {
                margin-top: 20px;
            }
        }
        ul.bbc-list {
            padding-left: 3%;
            li {
                margin-bottom: 20px;
            }
        }
    }
    .social-sharing {
        display: none !important;
    }
    @include respond-to(mobile-only) {
        .footer-menu {
            border-top: 2px solid #463f2a;
        }
        .thankyou {
            .button div {
                line-height: 1.2em;
            }
            a.button {
                padding-left: 0.3em;
                padding-right: 0.3em;
            }
            .content button.button {
                div {
                    padding-left: 0.9em;
                    padding-right: 0.9em;
                }
            }
        }
        .thankyou .container .col-one .books-instructions p strong {
            font-size: 1.5em;
        }
    }
}
.el_gr,
.lv_lv {
    .thankyou .content .button {
        .btn-copy {
            line-height: 1.2em;
            @include respond-to(tablet) {
                font-size: 2.2vw;
            }
            @include respond-to(desktop) {
                font-size: 20px;
                max-width: 300px;
            }
            @include respond-to(desktop-xl) {
                font-size: 23px;
            }
        }
        div {
            @include respond-to(mobile-only) {
                padding-left: 0.7em;
                padding-right: 0.7em;
            }
            @include respond-to(tablet-only) {
                padding-bottom: 0.5em;
            }
        }
    }
}
.ro_ro,
.en_gr{
    #KSTL-Registration-WinnerConsent ~ div{
        @include font-vw(
            $mobile: $grm-checkbox-label-fs--mobile,
            $tablet: $grm-checkbox-label-fs--tablet,
            $desktop: 13px
        );
        margin-left: 53px;
    }
    #KSTL-Registration-wrapper-WinnerConsent a{
        @include font-vw($mobile: 20px, $tablet: 11px, $desktop: 14px);
    }
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-WinnerConsent input[type="checkbox"]{
       margin-left: 0px;
        height: 31px;
        width: 31px;
    }
    #KSTL-Registration-wrapper-WinnerConsent input[type="checkbox"] {
        display: inline-block;
        vertical-align: middle;
        background-image: url("/Content/img/icons/celebrate2022-icons_sprite.png");
        background-repeat: no-repeat;
        background-position: 0 0;
        cursor: pointer;
        position: absolute;
       
    }
    #KSTL-Registration-wrapper-WinnerConsent input[type="checkbox"]:checked{
        background-position: 0 -39px;
    }
}
.en_gr{
    .title-content p a{
        font-size: 16px;
    }
}
.en_ie,
.nl_nl,
.nl_be,
.fr_be,
.fi_fi,
.fr_fr,
.no_no,
.sv_se,
.de_at,
.fr_ch,
.de_de,
.hu_hu,
.hr_hr,
.lt_lt,
.bg_bg,
.bs_ba,
.sq_al,
.pt_pt,
.uk_ua,
.en_is,
.en_gr,
.en_gb,
.el_gr,
.pl_pl,
.ro_ro,
.sr_rs,
.de_ch{

    #KSTL-Registration-wrapper-HeaderSubscription{
       margin-top:-12px;
    }
}
.es_es,
.lt_lt {
    .thankyou .content .button {
        .btn-copy {
            line-height: 1.2em;
            @include respond-to(tablet-only) {
                font-size: 2.5vw;
            }
            @include respond-to(desktop) {
                font-size: 25px;
                max-width: 300px;
            }
        }
        div {
            @include respond-to(mobile-only) {
                padding-left: 0.7em;
                padding-right: 0.7em;
            }
            @include respond-to(tablet-only) {
                padding-bottom: 0.5em;
            }
        }
    }
    @include respond-to(tablet-only) {
        .thankyou-pdf .twocols .button {
            max-width: 100%;
        }
    }
}
.et_ee {
    .home .twocols > div button div {
        @include respond-to(desktop) {
            padding: 0.35em 0 0.5em;
        }
    }
    @include respond-to(tablet-only) {
        .thankyou-pdf .twocols .button {
            max-width: 100%;
        }
    }
    .thankyou-pdf .button .btn-copy {
        @include respond-to(tablet) {
            font-size: 2.5vw;
            line-height: 1.2em;
            padding-left: 0.6em;
            padding-right: 0.6em;
        }
        @include respond-to(desktop) {
            padding-left: 0.3em;
            padding-right: 0.3em;
            font-size: 30px;
        }
    }
}
.en_gr,
.en_eg,
.pt_pt,
.en_gb {
    .thankyou .content .button {
        .btn-copy {
            @include respond-to(mobile-only) {
                font-size: 5vw;
            }
        }
    }
}
.en_gr,
.en_eg {
    @include respond-to(tablet-only) {
        .thankyou-pdf .twocols .button {
            max-width: 100%;
        }
    }
    .thankyou-pdf .button .btn-copy {
        @include respond-to(tablet) {
            font-size: 2.5vw;
            line-height: 1.2em;
            padding-left: 0.6em;
            padding-right: 0.6em;
        }
        @include respond-to(desktop) {
            font-size: 28px;
            padding-left: 0.3em;
            padding-right: 0.3em;
            line-height: initial;
        }
        @include respond-to(desktop-xl) {
            font-size: 30px;
        }
    }
}

.lv_lv,
.tr_tr {
    @include respond-to(mobile-only) {
        .no-promo-field .col-one button {
            width: auto;
            min-width: 130px;
            span {
                font-size: 5.5vw;
                letter-spacing: 1px;
            }
        }
    }
}
.tr_tr {
    .thankyou .content .button .btn-copy {
        @include respond-to(tablet) {
            font-size: 2.5vw;
        }
        @include respond-to(desktop) {
            font-size: 24px;
        }
        @include respond-to(desktop-xl) {
            font-size: 27px;
        }
    }
}
.es_es {
    section {
        a {
            &.button {
                line-height: 1em;
            }
        }
    }
    .thankyou .content .button .btn-copy {
        @include respond-to(desktop) {
            font-size: 24px;
        }
        @include respond-to(desktop-xl) {
            font-size: 25px;
        }
    }
}

.sq_al {
    .home .twocols > div button div {
        @include respond-to(mobile-only) {
            padding: 0.5em 0.2em 0.5em;
        }
        @include respond-to(desktop) {
            padding-top: 0.3em;
        }
    }
    .thankyou-pdf {
        .twocols > div:first-child {
            button div,
            a.button div,
            label.button div,
            span.button div {
                @include respond-to(mobile-only) {
                    padding: 0.25em 3.2em 0.35em;
                }
                @include respond-to(tablet-only) {
                    padding: 0.25em 3.2em 0.35em;
                }
            }
        }
    }
    h2 {
        line-height: 1.3;
    }
}
.sr_rs {
    button,
    a.button,
    label.button,
    span.button {
        line-height: 1.1;
    }
    .thankyou-pdf {
        .twocols > div:last-child {
            button div,
            a.button div,
            label.button div,
            span.button div {
                @include respond-to(tablet-only) {
                    padding: 0.6em 1.4em 0.65em;
                }
                @include respond-to(desktop) {
                    padding: 0.55em 2.4em 0.5em;
                }
            }
        }
    }
}
.pl_pl,
.hr_hr {
    .thankyou-pdf {
        .twocols > div:last-child {
            button div,
            a.button div,
            label.button div,
            span.button div {
                @include respond-to(tablet-only) {
                    padding: 0.6em 1em 0.65em;
                }
            }
        }
    }
}
.bg_bg {
    .login,
    .register-wrapper {
        .content a.button {
            div {
                span {
                    @include font-vw($mobile: 36px, $tablet: 22px, $desktop-lg: 22px);
                }
            }
        }
    }
    .thankyou-pdf {
        .twocols > div:last-child {
            button div,
            a.button div,
            label.button div,
            span.button div {
                @include respond-to(desktop) {
                    padding: 0.55em 1.5em 0.5em;
                }
            }
        }
    }
    .thankyou .container .col-one .thankyou-pdf .twocols p,
    .thankyou .container .col-one h4 {
        font-weight: bold;
    }
    section {
        #reg-container {
            .KSTL-Registration {
                button,
                button span {
                    @include font-vw($mobile: 26px, $tablet: 14px, $desktop-lg: 18px);
                }
            }
        }
        button,
        a.button {
            @include font-vw($mobile: 26px, $tablet: 14px, $desktop-lg: 18px);
            line-height: 1em;
            span {
                @include font-vw($mobile: 26px, $tablet: 14px, $desktop-lg: 18px);
                line-height: 1em;
            }
        }
    }
}
.mk_mk {
    #reg-container .KSTL-Registration-button button i[ng-show="processing"] {
        @include respond-to(desktop) {
            right: -1.5em;
        }
    }
    .login {
        .content a.button {
            div {
                @include respond-to(tablet-only) {
                    padding: 0.6em 0.4em 0.65em;
                }
            }
            span {
                @include font-vw($mobile: 36px, $tablet: 25px, $desktop-lg: 25px);
            }
        }
    }
    #KSTL-Registration-wrapper-HeaderSubscription p.info{
        display: none;
    }
}
.en_gb,
.hu_hu,
.sq_al,
.sr_rs,
.pl_pl,
.hr_hr,
.de_de,
.de_at,
.de_ch,
.sl_si {
    .error-message {
        line-height: 1.2;
    }
}
.ar_eg,
.en_eg {
    .single-col.view-wrapper p,
    .thankyou .container .col-one .lose-wrapper p {
        padding-top: 0;
        padding-bottom: 0;
        @include font-vw($mobile: 30px, $tablet: 30px, $desktop-lg: 30px);
        font-family: $font-andes-medium;
        font-weight: 500;
    }
}
.en_gr,
.el_gr {
    #reg-container .KSTL-Registration .pod .ng-form label {
        max-width: none;
    }
    #reg-container .KSTL-Registration .pod label.required::after {
        right: unset;
    }
}
.el_gr,
.bg_bg,
.mk_mk,
.ru_ru {
    #reg-container {
        .KSTL-Registration-SocialMetaData {
            .regbutton {
                button {
                    span {
                        font-family: "Pringles-SemiSerif", Arial, "Helvetica Neue", Helvetica, sans-serif !important;
                    }
                }
            }
        }
    }
}
.el_gr {
    .puzzle-container {
        p {
            @include font-vw($mobile: 25px, $tablet: 14px, $desktop-lg: 16px);
        }
    }
    .login #reg-container .KSTL-Registration .pod .ng-form {
        label {
            @include respond-to(desktop) {
                font-size: 12px;
            }
        }
        #KSTL-Registration-ForgotPasswordLink {
            @include respond-to(desktop) {
                padding: 0.9em 0 0;
            }
            a {
                @include respond-to(desktop) {
                    font-size: 8px;
                }
            }
        }
    }
    // .batchcode-page .twocols > div:last-child .below-can{
    //     @include font-vw($mobile: 20px, $tablet: 20px, $desktop-lg: 20px);
    // }
    // .batchcode-page .twocols > div:last-child h3{
    //     @include font-vw($mobile: 35px, $tablet: 35px, $desktop-lg: 35px);
    // }
    .main-wrapper
        .login
        #reg-container
        #KSTL-Registration-wrapper-ForgotPasswordLink
        #KSTL-Registration-ForgotPasswordLink
        a {
        //font-size: 10px;
        @include respond-to(mobile-only) {
            top: 0.5em;
            font-size: 2.4vw;
        }
        // @include respond-to(tablet-only) {
        //     font-size: 1vw;
        // }
    }
    @include respond-to(mobile-only) {
        .main-wrapper .container.register-wrapper,
        .main-wrapper .container.login {
            [id*="KSTL-Registration-wrapper"] {
                //max-width: 90%;
                //label {font-size: 3.3vw;}
            }
        }
        .main-wrapper .login .twocols > div:last-child > div .button {
            max-width: 90%;
        }
    }
    #reg-container .KSTL-Registration .pod label.required::after {
        right: unset;
    }
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-TermsConditions,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-TermsandConditions,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-HeaderSubscription,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-PrivacyPolicy,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-TermsConditionPrvcyPolicyCombo,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-TermsConditions1,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-Header_additional_label,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-ContinueButton1,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-TermsConditions1 {
        @include respond-to(mobile-only) {
            max-width: 90%;
            padding-left: 3.1vw;
        }
        @include respond-to(tablet) {
            padding-left: 1.21vw;
        }
    }
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-ContinueButton,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-ContinueButton1,
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-SendEmailButton {
        @include respond-to(mobile-only) {
            padding-left: 0 !important;
        }
    }
}
.en_gr {
    #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-Country {
        @include respond-to(mobile-only) {
            padding-left: 0;
        }
        .error-field {
            @include respond-to(mobile-only) {
                padding-left: 3.1vw;
            }
            @include respond-to(tablet) {
                // height: 35px;
                padding-bottom: 1vw;
            }
        }
        label {
            @include respond-to(mobile-only) {
                padding-left: 3.1vw;
            }
        }
    }
    #reg-container .KSTL-Registration .pod .ng-form {
        #KSTL-Registration-wrapper-TermsConditions1,
        #KSTL-Registration-wrapper-PrivacyPolicy,
        #KSTL-Registration-wrapper-HeaderSubscription,
        #KSTL-Registration-wrapper-TermsConditionPrvcyPolicyCombo,
        #KSTL-Registration-wrapper-WinnerConsent {
            padding-left: 1.1vw;
            @include respond-to(tablet) {
                padding-left: 1.21vw;
            }
        }
    }
}
.android {
    footer {
        p,
        p a,
        * {
            font-weight: bold;
        }
    }
}
.az_az,
.be_by,
.mk_mk {
    * {
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
    }
}
// .ka_ge{
//     *{
//         font-family: "Andes_Regular",Arial,"Helvetica Neue",Helvetica,sans-serif !important;
//     }
// }
.be_by,
.ka_ge,
.az_az,
.uk_ua {
    // .thankyoucol .col-one h3,
    // .thankyoucol .col-one h4,
    .batchcode-page .twocols > div:first-child h2,
    .batchcode-page .twocols > div:first-child h4,
    .main-wrapper .login .col-one h3,
    h3.register-heading {
        font-weight: bold;
    }
}
.ka_ge {
    button,
    button span,
    a.button,
    label.button,
    span.button,
    button div .btn-copy,
    a.button div .btn-copy,
    label.button div .btn-copy,
    span.button div .btn-copy {
        vertical-align: baseline;
        padding-top: 0.2em !important;
    }
    .main-wrapper {
        .login,
        .register {
            #reg-container .KSTL-Registration .pod .ng-form button {
                padding-bottom: 0.5em !important;
                padding-top: 0.27em !important;
            }
        }
    }
    .main-wrapper {
        .twocols > div:last-child > div > a {
            // @include respond-to(tablet) {
            padding: 0.6em 1em !important;
            // }
            span {
                @include font-vw($mobile: 26px, $tablet: 18px, $desktop-lg: 22px);
            }
        }
    }
}
.az_az {
    #reg-container .KSTL-Registration .pod {
        .error-field {
            height: auto;
        }
        .ng-form #KSTL-Registration-wrapper-TermsConditions {
            min-height: 30px;
            margin-top: 0;
            margin-bottom: 0;
            padding-bottom: 20px;
            @include respond-to(desktop) {
                min-height: 0;
            }
            div.terms {
                padding-top: 0;
                min-height: 0;
            }
            label {
                min-height: 31px;
                padding-top: 5px;
            }
        }
    }
    .thankyoucol .col-one h3 {
        @include font-vw($mobile: 40px, $tablet: 30px, $desktop: 30px);
    }
    .thankyoucol.section-limit .col-one h3 {
        @include font-vw($mobile: 40px, $tablet: 30px, $desktop: 30px);
        text-transform: none;
    }
    .thankyoucol.error .col-one h3 {
        @include font-vw($mobile: 40px, $tablet: 30px, $desktop: 30px);
        text-transform: none;
    }
    .batchcode-page .twocols > div:first-child h2:first-child {
        text-transform: none;
    }
    #FAQ {
        h2 {
            text-transform: none;
        }
    }
}

.fr_ch,
.fr_be,
.fr_fr {
    #reg-container .KSTL-Registration .pod .ng-form button > span,
    .login a.button span.btn-copy {
        // @include respond-to(tablet) {
        //     font-size: 2.6vw;
        // }
        // @include respond-to(desktop) {
        //     font-size: 1.6vw;
        // }
    }
}
// .kk_kz{
//     button#homeBtn,
//     #reg-container .KSTL-Registration .pod .ng-form button,
//     .main-wrapper .login .twocols>div:last-child>div .button,
//     .main-wrapper .login #reg-container .KSTL-Registration .pod .ng-form button{
//         padding: 10px 1em 12px;
//         @include respond-to(tablet) {
//             padding: 10px 1em 12px;
//         }
//     }
//     .batchcode-page .twocols>div:first-child h2{
//         font-weight: bold;
//     }
//     .thankyoucol .col-one .button{
//         max-width: 520px;
//         @include respond-to(tablet) {
//             padding: .4em 1em .4em;
//             }
//         @include respond-to(tablet-only) {
//             max-width: 380px;
//             }
//     }
// }
.iphone {
    .wordsearchpage.crossword .words-to-find .tooltip-top-left .checked-word p {
        margin: 0;
    }
}
.ka_ge.safari.mac {
    footer *,
    footer p,
    footer p a {
        font-weight: bold;
    }
}

.mk_mk.safari.mac {
    .main-wrapper .login .twocols > div:last-child > div a.button span {
        @include respond-to(tablet) {
            font-size: 1.6vw;
        }
    }
}
.pl_pl {
    .puzzle-container {
        p {
            @include font-vw($mobile: 25px, $tablet: 14px, $desktop-lg: 16px);
        }
    }
    .batchcode-page .twocols > div:first-child h2 {
        font-family: "Andes_Regular", Arial, "Helvetica Neue", Helvetica, sans-serif !important;
        font-weight: bold;
    }
    #reg-container .KSTL-Registration {
        .KSTL-Registration-SocialMetaData > div > div > p {
            @include font-vw($desktop: 18px);
        }
    }
}
.be_by {
    #KSTL-Registration-wrapper-SendEmailButton > div > p > button > span {
        @include respond-to(tablet) {
            font-size: 1.5vw !important;
            line-height: 1.2em;
        }
    }
    .crossword .words-to-find .tooltip-top-left p {
        @include respond-to(mobile-only) {
            font-size: 2.8vw;
        }
    }
    #reg-container .KSTL-Registration .pod .ng-form label.required:after {
        right: unset;
    }
}
.mk_mk,
.pl_pl {
    section {
        #reg-container {
            .KSTL-Registration {
                button,
                button span {
                    @include font-vw($mobile: 26px, $tablet: 16px, $desktop-lg: 22px);
                }
            }
        }
        button,
        a.button {
            @include font-vw($mobile: 26px, $tablet: 16px, $desktop-lg: 22px);
            span {
                @include font-vw($mobile: 26px, $tablet: 16px, $desktop-lg: 21px);
                line-height: 1em;
            }
        }
    }
}
.mk_mk,
.lv_lv {
    .nopromo-section {
        h4 p:first-child {
            margin: 0 0 1vw;
        }
    }
}
.register {
    #reg-container .KSTL-Registration {
        .KSTL-Registration-Profile.pod {
            .ng-form #KSTL-Registration-wrapper-Header_additional_label,
            .ng-form #KSTL-Registration-wrapper-HeaderSubscription,
            [id*="KSTL-Registration-wrapper"]:nth-child(2n + 1),
            .ng-form #KSTL-Registration-wrapper-TermsConditions1,
            .ng-form #KSTL-Registration-wrapper-PrivacyPolicy {
                margin-left: 3%;
            }
        }
    }
}
.cs_cz {
    section {
        &.main-wrapper {
            .twocols > div:first-child {
                p {
                    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
                }
            }
        }
    }
    .login {
        .col-one {
            p {
                font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
            }
            p.tnclink + h2 {
                font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
            }
        }
    }
    .thankyou .col-one a.button span {
        @include respond-to(mobile-only) {
            font-size: 3.4vw;
        }
    }
    footer {
        .footer-menu {
            a {
                font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
            }
        }
    }
    section {
        button,
        a.button {
            line-height: 1em;
        }
    }
}
.lv_lv {
    section {
        a {
            &.button {
                line-height: 1em;
            }
        }
    }
}
.en_gr {
    #reg-container .KSTL-Registration .pod.KSTL-Registration-Login .ng-form #KSTL-Registration-wrapper-WinnerConsent {
        // float: none;
    }
}
.sl_si,
.sk_sk {
    section {
        button,
        a.button {
            line-height: 1em;
        }
    }
}
.be_by {
    #reg-container .KSTL-Registration .pod .ng-form label {
        @include respond-to(tablet) {
            min-height: 35px;
        }
    }
}
.cs_cz,
.mk_mk,
.sk_sk,
.nl_nl,
.lv_lv,
.be_by,
.pt_pt,
.ro_md,
.ro_ro,
.es_es,
.nl_be,
.bg_bg {
    #reg-container .KSTL-Registration .pod .KSTL-Registration-button button i {
        @include respond-to(tablet) {
            right: -0.7em;
        }
    }
}
.nl_nl,
.fr_be,
.fi_fi,
.be_by{
    #KSTL-Registration-wrapper-SendEmailButton .KSTL-Registration-button button  i{
        right: -0.3em !important;
    }
}
.mac {
    .splitter.section .container .button {
        @include respond-to(tablet) {
            padding: 12px 25px 14px;
        }
    }
}
.fr_be,
.fr_ch,
.fr_fr,
.de_ch,
.de_at,
.de_de {
    &.mac {
        .main-wrapper .login #reg-container .KSTL-Registration .pod .ng-form button {
            @include respond-to(tablet) {
                padding: 0.3em 0;
            }
        }
    }
}
.fr_fr,
.fr_be,
.es_es {
    .comingsoon {
        .col-one {
            @include respond-to(tablet-only) {
                h2 {
                    font-size: 6.8vw;
                }
                p {
                    width: 90%;
                }
            }
            @include respond-to(tablet) {
                width: 90%;
                max-width: 800px;
            }
        }
    }
}

.pt_pt,
.el_gr {
    .comingsoon {
        .col-one {
            @include respond-to(tablet-only) {
                p {
                    width: 90%;
                }
                h2 {
                    font-size: 7vw;
                }
            }
            @include respond-to(tablet) {
                width: 90%;
                p {
                    max-width: 60%;
                }
            }
            @include respond-to(desktop-xl) {
                h2 {
                    font-size: 40px;
                }
                p {
                    max-width: 70%;
                }
            }
        }
    }
}
.el_gr {
    .comingsoon {
        p,
        h2,
        a {
            font-family: $font-arial !important;
        }
        .col-one {
            @include respond-to(tablet) {
                width: 95%;
            }
        }
        .footer-menu {
            li p,
            a {
                font-family: $font-andes-medium !important;
            }
        }
    }
}
.en_gb,
.en_gr {
    .comingsoon {
        .col-one {
            max-width: 800px;
            @include respond-to(tablet-only) {
                p {
                    width: 90%;
                }
            }
        }
    }
}
.nl_nl,
.nl_be,
.pt_pt,
.es_es {
    .comingsoon {
        .col-one {
            max-width: 910px;
        }
    }
}
.iphone,
.android,
.ipad,
.mac {
    .splitter {
        .btn-copy {
            padding-top: 8px;
            @include respond-to(tablet) {
                padding-top: 9px;
            }
        }
        .btn-arrow {
            i {
                margin-top: 2px;
                @include respond-to(tablet) {
                    margin-top: 0;
                }
            }
        }
    }
}
.es_es{
    button .loader-wrapper{
        top: 40%;
    }
}
.ru_ru{
    .thankyou-page .ref-copy a,
    a>span{
        font-weight: normal;
    }
}
.en_gr{
    #reg-container .KSTL-Registration .pod .ng-form input[type=checkbox]:checked{
        display: inline-block;
        vertical-align: middle;
        background-image: url("/Content/img/icons/celebrate2022-icons_sprite.png");
        background-repeat: no-repeat;
        background-position: 0 -39px;
        cursor: pointer;
        position: absolute;
       /* left: 1px;
        top: 0;*/
        height: 31px;
        width: 31px;
    }
}
.ro_md,
.ro_ro,
.lt_lt,
.fr_ch,
.hu_hu,
.hr_hr{
    section button,
    section a.button{
        span {
            @include font-vw($mobile: 26px, $tablet: 20px, $desktop-lg: 23px);
        }
    }
.en_gr {
    #KSTL-Registration-wrapper-WinnerConsent > div > div > div {
        position: relative;
        &:before {
            content: "*";
            display: inline-block;
            position: absolute;
            left: -0.7em;
            margin-top: $grm-label-requiredstar-margintop;
            @include font-vw($mobile: 18px, $tablet: 14px, $desktop-lg: 14px);
            font-weight: bold;
            @include respond-to(mobile-only){
                top: 0.3em;
            }
        }
    }
}
}
.fr_be,
.fr_fr{
    section button,
    section a.button{
        span {
            @include font-vw($mobile: 35px, $tablet: 20px, $desktop-lg: 28px);
        }
    }
}
 .nl_nl,
 .de_de{
        #reg-container .KSTL-Registration .pod .ng-form #KSTL-Registration-wrapper-PrivacyPolicy >div .error-field{
            margin-top:8px;
        }
    }
    
    .ro_ro,
    .sl_si,
    .sk_sk,
    .fr_ch,
    .de_ch,
    .hu_hu,
    .cs_cz,
    .bg_bg,
    .lv_lv,
    .et_ee,
    .lt_lt,
    .en_is{
        .register-page .register-form [id*=KSTL-Registration-wrapper]:nth-child(2n+1){
            margin-left: 0;
            margin-right: 3%;
        }
    }
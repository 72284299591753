.social {
    overflow: hidden;
    display: inline-block;
    width: auto;
    margin-top: $social-margin-top--mobile;
    margin-bottom: $social-margin-bottom--mobile;
    @include respond-to(tablet) {
        margin-top: $social-margin-top--tablet;
        margin-bottom: $social-margin-bottom--tablet;
    }
    @include respond-to(desktop) {
        margin-top: $social-margin-top--desktop;
        margin-bottom: $social-margin-bottom--desktop;
    }
    a,
    .facebook,
    .twitter {
        display: block;
        float: left;
        border-radius: 50%;
        text-align: center;
        width: $social-icon-width--mobile;
        height: $social-icon-height--mobile;
        margin-bottom: 0;
        position: relative !important;
        overflow: hidden;
        @include respond-to(tablet) {
            width: $social-icon-width--tablet;
            height: $social-icon-height--tablet;
        }
        @include respond-to(desktop) {
            width: $social-icon-width--desktop;
            height: $social-icon-height--desktop;
        }
        i {
            color: $social-icon-color;
            display: inline-block;
            font-size: $social-icon-i-fs--mobile;
            line-height: $social-icon-i-lineheight--mobile;
            @include respond-to(tablet) {
                font-size: $social-icon-i-fs--tablet;
                line-height: $social-icon-i-lineheight--tablet;
            }
            @include respond-to(desktop) {
                font-size: $social-icon-i-fs--desktop;
                line-height: $social-icon-i-lineheight--desktop;
            }
            &.icon-facebook {
                font-size: $social-icon-i-fs-fb--mobile;
                line-height: $social-icon-i-lineheight-fb--mobile;
                @include respond-to(tablet) {
                    font-size: $social-icon-i-fs-fb--tablet;
                    line-height: $social-icon-i-lineheight-fb--tablet;
                }
                @include respond-to(desktop) {
                    font-size: $social-icon-i-fs-fb--desktop;
                    line-height: $social-icon-i-lineheight-fb--desktop;
                }
            }
        }
        + a {
            margin-left: $social-icon-spacebeetween--mobile;
            @include respond-to(tablet) {
                margin-left: $social-icon-spacebeetween--tablet;
            }
            @include respond-to(desktop) {
                margin-left: $social-icon-spacebeetween--desktop;
            }
        }
    }
    .twitter {
        border: solid $social-border-size $twitter-border-color;
        background-color: $social-bg-color;
    }
    .facebook {
        border: solid $social-border-size $facebook-border-color;
        background-color: $facebook-bg-color;
    }
    > div {
        cursor: pointer;
    }
}

#reg-container {
    .KSTL-Registration-SocialMetaData {
        background: #f9df74;
        border-radius: 10px;
        @include respond-to(tablet) {
            min-height: 150px;
        }
        .KSTL-Registration-twitter {
            display: none;
        }
        &.pod {
            margin: $grm-social-margin--mobile;
            padding: $grm-social-padding--mobile;
            @include respond-to(tablet) {
                margin: $grm-social-margin--tablet;
                padding: $grm-social-padding--tablet;
            }
            @include respond-to(desktop) {
                margin: $grm-social-margin--desktop;
                padding: $grm-social-padding--desktop;
            }
        }
        > div > div {
            > h2,
            > p {
                text-align: center;
                font-family: $font-andes-semiBold;
                line-height: 1.2em;
            }
            h2 {
                @include font-vw($mobile: 30px, $tablet: 18px, $desktop: 22px);
                margin-bottom: 0.4em;
                @include respond-to(tablet) {
                    margin-bottom: 0.05vw;
                }
            }
            > p {
                // margin-bottom: 0.3em;
                margin: 0;
                padding: 0.3em 0;
                max-width: 100% !important;
                @include font-vw($mobile: 24px, $tablet: 14px, $desktop: 18px);
                a {
                    @include font-vw($mobile: 24px, $tablet: 14px, $desktop: 18px);
                }
                @include respond-to(tablet) {
                    width: 100%;
                    //max-width:250px;
                    text-align: center;
                }
            }
        }
        div[ng-activity-indicator="Circled"] {
            transform: translateY(100%);
            @include respond-to(tablet) {
                padding-right: 5%;
            }
            @include respond-to(tablet) {
                padding-right: 11%;
            }
        }
        .regbutton {
            p {
                margin: 0;
                padding: 0;
            }
            @include respond-to(tablet) {
                position: relative;
                min-width: 125px;
                //left: 5%;
                margin-right: 0;
            }
            @include respond-to(desktop) {
                min-width: 155px;
            }

            > div {
                button {
                    min-width: 120px;
                    @include respond-to(tablet) {
                        min-width: 145px;
                        max-width: 100%;
                        padding: 7px 1em 9px 1.5em;
                        background-position: 12px 50%;
                    }
                }
            }
            button {
                @include font-vw($mobile: 40px, $tablet: 20px, $desktop: 25px);
                @include respond-to(mobile-only) {
                    padding-right: 0;
                    padding-left: 0;
                    text-align: center;
                }
                span {
                    font-family: $font-pringles-semiSerif;
                    color: #ffffff !important;
                    font-weight: normal;
                    letter-spacing: 1px;
                    @include respond-to(tablet) {
                        padding: 8px 6px;
                    }
                    @include respond-to(tablet) {
                        padding-left: 2px;
                    }
                }
                &.bt-twitter,
                &.bt-facebook {
                    i[ng-activity-indicator="Small"] {
                        span {
                            padding: 0;
                            border-color: rgba($site-copy-color, 0.5) rgba($site-copy-color, 0.5) $site-copy-color
                                $site-copy-color;
                        }

                        right: -10px;

                        @include respond-to(tablet) {
                            right: 1px;
                        }
                    }
                }
            }
            .bt-twitter {
                background-color: $grm-bg-color;
                border-color: $grm-bg-color;
                background-size: auto;
                &:hover {
                    background-color: $twitter-hover-color;
                    border-color: $twitter-hover-color;
                }
                @include respond-to(mobile-only) {
                    > span {
                        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkNDMDVCNTIyNEQ2RDExRTQ4NURGQTI1OTBDQTVFMjcyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkNDMDVCNTIzNEQ2RDExRTQ4NURGQTI1OTBDQTVFMjcyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6Q0MwNUI1MjA0RDZEMTFFNDg1REZBMjU5MENBNUUyNzIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6Q0MwNUI1MjE0RDZEMTFFNDg1REZBMjU5MENBNUUyNzIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz47kkpdAAABN0lEQVR42qTUsUrDQBzH8b9pFysUHFRE6ebUoeomXUTcRDv5AtLFB/AZ7OoLdBAnoYIgjiJqXXVRB7WDi4LgUipFsOf3L6mcR5KL9Q8fSHKX3x3kfxFjTBajkBSW0cQ73rCHyXBsC0t6MYU7rHjC1k10PeEGZ5jViWVrcDUmbBgPJrlOsamTi/iwBnYx7QQuGH8dYKT/QsMZ7GIfGxhHxRP2ggnN0rA8FnEfM/kTHfQSAh91dxqYFZES6shIdAXISXK9otOffI4WCjJ4Xdqra1Vx/Y/A458r60uWcGH+Xk27IwJrlXmUB9jd9q87p99q1sq9FLvbcQ9B1KkInOC4akSdKgkbMhc+KOjxwa0nrI6huED9KZygnWJXLawl/US+Vwmbu4I5zGAM2vRdPOMKhzjyfaEvAQYA2ecc5GoQhrEAAAAASUVORK5CYII=);
                        //height: 26px;
                        background-repeat: no-repeat;
                    }
                }
            }
            .bt-facebook {
                background-size: auto;
                background-image: url("/Content/img/icons/celebrate2022-fb_icon_white.png");
                background-color: $facebook-bg-color;
                border-color: $facebook-bg-color;
                &:hover {
                    background-color: $facebook-hover-color;
                    border-color: $facebook-hover-color;
                }
                @include respond-to(mobile-only) {
                    > span {
                        background-image: url("/Content/img/icons/celebrate2022-fb_icon_white.png");
                        // height: 30px;
                        background-repeat: no-repeat;
                    }
                }
                @include respond-to(tablet) {
                    margin-top: 0;
                    max-width: 190px;
                }
            }
            @include respond-to(mobile-only) {
                float: none;
                width: 65%;
                margin: 0 auto;
                .bt-twitter,
                .bt-facebook {
                    background-image: none;
                    > span {
                        /*padding-right:10px;
                   content: "";
                   display: inline-block;
                   width: 36px;
                   background-repeat: no-repeat;*/
                        background-position: center left;
                        display: inline-block;
                        min-width: 70%;
                        padding: 6px 0px 5px 20px !important;
                    }
                }
                .bt-facebook {
                    margin-bottom: 0;
                    padding: 0.1em 20px 0.2em;
                    width: auto;
                    font-size: 24px;
                }
            }
        }
        &[continue="true"],
        &[error="true"] {
            > div > div {
                > h2,
                > p {
                    text-align: center;
                    max-width: 100%;
                    @include respond-to(tablet) {
                        float: left;
                        clear: left;
                        width: 100%;
                        text-align: left;
                    }
                    a {
                        font-weight: 600;
                    }
                }
            }
        }
        div[ng-activity-indicator="Circled"] {
            transform: translateY(100%);
        }
    }
}
#reg-container {
    .KSTL-Registration-SocialMetaData {
        .regbutton {
            button,
            > div button {
                line-height: 1;
                padding: 0.3em 0.4em;
                @include respond-to(tablet) {
                    padding: 10px 1em 9px 1.5em;
                }
                span {
                    @include font-vw($mobile: 40px, $tablet: 20px, $desktop-lg: 24px);
                    line-height: 1;
                }
            }
            &.KSTL-Registration-facebook {
                @include respond-to(tablet) {
                    margin: 0 auto;
                    float: none;
                }
                @include respond-to(tablet-only) {
                    clear: both;
                    margin-left: 0;
                }
                span {
                    padding-bottom: 4px !important;
                    @include respond-to(mobile-only) {
                        padding-bottom: 8px !important;
                    }
                }
            }
        }
    }
}
.register-page #reg-container .KSTL-Registration-SocialMetaData .regbutton button.bt-facebook i[ng-activity-indicator=Small]{
    right:-0.5em;
    @include respond-to(mobile-only) {
        right:-0.8em;
    }
}

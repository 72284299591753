header {
    position: relative;
    .swicth_country {
        display: none;
        position: absolute;
        top: 20px;
        right: 20px;
        background: gray;
        color: white;
        top: 20px;
        right: 20px;
        background: $grey;
        color: $white;
        width: auto;
        label {
            opacity: 0;
            width: 0;
            height: 0;
            overflow: hidden;
            font-size: 0;
            margin: 0;
            padding: 0;
        }
        select {
            margin: 0;
            padding: 0;
            -webkit-appearance: menulist-button;
            width: auto;
            border: 0;
            @include font-vw($mobile: 25px, $tablet: 14px, $desktop: 16px);
        }
    }
}
[pagename="Login"],
[pagename="Shipping"] {
    header .swicth_country {
        display: inline-block;
    }
}

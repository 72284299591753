.thankyou-page {
    max-width: 650px;
    margin: 0 auto;
    width: 100%;
    @include respond-to(tablet) {
        .thanks-inner-wrapper {
            max-width: 700px;
            width: 50vw;
            margin: 0 auto;
        }
    }
    hr {
        color: #2c2b26;
        background-color: #2c2b26 !important;
        margin-right: auto;
        margin-left: auto;
        height: 1px;
        width: 82vw;
        margin: 6vw auto;
        @include respond-to(tablet) {
            width: 27vw;
            max-width: 530px;
            margin: 3vw auto;
        }
    }
    #code {
        position: absolute;
        color: #fff;
        top: 0;
        left: -2000px;
        width: auto;
    }
    h1 {
        margin: 0 auto 2vw;
        @include respond-to(tablet) {
            margin: 0 auto 1vw;
        }
        @include font-vw($mobile: 36px, $tablet: 26px, $desktop: 30px);
        p {
            font-family: $font-andes-medium;
            @include font-vw($mobile: 36px, $tablet: 26px, $desktop: 30px);
            font-weight: bold;
            margin: 0 auto 4vw;
            @include respond-to(tablet) {
                margin: 0 auto 0.8vw;
            }
        }
    }
    .win-mess {
        font-weight: bold;
        @include font-vw($mobile: 32px, $tablet: 24px, $desktop: 28px);
        margin: 2.5vw auto 4vw;
        @include respond-to(tablet) {
            margin: 0 auto 2vw;
        }
    }
    h3.winner,
    h3.winner p {
        font-family: $font-andes-medium;
        @include font-vw($mobile: 30px, $tablet: 35px, $desktop: 35px);
    }
    h3.error-heading {
        @include font-vw($mobile: 30px, $tablet: 35px, $desktop: 35px);
    }
    h4 {
        font-family: $font-medium;
        line-height: 1.4;
        font-weight: bold;
        margin-bottom: 2vw;
        @include font-vw($mobile: 28px, $tablet: 26px, $desktop: 26px);
        p {
            @include font-vw($mobile: 28px, $tablet: 26px, $desktop: 26px);
            font-weight: bold;
        }

        &.crossword,
        &.crossword p,
        &.lose,
        &.lose p,
        &.extended {
            font-weight: normal;
            font-family: $font-andes;
        }
    }
    p,
    p a {
        font-family: $font-andes;
        line-height: 1.3;
        @include font-vw($mobile: 24px, $tablet: 14px, $desktop: 20px);

        &.instructions-desc p {
            @include font-vw($mobile: 16px, $tablet: 18px, $desktop: 18px);
        }
        // a{
        //     @include font-vw($mobile: 24px, $tablet: 20px, $desktop: 24px);
        // }
        &.instruction_header {
            font-family: $font-andes-semiBold;
            margin-bottom: 2vw;
        }
        &.tnclink {
            a {
                margin: 0;
                text-decoration: underline;
                color: #2c2b26;
                @include font-vw($mobile: 16px, $tablet: 16px, $desktop: 16px);
            }
        }
    }
    p p {
        margin: 0 auto 0vw;
    }
    p.ref-copy {
        @include font-vw($mobile: 22px, $tablet: 17px, $desktop: 17px);
        p,
        a {
            @include font-vw($mobile: 22px, $tablet: 17px, $desktop: 17px);
        }
        margin-bottom: 0;
    }
    p.refCode {
        margin-bottom: 1vw;
    }
    .font-fix {
        @include font-vw($mobile: 22px, $tablet: 17px, $desktop: 17px);
    }
    .cta-container {
        margin-top: 2vw;
        @include respond-to(mobile-only) {
            margin-top: 4vw;
        }
    }
    .subscription-code {
        @include font-vw($mobile: 30px, $tablet: 30px, $desktop: 30px);
        color: #ea0808;
    }
    .button {
        &.download-user-manual-cta {
            margin-bottom: 1vw;
        }
    }
    #copy-clipboard {
        width: auto;
        cursor: pointer;
        display: inline-block;
        margin-left: 10px;
        margin-top: 4px;
        height: 4vw;
        @include respond-to(tablet) {
            height: 3vw;
        }
        @include respond-to(desktop) {
            height: 2vw;
        }
        @include respond-to(desktop-xl) {
            height: 1.5vw;
        }
    }
    .prize-cols {
        @include respond-to(tablet) {
            display: flex;
        }
        p {
            margin-bottom: 3vw;
            @include respond-to(tablet) {
                margin-bottom: 1vw;
            }
        }
        div {
            text-align: center;
            @include respond-to(tablet) {
                width: 42%;
                align-self: flex-end;
                &.prize-separator {
                    width: 16%;
                }
                button {
                    width: 100%;
                }
            }
        }
        .prize-separator {
            position: relative;
            @include respond-to(mobile-only) {
                margin: 4vw auto;
            }
            p {
                position: absolute;
                bottom: 1.5vw;
                width: 100%;
                text-align: center;
                position: relative;
                margin: 1vw auto;
                bottom: auto;
            }
        }
    }

    div.hiding {
        height: 0;
        width: 0;
        opacity: 0;
        margin: 0;
        padding: 0;
    }
    &.error p {
        @include respond-to(mobile-only) {
            margin-bottom: 7vw;
        }
    }
    p.link-desc {
        margin-bottom: 1vw;
    }
    a {
        // color: $black;
    }
}

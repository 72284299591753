// =============================================================================
// Fonts declarate
// =============================================================================
$sc-font-sets: (
  sm: (
    mobile: 14px,
    tablet: 14px,
    desktop: 14px,
  ),
  md: (
    mobile: 16px,
    tablet: 18px,
    desktop: 16px,
  ),
  lg: (
    mobile: 22px,
    tablet: 22px,
    desktop: 22px,
  ),
) !default;

//default font set for font-* mixins
$sc-font-set-default: md !default;

//font directory for sc-font-face mixin (relative to the output css file)
$sc-font-dir: "fonts/" !default;

//breakpoints for which font vw is not set (px value is used)
$sc-font-conv-vw-exclude: () !default;

//@include sc-font-face(font-name, font-filename, normal);
//$font-name: font-name,Arial,Tahoma,sans-serif;
// @include sc-font-face(Andes_Book, andesbook-webfont, normal);
// @include sc-font-face(Gotham_Bold, Gotham-Bold, normal);

// @include sc-font-face(KelloggsSans_Regular, KelloggsSans-Regular, normal);
// @include sc-font-face(KelloggsSans_Bold, KelloggsSans-Bold, normal);
// @include sc-font-face(KelloggsSans_Medium, KelloggsSans-Bold, normal);
// @include sc-font-face(KelloggsSans_Light, KelloggsSans-Light, normal);
// @include sc-font-face(fontello, fontello, normal);

// @include sc-font-face(Andes_Regular, andes-webfont, normal);
// @include sc-font-face(Pringles-SemiSerif, Pringles-SemiSerif, normal);
// @include sc-font-face(Andes_Bold, andesbold-webfont, normal); //medium font
// @include sc-font-face(Andes_SemiBold, andessemibold-webfont, normal);
// @include sc-font-face(AndesCondensed_ExtraBold, andescondensedextrabold-webfont, normal);
// @include sc-font-face(Andes_Extra_Bold, AndesExtraBold, normal);
// @include sc-font-face(Andes_Semibold, andessemibold-webfont, normal);

// @include sc-font-face(Andes_Medium, andesmedium-webfont, normal);
// @include sc-font-face(Andes_Light, AndesLight, normal);

// @include sc-font-face(Andes, Andes, normal);
// @include sc-font-face(Intro_Italic, Intro-Black-Italic, normal);

// @include sc-font-face(Futura_Heavy, Futura Std Heavy, normal);
// @include sc-font-face(Futura_Med, FuturaStd-Medium, normal);

$font-fontello: "fontello", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-pringles-semiSerif: "Pringles-SemiSerif", Arial, "Helvetica Neue", Helvetica, sans-serif;

$font-light: "KelloggsSans_Light", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-regular2: "KelloggsSans_Regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-arrow: "KelloggsSans_Bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-medium-Kellogg: "KelloggsSans_Medium", Arial, "Helvetica Neue", Helvetica, sans-serif;

$font-andes: "Andes_Book", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-bold: "AndesCondensed_ExtraBold", Arial, "Helvetica Neue", Helvetica, sans-serif; //headers
$font-andes-regular: "Andes_Regular", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-medium: "Andes_Bold", Arial, "Helvetica Neue", Helvetica, sans-serif; //medium font: label
$font-andes-extraBold: "Andes_Extra_Bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-andes-semiBold: "Andes_Semibold", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-andes-medium: "Andes_Medium", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-andes-light: "Andes_Light", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-andes: "Andes", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-gotham-bold: "Gotham_Bold", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-extralight: $font-light;
//$font-arrow: $font-regular;

$font-heading-italic: "Intro_Italic", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-futura: "Futura_Heavy", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-futura-med: "Futura_Med", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-arial: Arial, "Helvetica Neue", Helvetica, sans-serif;

// =============================================================================
// Fonts size variables
// =============================================================================
/////////////////////////////
///	LAYOUT - FONT-SIZE VARIABLES$header-col1-fs--mobile
/////////////////////////////
$h1-fs--mobile: 32px;
$h1-fs--tablet: 20px;
$h1-fs--desktop: 31px;

$h2-fs--mobile: 50px;
$h2-fs--tablet: 30px;
$h2-fs--desktop: 30px;

$h3-fs--mobile: 28px;
$h3-fs--tablet: 28px;
$h3-fs--desktop: 30px;

$titleSection-h3-fs--mobile: 28px;
$titleSection-h3-fs--tablet: 30px;
$titleSection-h3-fs--desktop: 30px;

$text-fs--mobile: 30px;
$text-fs--tablet: 16px;
$text-fs--desktop: 16px;

$link-fs--mobile: 28px;
$link-fs--tablet: 18px;
$link-fs--desktop: 18px;

$button-fs--mobile: 34px;
$button-fs--tablet: 26px;
$button-fs--desktop: 34px;

$buttonsm-fs--mobile: 36px;
$buttonsm-fs--tablet: 36px;
$buttonsm-fs--desktop: 36px;

$button-i-fs--mobile: 38px;
$button-i-fs--tablet: 38px;
$button-i-fs--desktop: 38px;

/////////////////////////////
///	FOOTER - FONT-SIZE VARIABLES
/////////////////////////////
$footer-link-fs--mobile: 24px;
$footer-link-fs--tablet: 14px;
$footer-link-fs--desktop: 12px;

$footer-copyright-fs--mobile: 18px;
$footer-copyright-fs--tablet: 14px;
$footer-copyright-fs--desktop: 14px;

$footer-text-fs--mobile: 20px;
$footer-text-fs--tablet: 12px;
$footer-text-fs--desktop: 10px;

/// faq& terms///
$legals-header-fs--mobile: 51px;
$legals-header-fs--tablet: 28px;
$legals-header-fs--desktop: 32px;

$legals-text-fs--mobile: 20px;
$legals-text-fs--tablet: 12px;
$legals-text-fs--desktop: 10px;

/////////////////////////////
///	CONTENT - FONT-SIZE VARIABLES
/////////////////////////////

/////////////////////////////
///	FORM - FONT-SIZE VARIABLES
/////////////////////////////
$label-fs--mobile: 36px;
$label-fs--tablet: 18px;
$label-fs--desktop: 22px;

$label-ff: $font-medium;

$input-fs--mobile: 35px;
$input-fs--tablet: 16px;
$input-fs--desktop: 20px;

/////////////////////////////
///	HEADER - FONT-SIZE VARIABLES
/////////////////////////////
$headerTitle-fs--mobile: 76px;
$headerTitle-fs--tablet: 48px;
$headerTitle-fs--desktop: 76px;

$header-mainPara1-fs--mobile: 18px;
$header-mainPara1-fs--tablet: 12px;
$header-mainPara1-fs--desktop: 18px;

$header-mainPara2-fs--mobile: 28px;
$header-mainPara2-fs--tablet: 18px;
$header-mainPara2-fs--desktop: 28px;

$header-subPara1-fs--mobile: 30px;
$header-subPara1-fs--tablet: 20px;
$header-subPara1-fs--desktop: 26px;

$header-subPara2-fs--mobile: 30px;
$header-subPara2-fs--tablet: 20px;
$header-subPara2-fs--desktop: 26px;

/////////////////////////////
///	COL1 - FONT-SIZE VARIABLES
/////////////////////////////

$header-col1-fs--mobile: 30px;
$header-col1-fs--tablet: 40px;
$header-col1-fs--desktop: 30px;

$header-para-fs--mobile: 15px;
$header-para-fs--tablet: 20px;
$header-para-fs--desktop: 15px;

$button-col1-fs--mobile: 40px;
$button-col1-fs--tablet: 40px;
$button-col1-fs--desktop: 30px;

/////////////////////////////
///	REGISTER - FONT-SIZE VARIABLES
/////////////////////////////
$register-text-fs--mobile: 18px;
$register-text-fs--tablet: 12px;
$register-text-fs--desktop: 15px;

$register-h-fs--mobile: 52px;
$register-h-fs--tablet: 30px;
$register-h-fs--desktop: 39px;

$grm-input-fs--mobile: 30px;
$grm-input-fs--tablet: 13px;
$grm-input-fs--desktop: 16px;

$grm-label-fs--mobile: 23px;
$grm-label-fs--tablet: 14px;
$grm-label-fs--desktop: 14px;

$grm-checkbox-label-fs--mobile: 20px;
$grm-checkbox-label-fs--tablet: 11px;
$grm-checkbox-label-fs--desktop: 14px;

$grm-checkbox-labellink-fs--mobile: 19px;
$grm-checkbox-labellink-fs--tablet: 16px;
$grm-checkbox-labellink-fs--desktop: 15px;

$grm-button-fs--mobile: 32px;
$grm-button-fs--tablet: 18px;
$grm-button-fs--desktop: 21px;

$grm-login-button-fs--mobile: 32px;
$grm-login-button-fs--tablet: 24px;
$grm-login-button-fs--desktop: 21px;

$grm-login-forgotlink-fs--mobile: 18px;
$grm-login-forgotlink-fs--tablet: 10px;
$grm-login-forgotlink-fs--desktop: 12px;

$grm-errorfield-fs--mobile: 14px;
$grm-errorfield-fs--tablet: 8px;
$grm-errorfield-fs--desktop: 8px;

/////////////////////////////
///	SOCIAL - FONT-SIZE VARIABLES
/////////////////////////////
// $social-icon-i-fs--mobile: 3.4em; //old
// $social-icon-i-fs--tablet: 2.4em; //old
$social-icon-i-fs--mobile: 2em;
$social-icon-i-fs--tablet: 2.8em;
$social-icon-i-fs--desktop: 2.1em;

// $social-icon-i-fs-fb--mobile: 5.1em; //old - to change in MS
// $social-icon-i-fs-fb--tablet: 3.2em;

$social-icon-i-fs-fb--mobile: 2.9em;
$social-icon-i-fs-fb--tablet: 4em;
$social-icon-i-fs-fb--desktop: 2.99em;

/////////////////////////////
///	SHIPPING - FONT-SIZE VARIABLES
/////////////////////////////
$shipping-secondrow-text-fs--mobile: 28px;
$shipping-secondrow-text-fs--tablet: 21px;
$shipping-secondrow-text-fs--desktop: 21px;

// $shipping-mandatoryfieldsinfo-fs--tablet: 1.05em;
$shipping-mandatoryfieldsinfo-fs--mobile: 25px; //change this value
$shipping-mandatoryfieldsinfo-fs--tablet: 17px; //change this value
$shipping-mandatoryfieldsinfo-fs--desktop: 17px; //change this value

$shipping-label-fs--desktop: 20px;

$shipping-input-fs--mobile: 28px;
$shipping-input-fs--tablet: 18px;
$shipping-input-fs--desktop: 18px;

$shipping-select-fs--mobile: 29px;
$shipping-select-fs--tablet: 18px;
$shipping-select-fs--desktop: 18px;

$shipping-option-fs--mobile: 29px;
$shipping-option-fs--tablet: 19px;
$shipping-option-fs--desktop: 19px;

// $shipping-termstitle-texttransofrm--mobile: 30px;
// $shipping-termstitle-texttransofrm--tablet: 23px;
// $shipping-termstitle-texttransofrm--desktop: 21px;

$shipping-terms-label-fs--mobile: 26px;
$shipping-terms-label-fs--tablet: 18px;
$shipping-terms-label-fs--desktop: 18px;

$shipping-terms-label-ff--mobile: $font-andes-regular;
$shipping-terms-label-ff--tablet: $font-andes-semiBold;

$shipping-terms-label-link-fs--mobile: 26px;
$shipping-terms-label-link-fs--tablet: 18px;
$shipping-terms-label-link-fs--desktop: 18px;

$shipping-terms-label-link-ff--mobile: $font-andes-regular;
$shipping-terms-label-link-ff--tablet: $font-medium;

$shipping-termstitle-ff--mobile: $font-bold;
$shipping-termstitle-ff--tablet: $font-medium;

$shipping-input-ff--mobile: $font-regular2;
$shipping-input-ff--tablet: $font-andes-regular;
$shipping-input-ff--desktop: $font-regular2;

$shipping-select-ff--mobile: $font-regular2;
$shipping-select-ff--tablet: $font-andes-regular;
$shipping-select-ff--desktop: $font-regular2;

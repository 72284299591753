html,
body {
    font-family: $font-andes;
    min-width: 320px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
body {
    // background-color: $bodybgcolor;
    &.activeOverlay {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    &.holding {
        background: -moz-linear-gradient(
            left,
            rgba(34, 46, 134, 1) 0%,
            rgba(34, 46, 134, 1) 5%,
            rgba(50, 125, 176, 1) 25%,
            rgba(50, 125, 176, 1) 75%,
            rgba(34, 46, 134, 1) 95%,
            rgba(35, 49, 136, 1) 100%
        );
        background: -webkit-gradient(
            left top,
            right top,
            color-stop(0%, rgba(34, 46, 134, 1)),
            color-stop(5%, rgba(34, 46, 134, 1)),
            color-stop(25%, rgba(50, 125, 176, 1)),
            color-stop(75%, rgba(50, 125, 176, 1)),
            color-stop(95%, rgba(34, 46, 134, 1)),
            color-stop(100%, rgba(35, 49, 136, 1))
        );
        background: -webkit-linear-gradient(
            left,
            rgba(34, 46, 134, 1) 0%,
            rgba(34, 46, 134, 1) 5%,
            rgba(50, 125, 176, 1) 25%,
            rgba(50, 125, 176, 1) 75%,
            rgba(34, 46, 134, 1) 95%,
            rgba(35, 49, 136, 1) 100%
        );
        background: -o-linear-gradient(
            left,
            rgba(34, 46, 134, 1) 0%,
            rgba(34, 46, 134, 1) 5%,
            rgba(50, 125, 176, 1) 25%,
            rgba(50, 125, 176, 1) 75%,
            rgba(34, 46, 134, 1) 95%,
            rgba(35, 49, 136, 1) 100%
        );
        background: -ms-linear-gradient(
            left,
            rgba(34, 46, 134, 1) 0%,
            rgba(34, 46, 134, 1) 5%,
            rgba(50, 125, 176, 1) 25%,
            rgba(50, 125, 176, 1) 75%,
            rgba(34, 46, 134, 1) 95%,
            rgba(35, 49, 136, 1) 100%
        );
        background: linear-gradient(
            to right,
            rgba(34, 46, 134, 1) 0%,
            rgba(34, 46, 134, 1) 5%,
            rgba(50, 125, 176, 1) 25%,
            rgba(50, 125, 176, 1) 75%,
            rgba(34, 46, 134, 1) 95%,
            rgba(35, 49, 136, 1) 100%
        );
    }
    #root~img {
        position: absolute;
        top: -1000px;
        left: -1000px;
    }
}
h1 {
    margin-bottom: 20px;
}
h1,
h3 {
    font-family: "AndesCondensed_ExtraBold", Arial, "Helvetica Neue", Helvetica, sans-serif;
}
p {
    font-family: "Andes_Medium", Arial, "Helvetica Neue", Helvetica, sans-serif;
    margin-bottom: 10px;
    @include respond-to(tablet) {
        margin-bottom: 15px;
    }
}

.trusty {
    text-align: center;
    background: $white;
    img {
        width: 30%;
        @include respond-to(tablet) {
            width: 200px;
        }
    }
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
}

em {
    font-style: italic;
}
img {
    width: 100%;
    margin: 0 auto;
}
.text-center {
    text-align: center;
    * {
        margin: 0 auto;
    }
}

.text-left {
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    > * {
        padding-left: 0;
        padding-right: 0;
    }
}
.text-right {
    text-align: right;
    padding-left: 0;
    padding-right: 0;
    > * {
        padding-left: 0;
        padding-right: 0;
    }
}
* {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
}

sup {
    vertical-align: super;
    font-size: smaller;
}

.clear {
    *zoom: 1;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

[class$="menu"] > li {
    display: inline-block;
}

ul,
menu {
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-start: 0;
    display: block;
}

.mobile-only {
    display: inline-block !important;
}

.tablet-only {
    display: none !important;
}

@include respond-to(tablet) {
    .mobile-only {
        display: none !important;
    }

    .tablet-only {
        display: inline-block !important;
    }
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.smaller-text {
    @include font-vw($mobile: 25px, $tablet: 19px, $desktop: 17px);
}

hr {
    @include rgbaBgForOldBrowser($white, 100);
    display: block;
    //height: $content-hr-height;
    //margin: $hr-margin--mobile;
    border: 0;

    //opacity: $hr-opacity;
    @include respond-to(tablet) {
        //  margin: $hr-margin--tablet;
    }

    & + h3,
    & + h2 {
        //margin-top: $hr-afterelement-margintop--mobile;
        @include respond-to(tablet) {
            //  margin-top: $hr-afterelement-margintop--tablet;
        }

        @include respond-to(desktop) {
            //margin-top: $hr-afterelement-margintop--desktop;
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    .tablet-only {
        @include respond-to(tablet) {
            display: inline-block !important;
        }

        @include respond-to(desktop) {
            display: inline-block !important;
        }
    }

    .mobile-only {
        display: inline-block !important;

        @include respond-to(desktop) {
            display: none !important;
        }

        @include respond-to(desktop) {
            display: none !important;
        }
    }
}

h1.hidden-aria {
    font-size: 0;
    width: 1px;
    height: 1px;
    display: inline-block;
    overflow: hidden;
    position: absolute !important;
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    clip: rect(1px, 1px, 1px, 1px);
}

/////////////////////////////
/// FORM - STYLE
/////////////////////////////
input::-webkit-input-placeholder {
    line-height: $placeholder-lineheight-webkit--m;
    font-weight: 600;
}

input::-moz-placeholder {
    /* Firefox 19+ */
    opacity: 1;
    line-height: $placeholder-lineheight-moz1--m;
    font-weight: 600;
}

input:-moz-placeholder {
    /* Firefox 18- */
    opacity: 1;
    line-height: $placeholder-lineheight-moz2--m;
    font-weight: 600;
}

input:-ms-input-placeholder {
    line-height: $placeholder-lineheight-ms--m;
    font-weight: 600;
}

.ng-error::-webkit-input-placeholder {
    line-height: $placeholder-lineheight-webkit--m;
    font-weight: 600;
}

.ng-error::-moz-placeholder {
    /* Firefox 19+ */
    opacity: 1;
    line-height: $placeholder-lineheight-moz1--m;
    font-weight: 600;
}

.ng-error:-moz-placeholder {
    /* Firefox 18- */
    opacity: 1;
    line-height: $placeholder-lineheight-moz2--m;
    font-weight: 600;
}

.ng-error:-ms-input-placeholder {
    line-height: $placeholder-lineheight-ms--m;
    font-weight: 600;
}

input,
textarea,
select {
    display: block;
    width: 100%;
    text-align: center;
    padding: $input-padding--mobile;
    //outline: 0;
    line-height: 1;
    height: auto;
    margin-bottom: 0 !important;
    color: $btn-text-grey;
    font-family: $font-andes-medium;
    -webkit-appearance: none;
    @include font-vw($mobile: $input-fs--mobile, $tablet: $input-fs--tablet, $desktop: $input-fs--desktop);
    @include border-radius(0);

    @include respond-to(tablet) {
        padding: $input-padding--tablet;
        @include border-radius(0);
    }

    @include respond-to(desktop) {
        padding: $input-padding--desktop;
    }

    &:focus,
    &:active {
        border: none;
        //outline: none;
    }
}

label,
label > a {
    display: block;
    font-family: $font-andes-medium;
    @include font-vw($mobile: $label-fs--mobile, $tablet: $label-fs--tablet, $desktop: $label-fs--desktop);
    line-height: 1.1em;
    width: 100%;
    color: $btn-text-grey;
    margin: $label-home-margin--mobile;
    text-align: center;

    @include respond-to(tablet) {
        margin: $label-home-margin--tablet;
    }

    @include respond-to(desktop) {
        margin: $label-home-margin--desktop;
    }
}

.error {
    input {
        font-family: $font-bold;
        letter-spacing: 3px;

        @include respond-to(tablet) {
            font-family: $font-andes-medium;
        }

        &::-webkit-input-placeholder {
            font-family: $font-bold;
            letter-spacing: 3px;

            @include respond-to(tablet) {
                font-family: $font-andes-medium;
            }
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            font-family: $font-bold;
            letter-spacing: 3px;

            @include respond-to(tablet) {
                font-family: $font-andes-medium;
            }
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            font-family: $font-bold;
            letter-spacing: 3px;

            @include respond-to(tablet) {
                font-family: $font-andes-medium;
            }
        }

        &:-ms-input-placeholder {
            font-family: $font-bold;

            @include respond-to(tablet) {
                font-family: $font-andes-medium;
            }
        }
    }
}

/* don't show the x for text inputs */
::-ms-clear {
    width: 0;
    height: 0;
    display: none;
}

/* don't show the eye for password inputs */
::-ms-reveal {
    width: 0;
    height: 0;
    display: none;
}

.error-message {
    display: none;
    text-align: center;
    position: relative;
    letter-spacing: 1px;
    margin: $error-margin--mobile;

    &.error {
        display: block;
    }

    + .error-message {
        margin: $error-next-margin--mobile;

        @include respond-to(tablet) {
            margin: $error-next-margin--tablet;
        }

        @include respond-to(desktop) {
            margin: $error-next-margin--desktop;
        }

        &:before {
            display: none;
        }
    }

    @include respond-to(tablet) {
        //padding: $error-padding--tablet;
        margin: $error-margin--tablet;
    }

    @include respond-to(desktop) {
        //padding: $error-padding--desktop;
        margin: $error-margin--desktop;
    }

    p {
        padding: 0;
        line-height: 1;
        display: table;
        margin: 0 auto;
        @include font-vw(
            $mobile: $grm-errorfield-fs--mobile,
            $tablet: $grm-errorfield-fs--tablet,
            $desktop: $grm-errorfield-fs--desktop
        );
        font-family: $font-light;

        span,
        span > p {
            display: table-cell;

            &.valid-icon {
                width: 1.2em;
            }

            + span {
                padding-left: 3%;
                display: inline-block;
                width: auto;
                padding-bottom: 0.7em;
                padding-left: 0.5em;
            }

            &:last-child {
                padding-bottom: 0;

                @include respond-to(mobile-only) {
                    @include font-vw($mobile: 20px);
                }
            }
        }
    }
}

.valid-icon {
    width: 1.16em;
    /*background: $validIcon--mobile no-repeat;
    background-size: 100%;
    background-position: 0 center;*/
}

/////////////////////////////
/// BUTTON - STYLE
/////////////////////////////
button,
a.button,
label.button,
span.button {
    &:not(.not-button) {
        min-width: 20vw;
        width: 100%;
        text-transform: uppercase;
        text-align: center !important;
        display: inline-block;
        text-decoration: none;
        position: relative;
        word-wrap: break-word;
        line-height: 1;
        -webkit-box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.11);
        box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.11);
        -moz-box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.11);
        @include border-radius(30px);
        @include respond-to(tablet) {
            width: auto;
            padding: 7px 40px 9px;
        }
    }
    padding: 0.5em 0.4em 0.6em;
    border: 0;
    cursor: pointer;
    div {
        display: flex;
        justify-content: center;
        @include respond-to(tablet) {
            padding: 0.2em 0.3em 0.3em;
        }
        @include respond-to(desktop) {
            padding: 0.15em 0 0.32em;
        }
        .btn-copy {
            padding-top: 0.05em;
            @include font-vw($mobile: $button-fs--mobile, $tablet: $button-fs--tablet, $desktop: $button-fs--desktop);
        }
    }

    span,
    .btn-copy {
        letter-spacing: 1px;
        text-align: center;
        font-family: "Pringles-SemiSerif", Arial, "Helvetica Neue", Helvetica, sans-serif;
        @include font-vw($mobile: $button-fs--mobile, $tablet: $button-fs--tablet, $desktop: $button-fs--desktop);
        display: inline-block;
        line-height: 1em;
        padding: 0 !important;
    }
    &.btn--witharr {
        padding: $buttonWithArrow-padding--mobile;
    }

    i {
        display: inline-block;
        margin: $buttonWithArrow-arrow-i-margin--mobile;
        font-family: $font-arrow;
        line-height: 1em;
        @include font-vw($mobile: $button-i-fs--mobile, $tablet: $button-i-fs--tablet, $desktop: $button-i-fs--desktop);

        @include respond-to(tablet) {
            margin: 0 0 0 0.4em;
        }

        @include respond-to(desktop) {
            margin: 0 0 0 0.25em;
            font-size: 38px;
        }

        //commented for trial
        //vertical-align: middle;
    }

    &:focus,
    &:active,
    &:hover,
    &:visited {
        //outline: none;
        border: 0;
    }

    &::-moz-focus-inner {
        padding: 0;
        @include border-radius(8px);
    }

    &.inactive {
        cursor: default;
        pointer-events: none;

        span {
            opacity: 0.65;
        }
    }

    .loader-wrapper {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);

        .loader {
            height: 13px;
            width: 13px;
            border-radius: 100%;
            -webkit-animation: ai-clockwise 0.6s linear infinite;
            animation: ai-clockwise 0.6s linear infinite;
            display: block;
            transform: none;
            -webkit-transform: none;
            -ms-transform: none;
            box-shadow: unset;

            &:before,
            &:after {
                background: none;
            }
        }

        @-webkit-keyframes ai-clockwise {
            to {
                -webkit-transform: rotate(360deg) translateZ(0);
                transform: rotate(360deg) translateZ(0);
            }
        }

        @keyframes ai-clockwise {
            to {
                -webkit-transform: rotate(360deg) translateZ(0);
                -ms-transform: rotate(360deg) translateZ(0);
                transform: rotate(360deg) translateZ(0);
            }
        }
    }
}

.KSTL-Registration button > span {
    padding: 0;
}

a.button.small,
label.button.small,
span.button.small {
    text-transform: none;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 10px;
    cursor: pointer;
    font-size: 22px;
    font-size: 4.375vw;
    font-family: $font-andes-semiBold;
    letter-spacing: inherit;

    @include respond-to(tablet) {
        font-size: 1.5625vw;
    }

    @include respond-to(desktop) {
        font-size: 15px;
    }

    & + .upload-img {
        display: none;
    }
}

.register-form {
    .KSTL-Registration-facebook button {
        background-repeat: no-repeat;
        font-size: 24px;
    }
    .form-group {
        text-align: left;
    }
    label {
        font-size: 14px;
        text-align: left;
    }
    #KSTL-Registration-wrapper-ForgotPasswordLink {
        .ng-scope {
            margin: 0;
            padding: 0;
        }
    }
}

/////////////////////////////
/// LAYOUT, GRID - STYLE
/////////////////////////////
.main-wrapper{
    background: #df032b url("/Content/img/home/celebrate2022-body-bg-mobile.jpg");
    background-position: left top;
    background-size: cover;
    background-repeat:no-repeat;
    @include respond-to(tablet){
        background-image: url("/Content/img/home/celebrate2022-body-bg-desktop.jpg");
    }

}
main {
    // background: transparent url("/Content/img/home/celebrate2022-body-bg-mob.png") no-repeat center bottom / 100% auto;
    padding-bottom: 80vw;
    @include respond-to(tablet) {
        // background-image:url("/Content/img/home/celebrate2022-body-bg.png");
        // background-position: center top;
        padding-bottom: 7vw;
    }
    > .container {
        background-color: $white;
        background-image: url("/Content/img/home/celebrate2022-body-bg2-mobile-top.png"),
            url("/Content/img/home/celebrate2022-body-bg2-mobile-bottom.png");
        background-position: center top, center bottom;
        background-repeat: repeat-x;
        background-size: contain;
        position: relative;
        z-index: 900;
        margin-bottom: 20vw;
        width: 90%;
        text-align: center;
        margin: $container-margin--mobile;
        padding: $twocols-padding--mobile;
        @include border-radius(5px);

        @include respond-to(tablet) {
            background-image: url("/Content/img/home/celebrate2022-body-bg2-desktop-top.png"),
                url("/Content/img/home/celebrate2022-body-bg2-desktop-right.png"),
                url("/Content/img/home/celebrate2022-body-bg2-desktop-bottom.png"),
                url("/Content/img/home/celebrate2022-body-bg2-desktop-left.png");
            background-position: center top, right top, center bottom, left top;
            background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
            background-size: auto 40px, 11% auto;
            @include border-radius(10px);
            width: 67%;
            margin: $container-margin--desktop;
            padding: $twocols-padding--desktop;
        }
        @include respond-to(tablet-only) {
            padding: $twocols-padding--tablet;
            width: 80%;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: -76vw;
            right: 0;
            left: -5vw;
            background: url("/Content/img/home/celebrate2022-bottom-chips-can-mobile.png") no-repeat center center / 100%
                auto;
            width: 100vw;
            height: 100vw;
            @include respond-to(tablet) {
                background: url("/Content/img/home/celebrate2022-bottom-chips-can.png") no-repeat center center / 100%
                auto;
                bottom: -8vw;
                right: -17vw;
                width: 24vw;
                left: auto;
                height: 40vw;
            }
        }
    }
    .main-wrapper {
        padding: 0 10vw;

        .js-setheight {
            background-color: $bodybgcolor;
        }

        .can-section {
            position: absolute;
            content: "";
            z-index: 1000;
            background: url("/Content/img/home/celebrate2022-can-mobile.png") no-repeat center 100%/100%;
            width: 65vw;
            height: 110vw;
            bottom: 0;
            right: 0;
            @include respond-to(tablet) {
                background: url("/Content/img/home/celebrate2022-can.png") no-repeat center 100%/100%;
                width: 26vw;
                height: 45vw;
            }
        }
        .can-section-russia {
            position: absolute;
            content: "";
            z-index: 1000;
            background: url("/Content/img/home/celebrate2022-can-mobile-russia.png") no-repeat center 100%/100%;
            width: 65vw;
            height: 110vw;
            bottom: 0;
            right: 0;
            @include respond-to(tablet) {
                background: url("/Content/img/home/celebrate2022-can-russia.png") no-repeat center 100%/100%;
                width: 26vw;
                height: 41vw;
            }
        }
    }

    .container {
        &.register-wrapper {
            @include respond-to(mobile-only) {
                text-align: center;
            }

            @include respond-to(tablet) {
                #reg-container .KSTL-Registration-Registration,
                #reg-container .KSTL-Registration-SocialMetaData {
                    > div {
                        max-width: 930px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }

            #reg-container .KSTL-Registration-Registration {
                @include respond-to(mobile-only) {
                    margin-left: auto;
                    margin-right: auto;
                }

                @include respond-to(tablet) {
                    margin: 0 0 2vw;
                    padding: 0;
                    // padding-left: 5.2vw;
                    // padding-right: 5.2vw;
                }
            }
        }

        &.register-wrapper,
        &.login {
            #KSTL-Registration-wrapper-email_h {
                margin: 0 !important;
                padding: 0 !important;
            }

            //
            //             #KSTL-Registration-wrapper-AddressLine1 {
            //                 opacity: 0;
            //                 width: 0;
            //                 height: 0;
            //                 overflow: hidden;
            //                 flex: none;
            //                 padding: 0;
            //                 margin: 0;
            //
            //                  @include respond-to(mobile-only) {
            //                     flex: 0 0 100%;
            //                 }
            //             }

            @include respond-to(mobile-only) {
                padding: 10vw 0 7vw;

                h3,
                p {
                    padding: 0 7vw;
                }

                [id*="KSTL-Registration-wrapper"],
                #KSTL-Registration-wrapper-TermsConditions1,
                #KSTL-Registration-wrapper-PrivacyPolicy,
                #KSTL-Registration-wrapper-HeaderSubscription,
                button {
                    max-width: 84%;
                    margin-left: auto !important;
                    margin-right: auto !important;
                }

                #KSTL-Registration-wrapper-ContinueButton1 {
                    // width: auto;
                    // max-width:100%;
                    text-align: center;
                }

                #KSTL-Registration-recaptcha-Registration > div {
                    // margin:0 auto;
                }
            }
        }
    }

    .container.outofhour {
        @include respond-to(tablet) {
            min-height: 20vw;
            display: flex;
            align-items: center;
        }
    }
}

/* first solution for 2cols grid */
.row {
    // @include grid-row();
}

.col-left,
.col-right {
    // @include grid-col(50%, null);
}

picture {
    display: block;
}

/* second solution for 2cols grid */
.twocols {
    display: flex;
    flex-direction: column;
    @include respond-to(tablet) {
        flex-direction: row;
    }
    > div {
        display: block;
        width: 100%;

        @include respond-to(tablet) {
            width: 50%;
            vertical-align: top;
        }

        &:first-child {
            > div {
                @include respond-to(tablet) {
                    margin-right: $leftCol-marginright--tablet;
                }

                @include respond-to(tablet) {
                    margin-right: $leftCol-marginright--desktop;
                }
            }
        }

        &:last-child {
            > div {
                @include respond-to(tablet) {
                    margin-left: $rightCol-marginleft--tablet;
                }
            }
            li {
                list-style-type: none;
            }
            .buy-now {
                -moz-transform: rotate(-90deg);
                -webkit-transform: rotate(-90deg);
                -o-transform: rotate(-90deg);
                -ms-transform: rotate(-90deg);
                transform: rotate(-90deg);
                position: absolute;
                padding: 10px 0px;
                border-radius: 2px !important;
                font-size: 16px;
                width: 105px;
                right: -35px;
                top: 68%;
                font-family: $font-andes-medium;

                @include respond-to(tablet) {
                    top: 24%;
                }
            }
        }

        form {
            button,
            .button {
                width: 100%;

                &.small {
                    width: auto;
                }
            }
        }
    }
}

//////////////////////////////////////////////
//  CRITICAL COMMUNICATION
//////////////////////////////////////////////
.country-swich {
    display: none;
}

#error_wp {
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.7);

    img {
        width: auto;
    }

    a {
        color: #fff;
        text-decoration: none;
        vertical-align: middle;
    }

    p {
        margin: 0;
        padding: 0;
        vertical-align: middle;
    }

    .errorwrap {
        padding: 1em 1em 0;

        &:last-child {
            padding-bottom: 1em;
        }
    }
}

//////////////////////////////////////////////
//  ONLY FOR TESTS
//////////////////////////////////////////////
.cookies {
    * {
        width: auto;
        margin: 0;
        text-align: center;

        @include respond-to(tablet) {
            text-align: left;
            margin-left: 4px;
        }
    }
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//      -webkit-animation-name: autofill;
//     -webkit-animation-fill-mode: both;
// }
// @-webkit-keyframes autofill {
//     to {
//         color: #fff;
//         background: transparent;
//     }
// }
input {
    filter: none;
}
// .main-wrapper .container.register-wrapper #KSTL-Registration-wrapper-ContinueButton1 button{
//      @include respond-to(mobile-only) { max-width: 75%;}
// }

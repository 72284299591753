//buttons
$button-padding--mobile: 0.17em 0.2em 0.29em;
$button-padding--tablet: 0 0 0.7vw 0;
$button-padding--desktop: 0 0 0.7vw 0;
$button-padding-bottom--tablet: 0.7vw;
$button-padding-bottom--desktop: 0.7vw;

$buttonWithArrow-padding--mobile: 6.3% 11% 6.3% 2%;

$button-margin--mobile: 0 0 0;
$button-margin--tablet: 0 0;
$button-margin--desktop: 0 0;

$home-button-margin--mobile: 0 0;
$home-button-margin--tablet: 0 0;
$home-button-margin--desktop: 0 0;

$buttonWithArrow-arrow-i-margin--mobile: 0 0 0 53%;
$buttonWithArrow-arrow-i-margin--tablet: -0.18em 0 0 0.4em;
$buttonWithArrow-arrow-i-margin--desktop: 0 0 6px 0.25em;

$label-home-margin--mobile: 0 0 1vw;
$label-home-margin--tablet: 0 0 1vw;
$label-home-margin--desktop: 0 0 1vw;
/////////////////////////////
///	GRID - OTHERS VARIABLES
/////////////////////////////
$maincontainer-margin--mobile: 0 auto;
$maincontainer-margin--tablet: 0 auto;
$maincontainer-margin--desktop: 0 auto;

$container-maxsize--mobile: 1100px;
$container-maxsize--tablet: 90%;
$container-maxsize--desktop: 90%;
$container-maxsize--desktophome: 90%;
$container-maxsize--desktoplg: 1220px;
$container-maxsize--largedesktop: 75%;

$container-margin--mobile: 5vw auto 0;
$container-margin--tablet: -9vw auto 0;
$container-margin--desktop: 0 auto;

$footer-container-margin--mobile: 0 auto;
$footer-container-margin--tablet: 0 auto;
$footer-container-margin--desktop: 0 auto;

$content-padding--mobile: 0 7.8vw;
$content-padding--tablet: 0 0;
$content-padding--desktop: 0 0;
//2Cols
$twocols-margintop--mobile: 0;
$twocols-margintop--tablet: -5.7vw;
$twocols-margintop--desktop: -5.7vw;
$twocols-padding--mobile: 12vw 5vw 20vw;
$twocols-padding--tablet: 5vw 10vw;
$twocols-padding--desktop: 5vw 10vw;
$twocols-hr-margin--mobile: 2vw 0;
$twocols-hr-margin--tablet: 4vw 5vw;
$twocols-hr-margin--desktop: 4vw 5vw;

$leftCol-marginleft--mobile: auto;
$leftCol-marginleft--tablet: 5.7vw;
$leftCol-marginleft--desktop: 5.7vw;

$leftCol-marginright--mobile: auto;
$leftCol-marginright--tablet: 5.7vw;
$leftCol-marginright--desktop: 5.7vw;

$rightCol-marginleft--mobile: auto;
$rightCol-marginleft--tablet: 2vw;
$rightCol-marginleft--desktop: 2vw;
$rightCol-marginright--mobile: auto;
$rightCol-marginright--tablet: 5vw;
$rightCol-marginright--desktop: 10vw;

/////////////////////////////
///	FORM - OTHERS VARIABLES
/////////////////////////////
$formcontrol-padding--mobile: 0 0 6% 0;
$formcontrol-padding--tablet: 0 0 3% 0;
$formcontrol-padding--desktop: 0 0 4% 0;

$input-padding--mobile: 2.25vw;
$input-padding--tablet: 0.9vw 1.21vw;
$input-padding--desktop: 0.9vw 0.5vw;

$home-input-padding--mobile: 2.25vw;
$home-input-padding--tablet: 1vw;
$home-input-padding--desktop: 1vw;

$label-padding--mobile: 0 0 3%;
$label-padding--tablet: 5% 0;
$label-padding--desktop: 2.4% 0 3%;

$error-padding--mobile: 0 0 0.8%;
$error-padding--tablet: 0 0 0.8%;
$error-padding--desktop: 0 0 0.8%;

$error-margin--mobile: 0 0 0.5vw;
$error-margin--tablet: 0 0 0.5vw;
$error-margin--desktop: 0 0 0.5vw;

$error-next-margin--mobile: 2.3% 0 0;
$error-next-margin--tablet: 2.3% 0 0;
$error-next-margin--desktop: 2.3% 0 0;

$triangle-size--mobile: 1.1em;
$triangle-size--tablet: 1.1em;
$triangle-size--desktop: 1.1em;

$placeholder-lineheight-webkit--m: 1.3;
$placeholder-lineheight-moz1--m: 1;
$placeholder-lineheight-moz2--m: 1;
$placeholder-lineheight-ms--m: 1.2;

/////////////////////////////
/// FOOTER - OTHERS VARIABLES
/////////////////////////////
$footer-margin--tablet: 4% auto 0;
$footer-margin--desktop: 4% auto 0;
$footer-text-maxwidth--tablet: 56%;

$footer-link-padding--mobile: 5.46875%;
$footer-link-padding--desktop: 0 0.4em;

$footer-copyright-padding--mobile: 5.46875%;
$footer-copyright-padding--tablet: 0;

$footer-text-lineheight--mobile: 1.5;
$footer-text-lineheight--tablet: 1.25;
$footer-text-lineheight--desktop: 1.5;

$footer-text-padding--mobile: 4.6vw;
$footer-text-padding--tablet: 0 1vw;
$footer-text-padding--desktop: 0 1vw;

$footer-text-margin--mobile: 0 auto 0.78vw;
$footer-text-margin--tablet: 0 auto 0.78vw;
$footer-text-margin--desktop: 0 auto 0.78vw;

$footer-text-maxwidth--desktop: 50%;

$footer-greyborder: 2px solid $grey9;
$footer-darkgreyborder: 2px solid $grey10;
$footer-textborder: 1px solid $btn-text-grey;

/////////////////////////////
/// HOME - OTHERS VARIABLES
/////////////////////////////

$homeLeftCol-margin--mobile: 0 0 0;
$homeLeftCol-margin--tablet: 0 3vw 0 0;
$homeLeftCol-margin--desktop: 0 2vw 0 0;

$homeLeftColheadertext-margin--mobile: 0 0 5vw;
$homeLeftColheadertext-margin--tablet: 0 0 1vw;
$homeLeftColheadertext-margin--desktop: 0 0 1vw;

$homeLeftColtext-margin--mobile: 0 0 4vw;
$homeLeftColtext-margin--tablet: 0 0 0.4vw;
$homeLeftColtext-margin--desktop: 0 0 0.4vw;

$homeLeftColInput-margin--mobile: 0 0 4vw;
$homeLeftColInput-margin--tablet: 0 0 2vw;
$homeLeftColInput-margin--desktop: 0 0 2vw;

/////////////////////////////
/// THANK YOU - OTHERS VARIABLES
/////////////////////////////

$social-marginleft--mobile: 5%;
$social-marginright--mobile: 5%;

$social-marginleft--desktop: 2%;
$social-marginright--desktop: 2%;

/////////////////////////////
///	REGISTER - OTHERS VARIABLES
/////////////////////////////
$register-margin--mobile: 0 0 2%;
$register-margin--tablet: 0;
$register-margin--desktop: -1.7em 0 0;

$register-h-margin--mobile: 0 0 4%;
$register-h-margin--tablet: 0 0 -1.1%;
$register-h-margin--desktop: 0 0 0.5em;

$register-text-lineheight--mobile: 1.2;

$register-text-padding--tablet: 0 0 3.3%;
$register-text-padding--desktop: 0 0 3.4%;

$register-text-textalign--mobile: center;
$register-text-textalign--tablet: center;
$register-text-textalign--desktop: center;

$register-content-padding--mobile: 4.1% 0 34vw;
$register-content-padding--tablet: 4.1% 1.3% 10vw 1.3%;
$register-content-padding--desktop: 3% 1.3% 10vw 1.3%;

$grm-colleft-paddingleft--tablet: 1%;
$grm-colright-paddingleft--tablet: 0;
$grm-colright-paddingright--tablet: 3%;

$grm-colleft-paddingleft--desktop: 3%;
$grm-colright-paddingleft--desktop: 0;
$grm-colright-paddingright--desktop: 3%;

$grm-input-padding--tablet: 17px 0.8em;
$grm-input-padding--desktop: 0.85em 1em;

$grm-input-padding--mobile: 2vw;
$grm-input-padding--tablet: 0.9vw;
$grm-input-padding--desktop: 0.9vw;

$grm-input-bordersizestyle: 1px solid;

$grm-label-padding--mobile: 0 0.21vw 0.21vw 3.1vw;
$grm-label-padding--tablet: 0 0.21vw 0.21vw 1.21vw;
$grm-label-padding--desktop: 0 0.21vw 0.21vw 1.21vw;

$grm-label-marginbottom--tablet: -2%;

$grm-label-requiredstar-rightposition: -0.42em;
$grm-label-requiredstar-margintop: 0.01em;

$grm-login-label-padding--mobile: 0 0 0.5vw;
$grm-login-label-padding--tablet: 0 0 0vw;
$grm-login-label-padding--desktop: 0 0 0vw;

$grm-login-label-margin--mobile: 0;
$grm-login-label-margin--tablet: 0;
$grm-login-label-margin--desktop: 0;

$grm-login-label-talign--mobile: left;
$grm-login-label-talign--tablet: left;

$grm-resetpass-login-label-padding--tablet: 5.6% 0;
$grm-resetpass-login-label-padding--desktop: 5.6% 0;

$grm-fcontrol-paddingbottom--tablet: 1.5vw;

$grm-fcontrol-marginbottom--tablet: 1.5vw;
$grm-fcontrol-marginbottom--mobile: 4vw;
$grm-button-margin--tablet: 3vw auto 0;
$grm-button-margin--desktop: 3vw auto 0;

$grm-button-padding--mobile: 0.4em 0.2em 0.5em;
$grm-button-padding--tablet: 1.4em 1em;
$grm-button-padding--desktop: 1.5em 1.3em;

$grm-button-arrow-width: 0.34em;
$grm-button-arrow-height: 0.68em;
$grm-button-arrow-margintop: -$grm-button-arrow-width;
$grm-button-arrow-rightposition: 0;

$grm-button-arrow-lineheight: 1;

$grm-button-arrow-padding--mobile: 0 1em 0 0.6em;
$grm-registerbutton-padding--tablet: 2.8% 2%;
$grm-registerbutton-padding--desktop: 3.2% 2%;

$grm-login-button-padding--tablet: 1em;
$grm-login-button-padding--desktop: 1em 1.3em;

$grm-resetpass-button-padding--tablet: 0.4% 0 1.2%;
$grm-resetpass-button-padding--desktop: 0.4% 0 7.2%;

$grm-button-maxwidth--tablet: 62%;
$grm-button-maxwidth--desktop: 64%;

$grm-wraperbutton-width--tablet: 100%;

$grm-wraperterms-width--tablet: auto;

$grm-terms-mheight--tablet: 1.1em;

$grm-termslabel-padding--mobile: 4px 0 0 45px;
$grm-termslabel-padding--tablet: 0 0 0 3em;
$grm-termslabel-padding--desktop: 0 0 0 3em;

$grm-checkbox-margintop--mobile: 1.2em;
$grm-checkbox-width--mobile: 2.22em;
$grm-checkbox-height--mobile: 2.3em;
$grm-checkbox-bradius--mobile: 8px;

$grm-checkbox-minheight--mobile: 2.4em;
$grm-checkbox-minheight--tablet: 3.2em;

$grm-checkbox-margintop--tablet: 1.1em;
$grm-checkbox-width--tablet: 2.5em;
$grm-checkbox-height--tablet: 2.59em;

// $grm-checkbox-margin--tablet: 1.6em; 0 0 1.6em - 0.2em;
// $grm-checkbox-margin--desktop: 0 0 1.2em;

$grm-checkbox-margintop--desktop: 1em;
$grm-checkbox-width--desktop: 1.58em;
$grm-checkbox-height--desktop: 1.64em;
$grm-checkbox-minheight--desktop: 3.7em;

$grm-terms-margintop--mobile: 6%;
$grm-terms-margintop--desktop: 7.5%;

$grm-placeholder-lineheight-webkit--m: 1.2;
$grm-placeholder-lineheight-moz1--m: 1;
$grm-placeholder-lineheight-moz2--m: 1;
$grm-placeholder-lineheight-ms--m: 1;

$grm-error-padding--mobile: 0;
$grm-error-margin--mobile: 0;
$grm-error-margin--tablet: 0;

$grm-error-triangle-tposition--mobile: 100%;

$grm-error-triangle-size--mobile: 0.45em;
$grm-error-triangle-size--tablet: 0.5em;

$grm-content-border-radius--desktop: 8px;
$grm-content-margintop--desktop: 0;

/////////////////////////////
///	LOGIN - OTHERS VARIABLES
/////////////////////////////
$displayDefaultGRMLoginTitle: none;

$login-text-margin--mobile: 6% 5% 5.2%;
$login-text-margin--tablet: 8% 7.2% 0.3%;
$login-text-margin--desktop: 8% 7.2% 0.3%;
$login-text-lineheight--mobileex: 1.2;
$login-text-lineheight--desktop: 1.6;

$login-buttonlink-margin--mobile: 11% 0 -0.3%;
$login-buttonlink-margin--tablet: 2.5em 0 0;
$login-buttonlink-margin--desktop: 1.3em 0 0;

$login-margin--desktop: 0 0 0;

$login-header-textalign--mobile: left;
$login-header-textalign--tablet: left;
$login-header-textalign--desktop: left;

$login-text-textalign--mobile: center;
$login-text-textalign--tablet: left;
$login-text-textalign--desktop: left;

//2cols - paddings
$login-leftCol-paddingtop--mobileex: 2%;
$login-leftCol-paddingbottom--mobileex: 9%;
$login-leftCol-paddingright--mobileex: 16%;
$login-leftCol-paddingleft--mobileex: 16%;

$login-leftCol-paddingtop--desktop: 0%;
$login-leftCol-paddingbottom--desktop: 0%;
$login-leftCol-paddingright--desktop: 0;
$login-leftCol-paddingleft--desktop: 0;

$login-leftCol-paddingright--tablet: 0;

$login-rightCol-paddingtop--mobileex: 6vw;
$login-rightCol-paddingbottom--mobileex: 0;

$login-rightCol-paddingtop--tablet: 6vw;
$login-rightCol-paddingbottom--tablet: 0;
$login-rightCol-paddingtop--desktop: 6vw;
$login-rightCol-paddingbottom--desktop: 0;

// login - grm style
$grm-login-button-margin--tablet: 1em 0 0;
$grm-login-button-margin--desktop: 1em 0 0;

$grm-container-margintop--desktop: -1.6%;

$grm-login-passwordlink-margin--mobile: 0 0 0;
$grm-login-passwordlink-margin--tablet: 0.21vw 0 0 0;
$grm-login-passwordlink-margin--desktop: 0.21vw 0 0 0;

$grm-login-passwordlink-textalign--tablet: center;
$grm-login-passwordlink-textalign--desktop: center;

/////////////////////////////
///	RESET - OTHERS VARIABLES
/////////////////////////////
$grm-resetpass-title-display--mobile: block;
$grm-resetpass-title-display--tablet: none;
$grm-resetpass-title-display--desktop: none;

$reset-content-margintop: -1.5%;

$reset-content-maxwidth--tablet: 44%;
$reset-content-maxwidth--desktop: 44%;

$grm-reset-text-margin--mobile: -0.8em 0 1em;
$grm-reset-text-margin--tablet: -0.8em 0 1em;
$grm-reset-text-margin--desktop: -0.2em 0 0.1em;

$grm-reset-text-lineheight--mobileex: 1.25;
$grm-reset-text-lineheight--desktop: 1.6;

$grm-resetpass-container-margintop--tablet: -1.1%;
$grm-resetpass-container-margintop--desktop: -1.1%;

/////////////////////////////
///	SOCIAL - OTHERS VARIABLES
/////////////////////////////
$social-margin-top--mobile: 0;
$social-margin-top--tablet: 1%;
$social-margin-top--desktop: 1%;

$social-margin-bottom--mobile: 8.5%;
$social-margin-bottom--tablet: 7.6%;
$social-margin-bottom--desktop: 5.6%;

$grm-social-padding--mobile: 2.7vw;
$grm-social-padding--tablet: 1.7vw;
$grm-social-padding--desktop: 1.7vw;

$grm-social-margin--mobile: 0 0 9vw;
$grm-social-margin--tablet: 0 0 2vw;
$grm-social-margin--desktop: 0 0 2vw;

$social-icon-width--mobile: 3.9em;
$social-icon-width--tablet: 5.4em;
$social-icon-width--desktop: 4em;

$social-icon-height--mobile: 3.9em;
$social-icon-height--tablet: 5.4em;
$social-icon-height--desktop: 4em;

// $social-icon-i-lineheight--mobile: 1.95em;
// $social-icon-i-lineheight--tablet: 1.7em; //old
$social-icon-i-lineheight--mobile: 1.9em;
$social-icon-i-lineheight--tablet: 1.8em;
$social-icon-i-lineheight--desktop: 1.8em;

// $social-icon-i-lineheight-fb--mobile: 1.3em; //old
$social-icon-i-lineheight-fb--mobile: 1.25em;
$social-icon-i-lineheight-fb--tablet: 1.3em;
$social-icon-i-lineheight-fb--desktop: 1.3em;

// $social-icon-spacebeetween--mobile: 1.8em; //old before change - to check in MS
// $social-icon-spacebeetween--tablet: 1.2em; //old before change - to check in MS

$social-icon-spacebeetween--mobile: 1em;
$social-icon-spacebeetween--tablet: 1em;
$social-icon-spacebeetween--desktop: 1em;

$social-border-size: 2px;

/////////////////////////////
///	SHIPPING - OTHERS VARIABLES
/////////////////////////////
$shipping-margin-mobile: 0;
$shipping-margin-tablet: -1.6% 0 0;
$shipping-margin-desktop: -0.9% 0 0;

$shipping-firstrow-padding--mobile: 11.1% 8% 10%;
$shipping-firstrow-padding--tablet: 11.4% 3.2% 5.5% 3.6%;
$shipping-firstrow-padding--desktop: 11.4% 3.2% 5.5% 3.6%;

$shipping-firstrow-text-margin--mobile: -0.3em 6% 4%;
$shipping-firstrow-text-margin--tablet: -1em 6% -3.01%;
$shipping-firstrow-text-margin--desktop: -1em 6% -3.01%;

$shipping-formcontrol-padding-mobile: 0 0 4vw 0;
$shipping-formcontrol-padding-tablet: 0 0 0.5% 0;
$shipping-formcontrol-padding-desktop: 0;

$shipping-label-texttransofrm--mobile: none;

$shipping-label-padding--mobile: 1.05em 0 0.75em;
$shipping-label-padding--tablet: 1em 0;
$shipping-label-padding--desktop: 1.5em 0 0.9em;

$shipping-label-textalign--mobile: center;
$shipping-label-textalign--tablet: center;

$shipping-input-texttransofrm--tablet: 0.89em 0.6em;
$shipping-input-texttransofrm--desktop: 0.89em 0.6em;

$shipping-input-padding--mobile: 1em 0.85em 1em 0.85em;
$shipping-input-padding--tablet: 1.33em 1.3em;
$shipping-input-padding--desktop: 1.33em 1.3em;

$shipping-secondrow-padding--mobile: 8.3% 8% 9.2%;
$shipping-secondrow-padding--tablet: 2.6% 2.7%;
$shipping-secondrow-padding--desktop: 2.6% 2.7%;

$shipping-button-margin--mobile: 1.8% 0 0;
$shipping-button-margin--tablet: -1.5% 0 0;

$shipping-button-padding--mobile: 1.1em 1em;
$shipping-button-padding--tablet: 1.1em 1em;
$shipping-button-padding--desktop: 1em 0.6em;

$shipping-button-maxwidth--tablet: 44%;

$shipping-mandatoryfieldsinfo-margin--mobile: 6.3% 0 0;
$shipping-mandatoryfieldsinfo-margin--tablet: 2.1% 0 -2.5%;
$shipping-mandatoryfieldsinfo-margin--desktop: 2.1% 0 -2.5%;

$shipping-firstrow-afterelement-display--mobile: block;

$shipping-firstrow-afterelement-height--mobile: 10px;

$shipping-firstrow-boxshadow--mobile: 0px 0px 2px 1px rgba(0, 0, 0, 0.11);

$shipping-secondrow-bordertop--mobile: none;
$shipping-secondrow-bordertop--tablet: none;

$shipping-secondrow-text-margin--mobile: -3.1% 0 3.5%;
$shipping-secondrow-text-margin--tablet: -2.3% 6% 0.7%;
$shipping-secondrow-text-margin--desktop: -2.3% 6% 0.7%;

$shipping-secondrow-text-lineheight--tablet: 1.3;
$shipping-secondrow-text-lineheight--desktop: 1.5;

$shipping-secondrow-bordertop--mobile: solid 1px rgba(#ffffff, 0.3);

$shipping-termstitle-texttransofrm--mobile: uppercase;
$shipping-termstitle-texttransofrm--tablet: none;

$shipping-termstitle-padding--mobile: 5% 0 0;
$shipping-termstitle-padding--tablet: 4.55% 0 0;
$shipping-termstitle-padding--desktop: 3.55% 0 0;

$shipping-termstitle-margin--mobile: 0 0 7.3%;
$shipping-termstitle-margin--tablet: 0 0 3.5%;
$shipping-termstitle-margin--desktop: 0 0 2.5%;

$shipping-termslabel-padding--mobile: $grm-termslabel-padding--mobile;
$shipping-termslabel-padding--tablet: 0 0 0 3.7em;
$shipping-termslabel-padding--desktop: 0 0 0 3.4em;

$shipping-secondrow-textshadow--mobile: none;
$shipping-secondrow-textshadow--tablet: none;

$shipping-termslabel-margin--mobile: 0;

$shipping-terms-label-textalign--mobile: left;
$shipping-terms-label-textalign--tablet: left;
$shipping-terms-label-textalign--desktop: left;

$shipping-terms-label-link-textdecoration--mobile: underline;
$shipping-terms-label-link-textdecoration--tablet: underline;
$shipping-terms-label-link-textdecoration--desktop: underline;

$shipping-checkbox-margintop--mobile: -1.3em;
$shipping-checkbox-height--mobile: 2.5em;
$shipping-checkbox-width--mobile: 2.5em;

$shipping-checkbox-margintop--tablet: -1.45em;
$shipping-checkbox-height--tablet: 2.8em;
$shipping-checkbox-width--tablet: 2.8em;

$shipping-checkbox-margintop--desktop: -1.4em;
$shipping-checkbox-height--desktop: 2.6em;
$shipping-checkbox-width--desktop: 2.6em;

$shipping-selecticon-bacgroundsize--mobile: auto 100%;
$shipping-selecticon-bacgroundsize--tablet: auto 100%;
$shipping-selecticon-bacgroundsize--desktop: auto 100%;

$shipping-selecticon-bgstyle--mobile: no-repeat right 0 #fafafa;
$shipping-selecticon-bgstyle--tablet: no-repeat right 0 #fafafa;
$shipping-selecticon-bgstyle--desktop: no-repeat right 0 #fafafa;

$shipping-select-padding--mobile: 1.2em 4.2em 1.2em 0.85em;
$shipping-select-padding--tablet: 1.4em 5.2em 1.4em 1.4em;
$shipping-select-padding--desktop: 1.4em 0 1.3em 1.4em;

$shipping-selectwrapper-width--mobile: 100%;

$shipping-selectwrapper-transition: border linear 0.2s, box-shadow linear 0.2s;

$shipping-selectwrapper-border--mobile: 1px solid rgba(67, 67, 67, 0.2);

$shipping-selectwrapper-boxshadow--mobile: inset 0 1px 1px rgba(0, 0, 0, 0.075);

$shipping-selectwrapper-borderradius--mobile: 3px;
$shipping-selectwrapper-borderradius--tablet: 3px;
$shipping-selectwrapper-borderradius--desktop: 3px;

$shipping-select-width--mobile: 105%;

$shipping-select-letterspacing--mobile: 0;
$shipping-select-letterspacing--tablet: 1px;
$shipping-select-letterspacing--desktop: 0;

$shipping-input-letterspacing--mobile: 1;
$shipping-input-letterspacing--tablet: 1px;
$shipping-input-letterspacing--desktop: 0;

$shipping-controlright-paddingleft--tablet: 2.1%;
$shipping-controlright-paddingleft--desktop: 2.4%;

$shipping-controlleft-paddingright--tablet: 2.1%;
$shipping-controlleft-paddingright--desktop: 2.4%;

$shipping-checkbox-bradius--mobile: $grm-checkbox-bradius--mobile;

$shipping-checkboxempty-bgsize--mobile: 100% 100%;

$shipping-button-i-margin--mobile: -0.16em 0 0 0.45em;
$shipping-termslabel-lineheight--mobile: 1.4;

/////////////////////////////
///	FAQ/TERMS - OTHERS VARIABLES
/////////////////////////////
$faq-headline-borderbottom: 1px solid $grey4;
$faq-headline-borderbottom-display--mobile: $faq-headline-borderbottom;
$faq-headline-borderbottom-display--tablet: $faq-headline-borderbottom;
$faq-headline-borderbottom-display--desktop: $faq-headline-borderbottom;
$faq-headline-padding--mobile: 4.8% 3% 2.2%;
$faq-headline-padding--tablet: 0 3% 2.3%;
$faq-headline-padding--desktop: 2.1% 4% 1.5%;

$faq-heading-padding--mobile: 0 2.3% 0;
$faq-heading-padding--tablet: 0 2.3% 0;
$faq-heading-padding--desktop: 0 2.3% 0;

$faq-text-padding--mobile: 5% 20px;
$faq-text-padding--tablet: 2.2% 3%;
$faq-text-padding--desktop: 3.7% 3%;

$table-border: 1px solid $black;

//harvest
$shipping-secondrow-textshadow--mobile: -1px 1px 1px rgba(0, 0, 0, 1);
$shipping-selectwrapper-boxshadow--mobile: none;
$shipping-selectwrapper-border--mobile: 0;

$shipping-selectwrapper-borderradius--mobile: 8px;
$shipping-selectwrapper-borderradius--tablet: 4px;
$shipping-selectwrapper-borderradius--desktop: 4px;

$primary-bg-color: #d50e0e;
$primary-copy-color: $white;
$secondary-copy-color: $btn-text-grey;

$primary-button-color: $yellow;
$primary-button-copy-color: $btn-text-grey;
$primary-button-hover: #ffbc2c;
$secondary-button-color: $red;
$secondary-button-copy-color: $white;
$secondary-button-hover: #bd1924;

// =============================================================================
// Others variables
// =============================================================================
/////////////////////////////
///	LAYOUT - OTHERS VARIABLES
/////////////////////////////
$col-beetween-space: 30px; //px
$content-beetween-space: 20px;

$nextcontent-margin--mobile: 0;
$nextcontent-margin--tablet: 0 0;
$nextcontent-margin--desktop: 0 0;

$col-beetween-space-bgtransparent-left: 20px/2; //more space, when bg transparent

// =============================================================================
// Images - variables
// =============================================================================

$grm-radioempty: url("/Content/img/icons/h_register_gender_icon.png") no-repeat;

$shipping-checkboxempty--mobile: url("/Content/img/icons/h_shipping-checkbox-empty_m.png");
$shipping-checkboxchecked--mobile: url("/Content/img/icons/h_shipping-checkbox-checked_m.png");

$shipping-checkboxempty--tablet: url("/Content/img/icons/h_shipping-checkbox-empty_td.png");
$shipping-checkboxchecked--tablet: url("/Content/img/icons/h_shipping-checkbox-checked_td.png");

$shipping-selecticon--mobile: url("/Content/img/icons/h_shipping-select_m.png");
$shipping-selecticon--tablet: url("/Content/img/icons/h_shipping-select_t.png");
$shipping-selecticon--desktop: url("/Content/img/icons/h_shipping-select_d.png");

$terms-backbtn: url("/Content/img/icons/h_close-icon.png");

// =============================================================================
// Others variables
// =============================================================================
/////////////////////////////
///	LAYOUT - OTHERS VARIABLES
/////////////////////////////
$col-beetween-space: 90px;
$nostandard-smallerspaces-bettwencols--tablet: 24px;

$h2-texttransform: uppercase;
$h2-texttransform--mobile: uppercase;

$h3-texttransform: none;

$h3-padding--mobile: 0 0 3.73794% 0;

$text-margin--tablet: 0;
$text-margin--desktop: 0;

$text-lineHeight--tablet: 1.3;

//$text-padding--mobile: 0 6% 5.3%;
$text-padding--tablet: 0 6% 4%;
$text-padding--desktop: 0 6% 3%;

//$mainContent-margintop--mobile: 0%;
//$mainContent-margintop--tablet: -21%;
//$mainContent-margintop--desktopsm: -21%;
//$mainContent-margintop--desktop: -21%;
//$mainContent-margintop--largedesktop: -22%;

$nextcontent-margin--mobile: 1.1% 0 0; //space beetwen row

$titleSection-content-padding--mobile: 8% 8% 0;
$titleSection-content-padding--tablet: 6% 0 0;
$titleSection-content-padding--desktop: 3.9% 0 0;

$titleSection-margin--mobile: 1px 0 1px;
$titleSection-margin--tablet: 1% 0 0%;
//$titleSection-margin--desktop: 2.7% 0 0%;

//buttons
// $button-margin--mobile: 1em;
$button-margin--tablet: 0.8em 0 0;
$button-margin--desktop: 4.1% 0 0;

//$home-button-margin--tablet: 5.8% 0 0;

$button-padding--tablet: 0.1em 0.3em 0.15em;
$button-padding--desktop: 0.1em 0 0.15em;

/////////////////////////////
///	GRID - OTHERS VARIABLES
/////////////////////////////
$container-maxsize--tablet: 85%;
$container-maxsize--desktop: 80%;
$container-maxsize--desktophome: 954px;

//2Cols
$leftCol-paddingtop--tablet: 10%;
$leftCol-paddingtop-nofirstsection--tablet: 6.3%;
$leftCol-paddingbottom--tablet: 7%;
$leftCol-marginleft--tablet: 7.2%;
$leftCol-marginright--tablet: 7.2%;
$leftColHeader-marginleft--tablet: 6.2%;
$leftColHeader-marginright--tablet: 6.2%;

$leftCol-paddingtop--desktop: 6%;
$leftCol-paddingtop-nofirstsection--desktop: 8.6%;
$leftCol-paddingbottom--desktop: 7%;
$leftCol-marginleft--desktop: 12%;
$leftCol-marginright--desktop: 12%;
$leftColHeader-marginleft--desktop: 7.2%;
$leftColHeader-marginright--desktop: 7.2%;

$leftCol-bgno-paddingtop--mobile: 1%;

$rightCol-paddingtop--mobile: 10%;
$rightCol-paddingbottom--mobile: 8%;

$rightCol-paddingtop--tablet: 8.6%;
$rightCol-paddingbottom--tablet: 7%;

$rightCol-paddingtop--desktop: 7.2%;
$rightCol-paddingbottom--desktop: 7%;

//1Col
$col-padding--mobile: 10vw 0 0;
$col-padding--tablet: 4.6vw 0 0;
$col-padding--desktop: 2.6vw 0 0;

$colOne-margintop--tablet: -2.8%;
$colOne-margintop--desktop: -2.8%;

$colOne-header-padding--tablet: 0 0 2.2% 0;
$colOne-header-padding--desktop: 0 0 1.2% 0;

$colOne-button-margin--tablet: 1.6vw auto 0;
$colOne-button-margin--desktop: 1.6vw auto 0;

$colOne-button-minwidth--tablet: 54%;
$colOne-button-minwidth--desktop: 36%;

/////////////////////////////////
/// TEMPLATES - TRANSPARENT COLUMNS - VARIABLES
/////////////////////////////////
//$transparentBg-LeftCol-padding--tablet: 0 10.1% 0 6.3%;
//$transparentBg-LeftCol-padding--desktop: 0 10.1% 0 6.3%;
$LeftCol-bgno-paddingtop--tablet: 0;
$LeftCol-bgno-paddingbottom--tablet: 0;
$LeftCol-bgno-marginleft--tablet: 6.3%;
$LeftCol-bgno-marginright--tablet: 10.1%;

$LeftCol-bgno-paddingtop--desktop: 0;
$LeftCol-bgno-paddingbottom--desktop: 0;
$LeftCol-bgno-marginleft--desktop: 10.1%;
$LeftCol-bgno-marginright--desktop: 6.3%;

/////////////////////////////
///	FORM - OTHERS VARIABLES
/////////////////////////////
$input-padding--tablet: 0.85em 0.6em;
$input-padding--desktop: 0.65em 0.6em;

$label-padding--mobile: 0 0 3%;
$label-padding--tablet: 5% 0;

$error-padding--mobile: 0 0 0.8%;
$error-padding--tablet: 0 0 0.8%;
$error-padding--desktop: 0 0 0.8%;

$error-margin--mobile: 0 0 0.1em;
$error-margin--tablet: 0 0 0.1em;
$error-margin--desktop: 0 0 0.1em;

/////////////////////////////
/// HOME - OTHERS VARIABLES
/////////////////////////////
$home-imgcontent-margin--mobile: 5% 0 4%;
$home-imgcontent-margin--tablet: 4% 0 4%;
$home-imgcontent-margin--desktop: 4% 0 4%;
$home-imgcontent-width--mobile: 52%;
$home-imgcontent-width--tablet: 90%;
$home-imgcontent-width--desktop: 90%;

/////////////////////////////
///	FAQ/TERMS - OTHERS VARIABLES
/////////////////////////////

$bodybgcolor: #ffc522;
$site-copy-color: $secondary-copy-color;
$form-valid-field: #606060;
$content-header-color: $site-copy-color;
$text-color: #2c2b26;
$input-color: $black-input;
$input-bg-color: $white;
$inputError-color: #db1919;
$placeholder-copy-color: #000;
$placeholder-error-color: #db1919;
$placeholder-color-grm: $site-copy-color;
$inputError-border-color: $inputError-color;
$batch-code-error: $primary-button-color;

$button-bg: $secondary-button-color;
$button-color: $secondary-button-copy-color;
$button-hover-bg: $secondary-button-hover;

$home-button-bg: $primary-button-color;
$home-button-color: $primary-button-copy-color;
$home-button-hover-bg: $primary-button-hover;
$home-container-bg: $primary-bg-color;
$home-copy-color: $primary-copy-color;
$home-img-container-bg: transparent;
$home-img-copy-color: $site-copy-color;

$grm-container-bg: $transparent;
$grm-copy-color: $site-copy-color;
$grm-label-color: $site-copy-color;
$grm-input-bgcolor: $white;
$grm-input-color: $site-copy-color;
$grm-input-border-color: $site-copy-color;
$grm-input-invalid-color: $inputError-color;
$grm-social-bgcolor: rgba(#b7b7b7, 0.15);
$grm-link-color: $site-copy-color;
$grm-checkbox-bg-color: transparent;
$grm-forgotpass-color: $black;
$shipping-checkbox-bg-color: $grm-checkbox-bg-color;

//footer colors
$footer-bgcolor-mobile: $white;
$footer-bgcolor-desktop: $white;
$footer-link-color-mobile: $btn-text-grey;
$footer-link-color-desktop: $btn-text-grey;
$footer-link-color-holding: $btn-text-grey;
$footer-copyright-color-mobile: $btn-text-grey;
$footer-copyright-color-desktop: $btn-text-grey; //use also mixin rgbaForOldBrowser
$footer-text-color--mobile: $btn-text-grey; //use mixin rgbaForOldBrowser
$footer-text-color-desktop: $btn-text-grey;
$footer-grad-top: #fefefe;
$footer-grad-bottom: #e6e6e6;

$faq-bgcolor: $white;
$faq-title-color: #434343;
$faq-copy-color: #434343;

// BREAKPOINTS
$mobile: 1px;
$xs-min: 360px;
$xs-max: ($xs-min - 1);
$sm-min: 768px;
$sm-max: ($sm-min - 1);
$md-min: 992px;
$md-max: ($md-min - 1);
$lg-min: 1200px;
$lg-max: ($lg-min - 1);
$xl-min: 1900px;
$xl-max: ($xl-min - 1);

body[pagename="FAQ"],
body[pagename="TERMS"] {
	main > .container {
		max-width: 100%;
		width: 100%;
		background: none;
		text-align: left;
		position: static;
		&:before {
			content: none; 
		}
	}
}
.faq-page,.terms-page{
    ol.extra-country-nav{
        margin-left:-27px;
        @include respond-to(mobile-only) {
            margin-left:-32px;
        }
    }
}
.popup {
	background-color: rgba($faq-bgcolor, 0.96);
	color: $faq-copy-color;
	width: 100%;
	position: absolute;
	display: none;
	z-index: 3000;
	top: 0;
	left: 0;
	font-size: 100%;
	&.active {
		display: table;
	}
	ul.bbc-list {
		list-style: disc;
		margin-left: 35px;
	}
	p ol li a {
		@include font-vw($mobile: $text-fs--mobile, $tablet: $text-fs--tablet, $desktop-lg: $text-fs--desktop);
	}
	.popup-content {
		display: none;
		&.active {
			display: block;
		}
		@include respond-to(tablet) {
			padding: 2.2% 0;
			max-width: 945px;
			margin: 0 auto;
		}
		h1,
		p {
			color: $faq-title-color;
		}
		a{
			text-decoration: none;
			color: #8e8b8b;
		}
		h1 {
			@include font-vw(
				$mobile: $legals-header-fs--mobile,
				$tablet: $legals-header-fs--tablet,
				$desktop-lg: $legals-header-fs--desktop
			);
			font-family: $font-bold;
			line-height: 1.1;
			text-align: center;
			padding: 4.8% 3% 2.2%;
			border-bottom: $faq-headline-borderbottom;
			max-width: 100%;
			word-break: break-word;
		}

		ol {
			@include respond-to(mobile-only) {
				margin-top: 20px;
			}
		}
		.extra-country-nav li {
			display: inline-block;
			cursor: pointer;
			font-size: 4.5vw;
			padding: 2px 12px 4px 12px;
			font-family: $font-andes-regular;
			border-right: 2px solid #434343;
			color: #8e8b8b;
			@include respond-to(tablet) {
				font-size: 2vw;
			}
			@include respond-to(desktop) {
				font-size: 1.5vw;
			}
			&:last-child {
				border-right: none;
			}
			&.active.inactive a {
				color: #8e8b8b;
			}
			&:hover,
			&.active a,
			&.active.inactive a:hover {
				color: #434343;
			}
			&.inactive {
			}
			&:hover a {
				text-decoration: underline;
			}
		}
		.hide-btn {
			position: absolute;
			top: 5.8vw;
			right: 8%;
			background-position: 0;
			background-repeat: no-repeat;
			height: 5.4vw;
			width: 5.4vw;
			background-size: 100%;
			text-indent: -99999em;
		}
		@include respond-to(tablet) {
			padding: $faq-headline-padding--tablet;
			.hide-btn {
				top: 0.95%;
				right: 2.9%;
				height: 2.9vw;
				width: 2.9vw;
			}
		}
		@include respond-to(desktop) {
			padding: $faq-headline-padding--desktop;
			.hide-btn {
				top: 1.1em;
				right: 3.2%;
				height: 2em;
				width: 2em;
			}
		}

		sup {
			position: relative;
			top: -0.27em;
		}
		strong {
			font-weight: bold;
		}
		p {
			padding: $faq-text-padding--mobile;
			line-height: 1.5;
			max-width: 100%;
			word-break: break-word;
			@include font-vw($mobile: $text-fs--mobile, $tablet: $text-fs--tablet, $desktop-lg: $text-fs--desktop);
			font-family: $font-andes-regular;
			@include respond-to(tablet) {
				padding: $faq-text-padding--tablet;
			}
			@include respond-to(desktop) {
				font-size: 16px;
				padding: $faq-text-padding--desktop;
			}
			p {
				margin: 0;
				line-height: 1.32;
				padding: 0;
				font-family: $font-andes-regular;
			}
			table td {
				width: 20%;
				border: $table-border;
				padding: 5px;
			}
			@include respond-to(tablet) {
				> div p {
					padding: 0 1.7% 20px 1.7%;
					margin: 0;
				}
			}
		}
	}
}

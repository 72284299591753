body[pagename="Splitter"] {
    main {
        @include respond-to(desktop) {
            margin-bottom: 10vw;
        }
    }
}

.splitter-page {
    margin-bottom: 10%;
    h1 {
        @include font-vw($mobile: 36px, $tablet: 26px, $desktop: 30px);
        margin: 0 auto 2vw;
    }
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .button {
        margin: 2vw 0;
        @include respond-to(tablet) {
            margin: 1vw 0;
        }
    }
}

// newly added giga css
#kgsasgigyaForms {
    label {
        font-family: $font-andes-medium;
        color: $black;
    }
    .gigya-composite-control-captcha-widget iframe {
        margin: 1em 0;
    }
    .gigya-login-form {
        a:not(.button),
        label:not(.gigya-required-display) {
            line-height: 1.4em;
            font-family: $font-andes-medium;
            color: $black;
            @include font-vw($mobile: 24px, $tablet: 18px, $desktop: 20px);
            margin: $homeLeftColtext-margin--mobile;
            @include respond-to(tablet) {
                line-height: 1.4;
                margin: 0;
            }
            @include respond-to(mobile-only) {
                padding: 0;
            }
            &.tnclink {
                margin-bottom: 5vw;
                @include respond-to(tablet) {
                    margin-bottom: 0.5vw;
                }
                a {
                    display: inline;
                    margin: 0;
                    text-decoration: underline;
                    color: #2c2b26;
                    @include font-vw($mobile: 22px, $tablet: 18px, $desktop: 16px);
                }
            }
            &.mndtry-desc {
                margin-bottom: 5vw;
                @include respond-to(tablet) {
                    margin-bottom: 1.5vw;
                }
                @include font-vw(
                    $mobile: $register-text-fs--mobile,
                    $tablet: $register-text-fs--tablet,
                    $desktop: $register-text-fs--desktop
                );
            }
        }
        .gigya-composite-control-form-error{
            display: flex;
            div.gigya-error-msg-active{
                text-align: center;
            }
        }
    }

    .gigya-label-text {
        color: $black;
    }
    .gigya-screen .gigya-label,
    .gigya-screen .gigya-legend {
        font-weight: normal;
    }
    .gigya-input-text:focus,
    .gigya-input-password:focus {
        border: 1px solid #252525;
    }
    .gig-tfa-code-textbox,
    #gigya-dropdown-birthYear,
    .gigya-input-text,
    .gigya-input-password {
        padding-left: 1vw;
        @include font-vw($mobile: 22px, $tablet: 16px, $desktop: 16px);
        color: $black;
        max-width: 100%;
        width: 99%;
        margin: 0 1px;
        background: #f9df74;
        box-shadow: none;
        border: none;
        text-align: left;
        @include border-radius(20px);
    }
    .gigya-composite-control-submit,
    .gigya-screen {
        text-align: center;
        font-family: $font-andes-medium;
        flex: auto;
        .gig-tfa-button-submit,
        .gigya-input-submit,
        input[type="button"] {
            box-sizing: border-box;
            padding: 7px 40px 13px;
            margin: 2vw 0 0;
            width: 100%;
            height: auto;
            max-width: 64%;
            min-width: 20vw;
            display: inline-block;
            text-decoration: none;
            color: $white;
            border: none;
            letter-spacing: 1px;
            background-color: #da2037;
            background-size: 100%;
            box-shadow: 0 0 7px 4px rgb(0 0 0 / 11%);
            -moz-box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.11);
            @include border-radius(30px);
            font-family: $font-pringles-semiSerif;
            @include font-vw($mobile: $button-fs--mobile, $tablet: $button-fs--tablet, $desktop: $button-fs--desktop);
            @include respond-to(mobile-only) {
                max-width: 100%;
                padding: 0.5em 0.4em 0.6em;
            }
        }
    }
    .gigya-screen-content div.gigya-screen.landscape {
        padding: 0;
        right: 0;
    }
    .gigya-screen.portrait,
.gigya-screen.v2.landscape,
.gigya-screen-content div.gigya-screen.landscape {
    width: 100% !important;
}
    .gigya-screen {
        .gigya-layout-row.with-divider{
            .gigya-composite-control-social-login {
                right: 0px;
                width: 100% !important;
                .tabbing-button > div {
                    @include border-radius(20px);
                }
                #kgsasgigyaForms_social_2_uiContainer,
                #kgsasgigyaForms_social_1_uiContainer,
                #kgsasgigyaForms_social_0_uiContainer,
                .gigya-social-login-container,
                .gigya-login-providers{
                    height: 100% !important;
                    width: 100% !important;
                }
            }
            > .gigya-layout-cell.with-social-login {
                box-sizing: border-box;
                background: #f9df74;
                border-radius: 10px;
                margin-bottom: 20px;
                padding: 2vw 1vw;
                @include respond-to(tablet){
                    padding: 1vw;
                }
                > div:first-child {
                    padding: 10px 13px;
                }
                label:first-child {
                    @include font-vw($mobile: 30px, $tablet: 18px, $desktop: 22px);
                    font-family:$font-andes-semiBold;
                }
                label {
                    margin: 0;
                    padding-bottom: 18px;
                    @include font-vw($mobile: 24px, $tablet: 14px, $desktop: 18px);
                }
            }
            label {
                color: $black;
                font-family: $font-andes-medium;
            }
        }
        .gigya-composite-control.gigya-composite-control-submit {
            text-align: center;
        }
    }
    #gigya-forgot-password-success-screen {
        div:first-child {
            text-align: left;
        }
        label,
        a,
        span {
            @include font-vw($mobile: 19px, $tablet: 18px, $desktop: 19px);
            font-family: $font-andes-medium;
            padding: 0 0 1em;
            line-height: 1.4em;
            color: $black;
        }
        div > label:last-of-type,.forgot_label {
            display: inline;
        }
        a {
            display: inline;
            margin-left: 5px;
            color: #d31145;
            text-decoration: underline;
        }
        span {
            display: inline-block;
            margin-left: 6px;
            cursor: default;
        }
    }
    h2 {
        font-family: $font-andes-semiBold;
        @include font-vw($mobile: 26px, $tablet: 24px, $desktop: 24px);
        color: $black;
        line-height: 1.2em;
    }
    .gigya-reset-password-form label {
        @include font-vw($mobile: 27px, $tablet: 18px, $desktop: 19px);
        text-align: center !important;
        line-height: 1.4em;
    }
    .gigya-login-form {
        position: relative;
        .gigya-composite-control-password {
            clear: both;
            padding-top: 5px;
        }
    }
    #gigya-reset-password-success-screen {
        input[type="button"] {
            text-transform: uppercase;
        }
        label.gigya-composite-control-label{
            text-align: center;
            line-height: 1.5em;
            @include font-vw($mobile: 27px, $tablet: 18px, $desktop: 19px);
        }
    }
    .gigya-screen .gigya-layout-row.with-divider > .gigya-layout-cell.with-site-login,
    .gigya-reset-password-form,
    .gigya-otp-update-form,
    #gigya-complete-registration-screen,
    .gigya-otp-send-code-form {
        .gigya-composite-control-textbox .gigya-label,
        .gigya-composite-control-password .gigya-label,
        .country-reg .gigya-label,
        .gigya-input-submit {
            text-transform: uppercase;
        }
        h2.gigya-composite-control-header {
            text-align: center !important;
        }
        .gigya-composite-control-label {
            @include font-vw($mobile: 26px, $tablet: 14px, $desktop: 15px);
            text-align: center;
            font-family: $font-andes-medium;
            line-height: 1.3em;
        }
        .gigya-composite-control-textbox,
        .gigya-composite-control-link {
            margin-bottom: 2vw;
        }
        label .gigya-label-text {
            font-size: 14px;
            font-weight: normal;
            font-family: $font-andes-medium;
            text-align: left;
        }
        .fa-2x {
            display: none;
        }
        a.gigya-composite-control-link {
            @include font-vw($mobile: 16px, $tablet: 10px, $desktop: 12px);
            color: $black;
            font-family: $font-andes-medium;
            padding: 0;
        }
    }
    .gigya-screen .gigya-layout-row.with-divider > .gigya-layout-cell.with-site-login {
        .forgot_pwd{
            width: auto;
            float: right;
        }
        .divider {
            display: flex;
            align-items: center;
            text-align: center;
            margin: 2vw auto;
            max-width: 50%;
            @include font-vw($mobile: 36px, $tablet: 22px, $desktop: 25px);
            @include respond-to(tablet) {
                max-width: 47%;
            }
            &:before,
            &:after {
                content: "";
                flex: 1;
                border-bottom: 1px solid #000;
            }

            &:before {
                margin-right: 0.5em;
            }

            &:after {
                margin-left: 0.5em;
            }
        }
        a.button {
            position: unset;
            padding: 0.9em 0 1em;
            width: 100%;
            box-sizing: border-box;
            @include respond-to(tablet) {
                padding: 7px 40px 9px;
            }
        }
    }
    //   Register page
    .gigya-screen.portrait div.gigya-tfa .gig-tfa-container .gig-tfa-code-remember-checkbox,
    .gigya-screen input[type="checkbox"],
    .gigya-screen input[type="radio"] {
        height: 20px;
        width: 20px;
        border: 1px solid $black;
        border: none;
    }
    .gigya-screen {
        .gigya-composite-control {
            &.gigya-composite-control-multi-choice .gigya-multi-choice-item .gigya-input-radio,
            &.gigya-composite-control-tfa-verify .gig-tfa-code-remember-label input[type=checkbox],
            &.gigya-composite-control-checkbox .gigya-input-checkbox {
                background: #f9df74 url("/Content/img/icons/celebrate2022-icons_sprite.png");
                background-repeat: no-repeat;
                background-position: 0 0;
                cursor: pointer;
                left: 1px;
                top: 0;
                margin-right: 12px;
                height: 31px;
                width: 31px;
                &:checked {
                    background-position: 0 -39px;
                }
            }
            &.gigya-composite-control-multi-choice .gigya-multi-choice-item .gigya-input-radio {
                background-image: url("/Content/img/icons/celebrate2022-icons_sprite.png");
                background-color: transparent;
                background-position: right 2px;
                display: inline-block;
                height: 24px;
                width: 24px;
                margin-right: 10px;
                left: 0;
                &:checked {
                    background-position: right -24px;
                }
            }
            &.gigya-composite-control-checkbox {
                a {
                    text-decoration: underline;
                    color: $black;
                    font-family: $font-andes-medium;
                }
            }
            &.gigya-composite-control-multi-choice .gigya-multi-choice-item label {
                font-size: 14px;
            }
        }
    }
    .gigya-register-form, #gigya-complete-registration-screen {
        .gigya-composite-control-password {
            margin-bottom: 2vw;
            clear: both;
        }
        .gigya-composite-control-multi-choice.country-reg {
            margin: 0px;
            .gigya-error-msg-active {
                display: block;
                float: none;
                clear: both;
                text-align: left;
            }
        }
        .gigya-multi-choice-item,
        .gigya-composite-control-checkbox {
            align-items: center;
            padding-top:0px;
            label {
                font-family: $font-andes-medium !important;
            }
        }
        #register-site-login{
            > div:nth-child(1) > label {
                padding: 2vw 0;
            }
            .tnc-reg,
            .privacy-reg,
            .country-reg {
                border: none;
                > div:first-of-type {
                    flex-wrap: nowrap;
                }
            }

            .tnc-reg,
            .privacy-reg {
                .gigya-terms-error {
                    padding: 0px;
                    border: none;
                }
                .gigya-checkbox-container {
                    align-items: center !important;
                    margin-left: 0;
                }
            }
            .gigya-composite-control-captcha-widget, .gigya-composite-control-captcha-widget + .gigya-error-display-active{
                flex: 0 0 100%;
            }
        }
   
        .gigya-layout-row.with-divider label span a,
        label.added_Country a {
            text-decoration: underline;
            color: $black;
            font-size: 14px;
            font-weight: normal;
            font-family: $font-andes-medium;
            cursor: pointer;
        }
        label.added_Country {
            text-align: left !important;
            margin-bottom: 1vw;
            a {
                display: inline;
                @include font-vw($mobile: 26px, $tablet: 14px, $desktop: 15px);
            }
        }
        label.mndtry-desc:first-of-type {
            margin: 0;
            padding: 0;
        }
    }
    .gigya-error-msg-active {
        @include font-vw($mobile: 16px, $tablet: 14px, $desktop: 14px);
        float: left;
        font-weight: 100;
        padding: 5px 0;
        color: #cf122e;
        font-family: $font-andes-medium;
    }

    .gigya-screen.portrait {
        padding: 0px;
    }
    // reset pass page

    h1 {
        border: none;
        padding-left: 0;
        margin-bottom: 1.5vw;
        text-align: center;
        font-family: $font-andes-medium;
        color: $black;
        line-height: 1.2em;
        @include respond-to(tablet-only) {
            margin: 2.5vw;
        }
        @include font-vw($mobile: 36px, $tablet: 25px, $desktop: 30px);
    }
    .gigya-reset-password-form {
        .email-wrapper{
            margin-bottom:0px;
        }
        .gigya-composite-control-label {
            @include font-vw($mobile: 16px, $tablet: 16px, $desktop: 16px);
            font-family: $font-andes-medium;
            text-align: left;
        }
        .gigya-composite-control-password {
            margin-bottom: 2vw;
        }
        .gigya-composite-control-textbox {
            span.gigya-error-msg-active {
                text-align: center;
                float: unset;
            }
        }
    }

    //profile completion

    .gigya-profile-form {
        .gigya-composite-control-checkbox,
        .gigya-composite-control-textbox,
        .gigya-composite-control-dropdown {
            margin-bottom: 2vw;
        }

        .gigya-checkbox-subscribe {
            padding-top: 10px;
        }

        .gigya-error-msg-active {
            float: none;
            clear: both;
        }
        .gigya-composite-control-checkbox {
            label[for="gigya-checkbox-subscribe"] {
                padding-top: 10px;
            }
        }
    }
    #gigya-account-lockout-screen {
        .gigya-layout-row{
            text-align: left;
            padding-bottom: 2px;
        }
        label,
        span,
        a {
            @include font-vw($mobile: 18px, $tablet: 18px, $desktop: 18px);
            font-family: $font-andes-medium;
            line-height: 1.5em;
            padding-bottom: 5px;
            display: inline;
            color: $black;
        }
        a {
            color: #d8243c;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
            span {
                display: inline-block;
                cursor: default;
            }
        }
    }
    #gigya-otp-update-form {
        input {
            @include placeholder {
                color: #726841;
            }
        }
        div.gigya-error-msg-active{
            float: none;
        }
        label,
        a {
            @include font-vw($mobile: 16px, $tablet: 18px, $desktop: 18px);
            font-family: $font-andes-medium;
            line-height: 1.5em;
            // text-align: left;
            display: inline-block;
        }
        a {
            color: #d8243c;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        i.gigya-pencil {
            display: none;
        }
        div.gigya-layout-row:nth-child(4n) {
            label {
                font-family: $font-andes-medium;
                margin-bottom: 10px;
                top: 0;
                position: unset;
                font-size: 16px;
                width: 100%;
                text-align: center;
            }
            a {
                font-size: 16px;
            }
        }
    }
    #gigya-complete-registration-screen{
        .gigya-error-msg-active{
            text-align: left;
        }
        .gigya-layout-row:nth-child(3n){
            box-sizing: border-box;
            background: #f9df74;
            border-radius: 10px;
            margin-bottom: 20px;
            padding: 5vw 1vw;
            @include respond-to(tablet){
                padding: 1vw;
            }
            > div:first-child {
                padding: 10px 13px;
            }
            label:first-child {
                @include font-vw($mobile: 30px, $tablet: 18px, $desktop: 22px);
                font-family:$font-andes-semiBold;
            }
            label {
                margin: 0;
                padding-bottom: 18px;
                @include font-vw($mobile: 24px, $tablet: 14px, $desktop: 18px);
            }
        }
        .gigya-composite-control-social-login {
            right: 0px;
            width: 100% !important;
            .tabbing-button > div {
                @include border-radius(20px);
            }
        }
        #kgsasgigyaForms_social_2_uiContainer,
        #kgsasgigyaForms_social_1_uiContainer,
        #kgsasgigyaForms_social_0_uiContainer,
        .gigya-social-login-container,
        .gigya-login-providers{
            height: 100% !important;
            width: 100% !important;
        }

        .gigya-layout-row:nth-child(4n){
            .tnc-reg,
            .privacy-reg,
            .country-reg {
              border: none;
            //   > div:first-of-type {
            //     flex-wrap: nowrap;
            //    }
            }

            .tnc-reg,
            .privacy-reg {
                margin-bottom:0px;
                .gigya-terms-error {
                padding: 0px;
                border: none;
               }
               .gigya-checkbox-container {
                align-items: center !important;
                margin-left: 0;
               }
            }
        }     
        .gigya-composite-control-captcha-widget, .gigya-composite-control-captcha-widget + .gigya-error-display-active{
            flex: 0 0 100%;
        }
        .gigya-layout-row{
            label.mndtry-desc:last-of-type {
                margin: 0;
            }
        }
    }
    #gigya-tfa-verification-screen{

        .gig-tfa-email-code-header-text,
        .gig-tfa-email-text{
            margin: 0;
            display: inline-block;
            width: 100%;
            text-align: center;
        }
        .gigya-composite-control.gigya-composite-control-tfa-verify .gig-tfa-code-remember-label input[type=checkbox]{
            display: inline-block;
        }
        .gig-tfa-button-container{
            text-align: center;
        }
        .gigya-composite-control-label {
            text-align: center;
            margin-top:2vw;
          a,
          &.expire-txt {
            display: inline-block;
            @include font-vw($mobile: 16px, $tablet: 18px, $desktop: 18px);
            font-family: $font-andes-medium;
            line-height: 1.5em;
             }
           a {
            color: #d8243c;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
           }
        }
        .gig-tfa-button-submit{
            text-transform: uppercase;
        }
    }
    #gigya-link-accounts-form{
        .gigya-composite-control.gigya-composite-control-password{
            margin-top: 20px;
            .fa-2x{
                display: none;
            }
        }
        input.gigya-input-submit {
            text-transform: uppercase;
        } 
        .gigya-layout-cell .gigya-layout-cell {
            max-width: 100%;
            margin-top: -30px;
            width: 100%;
        }
        div.gigya-error-msg-active{
            position: relative;
            width: 100%;
            top: 40px;
        }
    }
}
.it_it,
.nl_nl,
.fr_fr{
    #kgsasgigyaForms #gigya-reset-password-success-screen input[type=button]{
        max-width: 80%;
    }
}
.fi_fi,
.sv_se,
.de_de,
.de_at{
    #kgsasgigyaForms #gigya-reset-password-success-screen input[type=button]{
        max-width: 70%;
    }
}

.fi_fi,
.no_no,
.sv_se{
    #kgsasgigyaForms {
        .gigya-screen .gigya-layout-row.with-divider > .gigya-layout-cell.with-social-login, #gigya-complete-registration-screen .gigya-layout-row:nth-child(3n){
            background: none;
            margin: 0;
            padding: 0;
        }
    
         #gigya-reset-password-success-screen label.gigya-composite-control-label{
           padding-bottom: 0px;
        }
        .gigya-layout-row.with-divider{
            margin-top: -3vw;
        }
    }
}
.no_no,
.es_es{
    #kgsasgigyaForms {
      .gigya-reset-password-form .gigya-input-submit, #gigya-reset-password-success-screen input[type=button]{
        max-width: 85%;
      }
      .gigya-login-form{
        a.button span{
            padding: 7px 0 !important;
        }
        .gigya-input-submit{
            padding: 13px 40px;
            @include font-vw($mobile: 26px, $tablet: 20px, $desktop-lg: 27px);
            font-family: Impact, HelveticaNeue-CondensedBlack, Charcoal, sans-serif-condensed, sans-serif;
        }
      }
      #gigya-reset-password-success-screen label.gigya-composite-control-label{
        padding-bottom: 0px;
     }
    }
}
.pt_pt{
    #kgsasgigyaForms {
         .gigya-screen .gigya-composite-control.gigya-composite-control-checkbox .gigya-label{
            margin-left: -2px;
        }
        #gigya-reset-password-success-screen input[type=button]{
            max-width: 74%;
        }
        #gigya-reset-password-success-screen label.gigya-composite-control-label{
            padding-bottom: 0px;
         }  
    }
}
.nl_be,
.fr_be{
    #gigya-reset-password-success-screen label.gigya-composite-control-label{
        padding-bottom: 0px;
     }  
     #register-site-login .gigya-composite-control.gigya-composite-control-multi-choice.country-reg{
        display: block;
    } 
     #gigya-reset-password-success-screen a.button{
        max-width: 70%;
       span{
        @include font-vw($mobile: $button-fs--mobile, $tablet: $button-fs--tablet, $desktop: $button-fs--desktop);
             @include respond-to(mobile-only) {
            max-width: 100%;
            padding: 0.5em 0.4em 0.6em;
             }
        }
    }
}
.el_gr{
    #kgsasgigyaForms{
        h2,.gigya-screen .gigya-layout-row.with-divider > .gigya-layout-cell.with-social-login label:first-child{
            font-weight: bold;
        }
        #gigya-login-form, #gigya-reset-password-form, #gigya-register-form,#gigya-otp-update-form{
            .gigya-input-submit{
                @include font-vw($mobile: 26px, $tablet: 20px, $desktop-lg: 27px);
                padding: 13px 40px 13px;
            }
            span.gigya-checkbox-text,.gigya-label-text{
                font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
            }
        }
        #gigya-register-form{
            label .gigya-label-text { 
                @include font-vw($mobile: 26px, $tablet: 9px, $desktop-lg: 13px);
            }
        } 
        #gigya-otp-send-code-form{
            .gigya-input-submit{
                 @include font-vw($mobile: 26px, $tablet: 20px, $desktop-lg: 25px);
                  padding: 13px 20px 13px; 
            }
        }     
        #gigya-login-form a.button:not(.not-button){
            @include respond-to(desktop){
                max-width: 70%;
            }
                padding: 14px 40px 14px;
                span{
                    font-weight: normal;
                }
        }
        #gigya-reset-password-success-screen{
            input[type=button]{
                @include font-vw($mobile: 25px, $tablet: 20px, $desktop-lg: 25px);
                max-width: 100%;
                padding: 13px 26px 13px;
            }
        }
    } 
}
.de_at,
.de_de{
    #kgsasgigyaForms{
        #gigya-register-form, #gigya-profile-form{
            .gigya-layout-row label.gigya-composite-control.name_note{
                padding: 0 0 2vw 0;
                font-size: 12px;
                margin-top: -21px;
                @include respond-to(mobile-only) {
                    margin-top: -6px;
                }
            }
        }
    }
}

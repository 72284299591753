footer {
    text-align: center;
    z-index: 22;
    position: relative;
    padding: 2.8vw 0 2vw;
    background-color: $footer-bgcolor-desktop;
    $footerSelector: &;
    @include respond-to(tablet) {
        padding-left: 10%;
        padding-right: 10%;
    }
    *,
    p,
    p a {
        @include font-vw(
            $mobile: $footer-link-fs--mobile,
            $tablet: $footer-link-fs--tablet,
            $desktop: $footer-link-fs--desktop
        );
        font-family: $font-andes-medium;
        color: $footer-text-color-desktop;
        text-decoration: none;
        font-weight: 100;
    }
    .social-sharing {
        display: flex;
        flex-direction: row;
        justify-content: center;
        @include respond-to(mobile-only) {
            border-top: $footer-greyborder;
            border-bottom: $footer-greyborder;
            width: 100%;
        }
        @include respond-to(tablet) {
            margin: 0 auto 4%;
        }
        > div {
            @include respond-to(mobile-only) {
                width: 50%;
                &:first-child {
                    border-right: $footer-greyborder;
                }
            }
        }
        button {
            background: none;
            width: 60px;
            box-shadow: none;
            img {
                width: 11vw;
                vertical-align: middle;
            }
            @include respond-to(tablet) {
                margin: 0 15px;
                transition: all 0.1s ease-in-out;
                padding: 0;
                &:hover {
                    transform: scale(1.2);
                }
                img {
                    width: 100%;
                }
            }
        }
    }

    .footer-menu {
        margin: $footer-text-margin--mobile;
        @include respond-to(tablet) {
            margin: $footer-text-margin--tablet;
        }
        li,
        & + div {
            display: block;
            vertical-align: middle;
            padding: $footer-text-padding--mobile;
            @include respond-to(mobile-only) {
                border-bottom: $footer-greyborder;
                width: 100%;
            }
            @include respond-to(tablet) {
                display: inline-block;
                padding: $footer-text-padding--tablet;
            }
            a:hover {
                text-decoration: underline;
            }
        }
        li {
            @include respond-to(tablet) {
                border-right: $footer-textborder;
                &:last-child {
                    border-right: none;
                }
            }
        }
        & + div {
            margin: $footer-text-margin--mobile;
            @include respond-to(tablet) {
                margin: $footer-text-margin--tablet;
            }
        }
    }
    .copyrights p {
        margin-bottom: 0;
    }
    .short-terms {
        display: block;
        vertical-align: middle;
        padding: $footer-text-padding--mobile;
        margin: $footer-text-margin--mobile;
        @include respond-to(mobile-only) {
            width: 100%;
        }
        @include respond-to(tablet) {
            padding: $footer-text-padding--tablet;
            margin: $footer-text-margin--tablet;
        }
        a:hover {
            text-decoration: underline;
        }
    }
    a{
        cursor:pointer;
    }
    .link__button{
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;
        width:auto;
        box-shadow: none;
         text-transform: none;
        min-width: 0px;
        &:hover{
            text-decoration: underline;
        }
    }
}

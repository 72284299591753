body[pagename="Login"] {
    .container {
        @include respond-to(desktop-lg) {
            padding-left: 14vw;
            padding-right: 14vw;
        }
    }
}

.login-page {
    h1,
    h3 {
        @include respond-to(tablet-only) {
            margin: 2.5vw;
        }
        margin-bottom: 1.5vw;
        text-align: center;
        @include font-vw($mobile: 36px, $tablet: 25px, $desktop: 30px);
    }
    .tnclink {
        margin-bottom: 2.5vw;
        font-size: 16px;
    }
    .mndtry-desc {
        font-size: 15px;
        margin: 30px 0;
    }
    button,
    a.button,
    label.button,
    span.button {
        &:not(.not-button) {
            width: 100%;
            @include respond-to(tablet) {
                max-width: $grm-button-maxwidth--tablet;
            }
            @include respond-to(desktop-lg) {
                max-width: $grm-button-maxwidth--desktop;
            }
        }
    }
    .form-control {
        &.captcha-box {
            clear: both;
            padding-top: 10px;
            @include respond-to(mobile-only) {
                margin-bottom: 7%;
            }
            @media only screen and (max-width: 413px) {
                #rc-imageselect,
                .g-recaptcha {
                    transform: scale(0.9);
                    -webkit-transform: scale(0.9);
                    transform-origin: 0 0;
                    -webkit-transform-origin: 0 0;
                    width: 285px;
                }
            }
            @media only screen and (max-width: 374px) {
                #rc-imageselect,
                .g-recaptcha {
                    transform: scale(0.67);
                    -webkit-transform: scale(0.67);
                    transform: scale(0.67);
                    -webkit-transform: scale(0.67);
                    transform-origin: 0 0;
                    -webkit-transform-origin: 0 0;
                    width: 245px;
                }
            }
            @include respond-to(tablet-only) {
                #rc-imageselect,
                .g-recaptcha {
                    transform: scale(0.67);
                    -webkit-transform: scale(0.67);
                    transform: scale(0.67);
                    -webkit-transform: scale(0.67);
                    transform-origin: 0 0;
                    -webkit-transform-origin: 0 0;
                    width: 194px;
                }
            }
            .g-recaptcha {
                & > div {
                    @include respond-to(desktop) {
                        margin-bottom: 50px;
                    }
                }
            }
        }
    }
    .nopromo-section .g-recaptcha > div {
        margin-left: auto;
        margin-right: auto;
    }
    .divider {
        display: flex;
        align-items: center;
        text-align: center;
        margin: 2vw auto;
        max-width: 50%;
        @include font-vw($mobile: 36px, $tablet: 22px, $desktop: 25px);
        @include respond-to(tablet) {
            max-width: 47%;
        }
        &:before,
        &:after {
            content: "";
            flex: 1;
            border-bottom: 1px solid #000;
        }

        &:before {
            margin-right: 0.5em;
        }

        &:after {
            margin-left: 0.5em;
        }
    }
    .registerBtn {
        @include respond-to(tablet) {
            max-width: $grm-button-maxwidth--desktop;
            margin: 0 auto;
        }
    }
    #reg-container .KSTL-Registration .pod .ng-form > div > div {
        margin-bottom: 5vw;
        @include respond-to(tablet) {
            margin-bottom: 1vw;
        }
        &#ForgotPasswordFormHeader {
            margin-bottom: 0;
            padding-bottom: 0;
        }
        &.KSTL-Registration-button {
            margin: 0;
            padding: 0;
        }
    }

    .KSTL-Registration .pod .success-message p {
        color: #b94a48;
    }
    #reg-container .KSTL-Registration .pod .error-field {
        height: auto;
        padding: 1% 0;
    }
    .twocols {
        padding: 0;
        & > div {
            &:first-child {
                @include respond-to(mobile-only) {
                    padding: 0;
                    margin-bottom: 0;
                }
                > div {
                    @include respond-to(mobile-only) {
                        border-bottom: 0px;
                        padding: 0 0;
                    }
                    height: auto !important;
                    @include respond-to(tablet) {
                        margin-right: 0 !important;
                    }
                    .KSTL-Registration-Login.pod {
                        @include respond-to(tablet) {
                            padding: 0;
                            margin-right: $leftCol-marginright--tablet;
                        }
                        @include respond-to(desktop) {
                            margin-right: $leftCol-marginright--desktop;
                        }
                    }
                }
            }
            & > div.content > p {
                margin: $login-text-margin--mobile;
                @include respond-to(tablet) {
                    margin: $login-text-margin--tablet;
                }
                @include respond-to(desktop) {
                    margin: $login-text-margin--desktop;
                }
            }
            &:last-child > div {
                padding: 6vw 0 8vw 0;
                @include respond-to(tablet) {
                    padding-top: $login-rightCol-paddingtop--tablet;
                    padding-bottom: $login-rightCol-paddingbottom--tablet;
                    padding-left: 0;
                    padding-right: 0;
                }
                @include respond-to(desktop) {
                    padding-top: $login-rightCol-paddingtop--desktop;
                    padding-bottom: $login-rightCol-paddingbottom--desktop;
                }
                .button {
                    max-width: 84%;
                    @include respond-to(mobile-only) {
                        margin: 0 auto;
                    }
                    @include respond-to(tablet) {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    #KSTL-Registration-wrapper-LoginTitle {
        display: $displayDefaultGRMLoginTitle;
    }
    #KSTL-Registration-wrapper-ForgotPwEmailCnfrmHeaderCopy {
        @include respond-to(tablet-only) {
            padding: 0;
        }
    }
    #reg-container {
        .KSTL-Registration-SocialMetaData {
            .regbutton {
                > div {
                    @include respond-to(tablet) {
                        position: relative;
                    }
                }
                &.KSTL-Registration-facebook {
                    margin: 0 auto;
                }
            }
        }
        .KSTL-Registration {
            .KSTL-Registration-Login {
                @include respond-to(mobile-only) {
                    margin: 0 auto;
                    > div {
                        margin: 0 auto;
                    }
                }
            }
        }
        #KSTL-Registration-wrapper-ForgotPasswordFormHeader {
            margin: 0 1vw 0;
            padding: 0;
        }
        #KSTL-Registration-wrapper-ForgotPasswordFormHeaderCopy {
            p {
                margin: 0;
                text-align: center;
            }
        }
        #KSTL-Registration-wrapper-ForgotPasswordLink {
            float: right;
            z-index: 1;
            display: inline-block;
            margin-right: 15px;
            position: relative;
            > div {
                margin: 0;
                padding: 0;
            }
            a {
                @include font-vw(
                    $mobile: $grm-login-forgotlink-fs--mobile,
                    $tablet: $grm-login-forgotlink-fs--tablet,
                    $desktop: $grm-login-forgotlink-fs--desktop
                );
                color: $grm-forgotpass-color;
                text-decoration: none;
                font-family: $font-andes-regular;
                padding: 0;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .KSTL-Registration-ForgotPassword {
            // margin:0 10vw;
            h2,
            p {
                padding: 0;
                text-align: center;
                @include respond-to(tablet) {
                    text-align: left;
                }
            }
        }
        .KSTL-Registration-ForgotPasswordEmailConfirm {
            text-align: left;
        }
        .recaptcha {
            margin: 0 0 10px 0;
            @include respond-to(tablet-only) {
                width: 245px !important;
                > div > div {
                    text-align: left;
                    > iframe {
                        transform: scale(0.8);
                        -webkit-transform: scale(0.8);
                        transform-origin: 0 0;
                        -webkit-transform-origin: 0 0;
                    }
                }
            }
            @media only screen and (max-width: 413px) {
                transform: scale(0.9);
                -webkit-transform: scale(0.9);
                transform-origin: 0 0;
                -webkit-transform-origin: 0 0;
                width: 335px;
            }
            @media only screen and (max-width: 374px) {
                transform: scale(0.77);
                -webkit-transform: scale(0.77);
                transform-origin: 0 0;
                -webkit-transform-origin: 0 0;
                width: 285px;
            }
        }
        [id*="KSTL-Registration-wrapper"] {
            //   @include respond-to(tablet) {
            //         margin-right:3vw;
            //   }
            p,
            p a {
                @include font-vw($mobile: 16px, $tablet: 16px, $desktop: 16px);
            }
        }
        #KSTL-Registration-wrapper-AccountLockedErrorHeader {
            margin: 0;
            padding: 0;
        }
        .KSTL-Registration-Login.pod.ng-isolate-scope,
        .KSTL-Registration-ForgotPassword,
        .KSTL-Registration-ForgotPasswordEmailConfirm,
        .KSTL-Registration-AccountLocked {
            h2 {
                font-family: "Andes_Semibold", Arial, "Helvetica Neue", Helvetica, sans-serif;
                @include font-vw($mobile: 26px, $tablet: 24px, $desktop: 24px);
                text-align: center;
                margin-bottom: 2.5vw;
                @include respond-to(tablet) {
                    margin-bottom: 1.5vw;
                }
            }
            [id*="KSTL-Registration-wrapper"] {
                p,
                p a {
                    @include font-vw($mobile: 27px, $tablet: 18px, $desktop: 20px);
                }
                .success-message,
                .error-field {
                    p,
                    p a {
                        text-align: center;
                        @include font-vw($mobile: 16px, $tablet: 16px, $desktop: 16px);
                    }
                }
                .error-field {
                    p {
                        text-align: left;
                    }
                }
            }
            min-height: 150px;
            @include respond-to(tablet) {
                margin: 1.5vw 0 2vw;
                padding: 0;
                //margin:$homeLeftCol-margin--tablet;
            }
            @include respond-to(desktop) {
                //margin:$homeLeftCol-margin--desktop;
            }
            #KSTL-Registration-wrapper-YourEmailAddress {
                margin-bottom: 0;
            }
        }
    }

    h2 {
        font-weight: bold;
        @include font-vw($mobile: 30px, $tablet: 20px, $desktop: 24px);
        @include respond-to(tablet) {
            margin-bottom: 0.5em;
        }
    }
    p,
    a:not(.button) {
        line-height: 1.4em;
        font-family: $font-andes-medium;
        // font-weight: bold;
        @include font-vw($mobile: 24px, $tablet: 18px, $desktop: 20px);
        margin: $homeLeftColtext-margin--mobile;
        @include respond-to(tablet) {
            line-height: 1.4;
            margin: 0;
            padding: 0 0 1em;
        }
        &.tnclink {
            margin-bottom: 5vw;
            @include respond-to(tablet) {
                margin-bottom: 0.5vw;
            }
            a {
                margin: 0;
                text-decoration: underline;
                color: #2c2b26;
                @include font-vw($mobile: 22px, $tablet: 18px, $desktop: 16px);
            }
        }
        &.mndtry-desc {
            margin-bottom: 5vw;
            @include respond-to(tablet) {
                margin-bottom: 2.5vw;
            }
            @include font-vw(
                $mobile: $register-text-fs--mobile,
                $tablet: $register-text-fs--tablet,
                $desktop: $register-text-fs--desktop
            );
        }
        &.tnclink + h2 {
            margin-bottom: 20px;
            font-family: "Andes_Semibold", Arial, "Helvetica Neue", Helvetica, sans-serif;
            @include respond-to(tablet) {
                margin-bottom: 1.5vw;
            }
        }
    }

    .hiding {
        width: 0;
        height: 0;
        opacity: 0;
    }
    .hide {
        display: none;
    }
}

.nopromo-section {
    margin: 0 auto;
    p {
        @include font-vw($mobile: 25px, $tablet: 30px, $desktop: 40px);
        font-family: $font-andes-medium;
        font-weight: bold;
        margin: $homeLeftColheadertext-margin--mobile;
        @include respond-to(tablet) {
            margin: 0 0 5.4vw;
        }
        @include respond-to(desktop) {
            margin: 0 0 4vw;
        }
    }
    .outofhour {
        p {
            @include respond-to(desktop) {
                margin: 0 0 1vw;
            }
        }
    }
    .buttons {
        width: 80%;
        margin-top: 4vw;
        @include respond-to(tablet) {
            padding-left: 6vw;
            padding-right: 6vw;
            width: auto;
        }
        @include respond-to(desktop) {
            padding-left: 4vw;
            padding-right: 4vw;
        }
    }
    h1 {
        font-family: $font-andes-medium;
        line-height: 1.4;
        font-weight: bold;
        @include font-vw($mobile: 30px, $tablet: 35px, $desktop: 35px);
        margin: 2.5vw auto 4vw;
        @include respond-to(tablet) {
            margin: 0 auto 2vw;
        }
        p {
            @include font-vw($mobile: 30px, $tablet: 35px, $desktop: 35px);
            font-weight: bold;
            margin: 0 auto 4vw;
            @include respond-to(tablet) {
                margin: 0 auto 0.8vw;
            }
        }
    }
    h2 {
        font-family: $font-medium;
        line-height: 1.4;
        font-weight: bold;
        margin-bottom: 1vw;
        @include font-vw($mobile: 24px, $tablet: 24px, $desktop: 24px);
        p {
            @include font-vw($mobile: 24px, $tablet: 24px, $desktop: 24px);
            font-weight: bold;
        }
    }
}
.bottom-prize-wrapper {
    text-align: center;
    margin: 0 auto 15vw;
    max-width: 890px;
    width: 90%;
    h3 {
        color: #e5e5e5;
        @include font-vw($mobile: 50px, $tablet: 50px, $desktop: 50px);
    }
    p {
        color: #e5e5e5;
        font-family: $font-andes-medium;
        @include font-vw($mobile: 25px, $tablet: 25px, $desktop: 25px);
    }
    a {
        display: inline-block;
    }
    img {
        width: 100%;
        height: auto;
        @include respond-to(tablet) {
            width: 40vw;
        }
    }
    @include respond-to(tablet) {
        width: 75%;
        margin: 0 auto 5vw;
    }
}

.home {
    .container {
        @include respond-to(tablet) {
            width: 85%;
        }
        @include respond-to(desktop) {
            max-width: 1400px;
        }
    }
    .twocols {
        background: #031648 url("/Content/img/home/home-upc-mob.png") no-repeat bottom center/100% auto;
        @include respond-to(tablet) {
            background: #031648 url("/Content/img/home/home-upc-bg.jpg") no-repeat bottom center/cover;
        }
        > div {
            @include respond-to(mobile-only) {
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
            &:first-child {
                //border-bottom: 1px solid #ffffff;
                .js-col-with-form {
                    padding-bottom: 10%;
                }
                hr {
                    width: 94%;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 0;
                }
                @include respond-to(tablet) {
                    padding-bottom: 2%;
                    border: none;
                    border-right: 1px solid #ffffff;
                    .js-col-with-form {
                        width: 70%;
                        margin: 0 auto;
                        max-width: 350px;
                    }
                    hr {
                        display: none;
                    }
                }
            }
            .js-col-without-form {
                @include respond-to(mobile-only) {
                    padding-top: 4%;
                    p {
                        margin-left: 15%;
                        margin-right: 15%;
                    }
                    //box-shadow: 0 0 2px 1px rgba(0, 0, 0, .11);
                    //border: 1px solid #e5e5e5;
                }
            }
            .content {
                @include respond-to(tablet) {
                    @include border-radius(10px);
                }
            }
            h2 {
                font-family: $font-heading-italic;
                //font-style: italic;
                font-weight: bold;
                @include font-vw($mobile: 50px, $tablet: 25px, $desktop-lg: 25px);
                padding-left: 0;
                padding-right: 0;
            }
            h2,
            h3 {
                @include font-vw($mobile: 46px, $tablet: 25px, $desktop-lg: 25px);
            }
            button {
                @include border-radius(6px);
                @include font-vw($desktop-lg: 30px);
                @include respond-to(mobile-only) {
                    margin-top: 6%;
                }
                @include respond-to(desktop) {
                    margin-top: 20px;
                }
            }
            & + div {
                .content {
                    picture {
                        display: block;
                    }
                    img {
                        margin: 5% 0 8%;
                        width: 45%;
                        @include respond-to(tablet) {
                            margin: $home-imgcontent-margin--tablet;
                            max-width: 60%;
                            width: auto;
                        }
                        @include respond-to(desktop) {
                            margin: $home-imgcontent-margin--desktop;
                            max-width: $home-imgcontent-width--desktop;
                        }
                    }
                    .pack-code img {
                        @include respond-to(desktop) {
                            width: 60%;
                            max-width: 290px;
                        }
                    }
                }
                button {
                    @include respond-to(tablet) {
                        margin: 6% 0 0;
                    }
                }
            }
        }
        .below-can {
            @include font-vw($mobile: 30px, $tablet: 25px, $desktop: 25px);
        }
    }
    .personalize-container {
        @include respond-to(desktop) {
            padding-top: 20px;
            padding-bottom: 10px;
            picture {
                //margin-left: -60px;
            }
            .content {
                //margin-left:60px;
            }
        }
    }
    .rule-container {
        position: relative;
        width: 90%;
        margin: 40px auto 10%;
        padding-bottom: 20%;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        @include respond-to(tablet) {
            padding-bottom: 90px;
            margin-bottom: 90px;
        }
        @include respond-to(desktop) {
            max-width: 954px;
        }
        .heading-container {
            position: relative;
            picture {
                position: relative;
                left: 2%;
                width: 18%;
                @include respond-to(tablet) {
                    position: absolute;
                }
                @include respond-to(desktop) {
                    left: 50px;
                    width: 136px;
                }
            }
            p {
                font-family: $font-andes-regular;
                @include font-vw($mobile: 18px, $tablet: 20px, $desktop: 20px);
                font-weight: 400;
                @include respond-to(mobile-only) {
                    width: auto;
                }
            }
        }
        h3 {
            color: #ffe006;
            padding-bottom: 3.5%;
            @include respond-to(tablet) {
                margin-top: 40px;
                margin-bottom: 20px;
                display: inline-block;
                width: 55%;
                padding-bottom: 0;
            }
            @include respond-to(tablet) {
                margin-bottom: 10px;
            }
            //margin:40px auto 20px;
        }
        ul {
            width: 90%;
            margin: 0 auto;
            list-style: disc;
            padding: 25px 0 0 10px;
            @include respond-to(tablet) {
                width: 90%;
                padding-left: 30px;
                padding-top: 30px;
            }
            li {
                color: $yellow;
                text-align: left;
                font-family: $font-andes-regular;
                line-height: 1.3em;
                div {
                    color: $site-copy-color;
                    display: inline;
                }
                @include font-vw($mobile: 18px, $tablet: 20px, $desktop: 20px);
                padding: 0 0 7px;
                @include respond-to(tablet) {
                    padding: 0 10px 10px;
                }
                &:last-child {
                    padding-right: 45%;
                    @include respond-to(tablet) {
                        padding-right: 160px;
                    }
                }
            }
        }
        .coco-wrapper {
            right: 5%;
            position: absolute;
            bottom: 1px;
            width: 45%;
            @include respond-to(tablet) {
                width: 23%;
            }
            @include respond-to(desktop) {
                right: 50px;
                width: 183px;
            }
        }
    }
}

.KSTL-Registration-Profile #KSTL-Registration-wrapper-ContinueButton1 {
    clear: both;
}
.KSTL-Registration .ng-form .recaptcha {
    margin-bottom: 5vw;
    @include respond-to(tablet) {
        margin-bottom: 3vw;
    }
}

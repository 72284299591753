.movie-page {
  padding-bottom: 34vw;
  .disclaimer {
    @include font-vw($mobile: 16px, $tablet: 16px, $desktop-lg: 17px);
  }
  @include respond-to(tablet) {
    padding-bottom: 10vw;
  }
  .mov-btn > div {
    @include respond-to(mobile-only) {
      padding: 0.45em 0.2em 0.55em;
    }
  }
  .mov-btn {
    @include respond-to(mobile-only) {
      width: 60%;
    }
  }
  .movie-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -5px;
    margin-bottom: 5%;
    .item {
      margin: 20px 5px 0;
      width: 45%;
      @include respond-to(tablet) {
        width: 30%;
      }
      p {
        @include font-vw($mobile: 20px, $tablet: 20px, $desktop-lg: 17px);
        padding: 0;
        margin: 5px 0 0;
        text-align: left;
        &.sub-heading {
          @include font-vw($mobile: 16px, $tablet: 16px, $desktop-lg: 15px);
        }
      }
      .movie-item {
        position: relative;
        picture img {
          width: 100%;
          vertical-align: top;
        }
        &:after {
          @include respond-to(desktop) {
            content: "\A";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.5);
            opacity: 0;
            transition: all 0.5s;
            -webkit-transition: all 0.5s;
          }
        }
        &:hover:after {
          cursor: pointer;
          opacity: 1;
        }
        .fade-bg {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.4);
          opacity: 1;
        }
        .tick {
          display: none;
          z-index: 2;
          width: 20%;
          height: auto;
          position: absolute;
          top: 8px;
          left: 8px;
          transition: all 0.5s;
          -webkit-transition: all 0.5s;
        }
        .selected.tick {
          display: block;
        }
      }
    }
  }
}

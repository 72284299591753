.form-valid-field {
    color: $form-valid-field !important;
}
ul,
h1,
h2,
h3 {
    color: $content-header-color;
    margin: 0;
    padding: 0;
}
p,
p a {
    color: $text-color;
}
input,
textarea,
select {
    color: $input-color;
    @include box-shadow-none();
    border: none;
}
input {
    @include placeholder {
        color: $placeholder-copy-color;
    }
    &.ng-invalid {
        @include placeholder {
            color: $placeholder-error-color;
        }
    }
}
.ng-error {
    @include placeholder {
        color: $placeholder-error-color !important;
    }
}
input.ng-pristine.ng-invalid {
    @include placeholder {
        color: $grm-input-color;
    }
}
.error {
    input {
        color: $inputError-color !important;
        @include placeholder {
            color: $placeholderError-color !important;
        }
    }
}
.error-message,
.error-field {
    p {
        &,
        span,
        span > p {
            color: $inputError-color;
        }
    }
}
button,
a.button,
label.button,
span.button {
    @include button($button-bg, $button-color, $button-hover-bg);
}
#reg-container {
    .ai-circled {
        margin: 20px auto 0;
    }
    .KSTL-Registration {
        .pod {
            padding: 0;
            border: none;
            min-width: 0;
            overflow: hidden;
            margin: 0;
            //margin-right: 2vw;
            .ns-popover-list-theme {
                left: 0 !important;
                top: 100% !important;
                li {
                    color: $black-input;
                }
                @include respond-to(tablet) {
                    @include calc(top, "100% - 15px");
                }
            }
            div[label] {
                text-align: left;
            }
            input,
            select {
                color: $input-color;
                max-width: 100%;
                width: 99%;
                margin: 0 1px;
                background: #f9df74;
                @include box-shadow-none();
                border: none;
                text-align: left;
                padding: $grm-input-padding--mobile;
                @include respond-to(tablet) {
                    padding: $grm-input-padding--tablet;
                }
                &[type="text"],
                &[type="password"],
                &[type="email"],
                &[type="tel"] {
                    line-height: 1.15;
                    height: auto;
                    @include border-radius(20px);
                }
                &.ng-valid,
                &.ng-invalid {
                    &.ng-dirty {
                        @include box-shadow-none();
                    }
                }
                &.ng-valid {
                    border-color: $grm-input-border-color;
                    &.ng-dirty {
                        @include box-shadow-none();
                    }
                }
                @include placeholder {
                    line-height: $grm-placeholder-lineheight-webkit--m !important;
                }
            }
            button {
                margin: 7% 0 0;
            }
            select {
                max-width: 100%;
                line-height: 1.15em !important;
                -webkit-line-height: 1.15em !important;
                -webkit-appearance: none;
                option {
                    color: $black-input;
                    background: #fff;
                }
            }
            label,
            label > a {
                position: relative;
                width: auto;
                font-weight: normal;
                display: inline-block;
                text-transform: uppercase;
                font-family: $font-andes-regular;
                text-align: $grm-login-label-talign--mobile;
                margin: $grm-login-label-margin--mobile;
                padding: $grm-login-label-padding--mobile;
                line-height: 1.2;
                @include respond-to(tablet) {
                    padding: $grm-login-label-padding--tablet;
                    margin: $grm-login-label-margin--tablet;
                }
                @include respond-to(desktop) {
                    padding: $grm-login-label-padding--desktop;
                    margin: $grm-login-label-margin--desktop;
                }
                &.required {
                    &:after {
                        content: "*";
                        display: inline-block;
                        position: absolute;
                        right: $grm-label-requiredstar-rightposition;
                        margin-top: $grm-label-requiredstar-margintop;
                        font-size: 1em;
                        font-family: $font-andes-regular;
                        @include respond-to(tablet) {
                            font-family: $font-bold;
                        }
                    }
                    span.required {
                        display: none;
                    }
                }
            }
            input[type="checkbox"],
            input[type="radio"] {
                & + label,
                & + div.error-field + label {
                    span {
                        background-color: $grm-checkbox-bg-color;
                    }
                }
            }
            // base style of placeholder
            [id*="KSTL-Registration-wrapper"].invalid {
                input {
                    &.ng-invalid {
                        @include placeholder {
                            color: $grm-input-invalid-color;
                        }
                    }
                }
                .error-field + div[checkbox] {
                    // margin-bottom: 1.1em;
                }
            }
            .error-field {
                font-size: 12px;
                padding: 5px 0;
                p {
                    font-family: $font-light;
                    font-size: 12px;
                    position: relative;
                    text-align: left;
                    color: #cf122e;
                    margin: 1.5vw 0 0;
                    padding: $grm-login-label-padding--mobile;
                    @include respond-to(tablet) {
                        padding: $grm-login-label-padding--tablet;
                        margin: $grm-login-label-margin--tablet;
                    }
                    @include respond-to(desktop) {
                        padding: $grm-login-label-padding--desktop;
                        margin: $grm-login-label-margin--desktop;
                    }
                }
            }
            .KSTL-Registration-button {
                button i[ng-show="processing"] {
                    margin-top: -2px;
                    right: 0.5em;
                    transform: translateY(-50%);
                    @include respond-to(tablet) {
                        right: 0.5em;
                        top: 60%;
                        margin-top: -1px;
                    }
                    @include respond-to(desktop) {
                        margin-top: 0;
                    }
                }
            }
            #KSTL-Registration-wrapper-Header_additional_label,
            #KSTL-Registration-wrapper-CountryAdditionalLabel {
                text-align: center;
                width: 100%;
                @include respond-to(tablet-only) {
                    margin-top: -10px;
                }
                &,
                a {
                    font-family: $font-andes-medium;
                    line-height: 1.2em;
                }
            }

            #KSTL-Registration-wrapper-Header_additional_label_blank {
                display: none;
            }
            #KSTL-Registration-wrapper-TermsConditions1,
            #KSTL-Registration-wrapper-PrivacyPolicy {
                text-align: left;
                padding-left: 0;
                margin-left: 0;
                .error-field p {
                    padding-top: 0;
                    padding-bottom: 1%;
                    padding-left: 0;
                    @include respond-to(tablet) {
                        margin: 0 !important;
                        padding-bottom: 2%;
                        padding-left: 0;
                    }
                }
                .error-field {
                    height: auto !important;
                }
                .pod .label {
                    text-transform: none;
                    a {
                        font-family: $font-andes-medium;
                        font-weight: bold;
                    }
                }
            }
            #KSTL-Registration-wrapper-ContinueButton1 {
                @include respond-to(tablet) {
                    padding-bottom: 0;
                    width: $grm-wraperbutton-width--tablet;
                    float: left;
                }
                > div {
                    @include respond-to(tablet) {
                        width: $grm-wraperbutton-width--tablet;
                        margin: 0;
                    }
                }
            }
        }
    }
}
#reg-container .KSTL-Registration .pod .ng-form label {
    padding-top: 0;
    padding-bottom: 2%;
    @include respond-to(tablet) {
        padding-bottom: 1%;
        max-width: 100%;
    }
}

.limit-page {
    h1 {
        margin: 0 auto 2vw;
        @include font-vw($mobile: 36px, $tablet: 26px, $desktop: 30px);
    }
    p {
        margin: 0 auto 1vw;
        line-height: 1.3;
        @include font-vw($mobile: 24px, $tablet: 14px, $desktop: 20px);
    }
    .button {
        margin-top: 25px;
    }
}

.shipping-details-page {
    h1 {
        margin: 0 auto 2vw;
        @include font-vw($mobile: 36px, $tablet: 26px, $desktop: 30px);
    }

    .hiding {
        width: 0;
        height: 0;
        opacity: 0;
    }

    .hide {
        display: none;
    }

    h2 {
        @include font-vw($mobile: 30px, $tablet: 20px, $desktop: 25px);
        @include respond-to(mobile-only) {
            padding: 0 6vw;
        }
    }

    p,
    a {
        line-height: 1.4em;
        font-family: $font-andes-medium;
        // font-weight: bold;
        @include font-vw($mobile: 24px, $tablet: 18px, $desktop: 20px);
        margin: $homeLeftColtext-margin--mobile;

        @include respond-to(tablet) {
            line-height: 1.4;
            margin: 0;
            padding: 0.5em 0 0.6em;
        }

        @include respond-to(desktop) {
            margin: 0;
        }

        &.tnclink {
            margin-bottom: 5vw;

            @include respond-to(tablet) {
                margin-bottom: 2.5vw;
            }

            a {
                margin: 0;
                text-decoration: underline;
                color: #2c2b26;
                @include font-vw($mobile: 22px, $tablet: 18px, $desktop: 16px);
            }
        }

        &.tnclink + h2 {
            margin-bottom: 20px;
            font-family: "Andes_Semibold", Arial, "Helvetica Neue", Helvetica, sans-serif;

            @include respond-to(tablet) {
                margin-bottom: 1.5vw;
            }
        }
    }

    p {
        @include respond-to(tablet) {
            padding: 0;
            margin-bottom: 1vw;
        }
    }

    #shipping-selectcountry {
        text-align: left;
        // margin-left: 1.21vw;
        margin-bottom: 20px;

        @include respond-to(mobile-only) {
            margin-bottom: 0;
        }

        label {
            padding-left: 0 !important;
        }

        input[type="radio"] + label:before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            position: absolute;
            left: 0;
            top: 0;
            background: url("/Content/img/icons/celebrate2022-icons_sprite.png") no-repeat;
            background-position: right 0;
        }

        input[type="radio"] + label {
            padding: 3px 35px 0 !important;
        }

        input[type="radio"]:checked + label:before {
            content: "";
            background-position: right -25px;
        }

        input[type="radio"] {
            position: absolute;
            border: 0;
            width: 12px;
            height: 12px;
            padding: 0;
            margin-top: 4px;

            & + label,
            & + div.error-field + label {
                position: relative;
                padding: $grm-termslabel-padding--mobile;
                margin: 0;
                display: inline-block;
                width: auto;

                @include respond-to(tablet) {
                    padding: $grm-termslabel-padding--tablet;
                }

                @include respond-to(desktop) {
                    padding: $grm-termslabel-padding--desktop;
                }

                span {
                    display: inline-block;
                    vertical-align: middle;
                    background-image: url("/Content/img/icons/celebrate2022-icons_sprite.png");
                    background-repeat: no-repeat;
                    background-position: 0 0;
                    background-color: $grm-checkbox-bg-color;
                    cursor: pointer;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -$grm-checkbox-margintop--mobile;
                    height: 31px;
                    width: 30px;

                    @include respond-to(tablet) {
                        margin-top: -$grm-checkbox-margintop--tablet;
                    }

                    @include respond-to(desktop) {
                        margin-top: -$grm-checkbox-margintop--desktop;
                    }
                }
            }

            &:checked + label span,
            &:checked + div.error-field + label span {
                background-position: 0 -39px;
            }

            &:focus,
            &:active {
                &.ng-invalid,
                &.ng-valid {
                    &.ng-dirty {
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        outline: 0;
                    }
                }
            }
        }
    }
    .error-wrapper {
        min-height: 3px;
        height: auto;

        @include respond-to(tablet) {
            height: 35px;
        }
        p {
            @include font-vw($mobile: 20px, $tablet: 10px, $desktop-lg: 14px);
            color: #cf122e;
        }
    }

    .error input {
        color: #2c2b26 !important;
    }

    .error-message {
        //height: auto;
        margin: 0;
        padding: 0;

        p {
            span {
                font-family: $font-extralight;
                @include font-vw(
                    $mobile: $grm-errorfield-fs--mobile,
                    $tablet: $grm-errorfield-fs--tablet,
                    $desktop-lg: $grm-errorfield-fs--desktop
                );
                font-weight: bold;
                padding: $grm-error-padding--mobile;
                margin: $grm-error-margin--mobile;
                position: relative;
                text-align: left;
                color: #b94a48;

                @include respond-to(tablet) {
                    margin-top: 2px;
                }
            }

            text-align: left;
            width: 100%;
            padding-bottom: 10px;
        }
    }

    label {
        @include respond-to(tablet) {
            padding: 0;
        }
        @include respond-to(desktop) {
            padding: 0;
        }
    }

    .termsAndCond,
    .receiveInfo {
        margin-top: 10px;
        margin-bottom: 10px;
        input {
            display: none;
        }

        .checkbox {
            height: 31px;
            width: 31px;
            background-image: url("/Content/img/icons/celebrate2022-icons_sprite.png");
            background-repeat: no-repeat;
            background-position: 0 0;
            //outline:none;
            float: left;
            margin-bottom: 0;
            padding: 0.5em;
            //margin-top: 3px;
            background-color: #f8df74;
        }

        .checked {
            background-position: 0 -39px;
        }

        label {
            &,
            a {
                font-weight: normal;
            }
        }

        .error-wrapper {
            height: auto;
        }
    }

    .receiveInfo {
        .checkbox-text {
            display: inline-block;
            margin-left: 10px;
            margin-bottom: 0;
            font-size: 3.5vw;
            float: left;
            @include font-vw($mobile: 18px, $tablet: 14px, $desktop: 14px);
            line-height: 2;
            color: #3f3f3f;
            text-align: left;
            text-transform: none;
            font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
            width: 82%;
            @include respond-to(mobile-only) {
                line-height: 1.1;
            }
            a {
                font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
                @include font-vw($mobile: 18px, $tablet: 14px, $desktop: 14px);
                color: #3f3f3f;
                cursor: pointer;
                text-decoration: underline;
                vertical-align: baseline;
                display: inline;
                text-transform: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .termsAndCond {
        .checkbox-text {
            display: inline-block;
            margin-left: 10px;
            margin-bottom: 0;
            font-size: 3.5vw;
            float: left;
            @include font-vw($mobile: 18px, $tablet: 14px, $desktop: 14px);
            line-height: 2;
            text-align: left;
            text-transform: none;
            font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
            width: 82%;
            color: #3f3f3f;

            @include respond-to(mobile-only) {
                line-height: 1.1;
            }

            p {
                color: #3f3f3f;
                //font-weight: normal;
                font-size: 15px;
                margin: 0;
                font-weight: normal;
                font-size: 3.5vw;

                @include respond-to(tablet) {
                    font-size: 15px;
                }

                @include font-vw($mobile: 24px, $tablet: 16px, $desktop-lg: 16px);
            }

            a {
                font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
                @include font-vw($mobile: 18px, $tablet: 14px, $desktop: 14px);
                color: #3f3f3f;
                cursor: pointer;
                text-decoration: underline;
                vertical-align: baseline;
                display: inline;
                text-transform: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    #sdForm.shipping {
        padding-top: 3vw;
        display: flex;
        flex-wrap: wrap;
        > div {
            padding: $shipping-formcontrol-padding-mobile;
            position: relative;
            flex: 0 0 100%;
            color: $site-copy-color;
            font-family: $font-andes-regular;
            @include respond-to(tablet) {
                flex: 0 0 47%;
                margin-left: $grm-colleft-paddingleft--tablet;
                padding: $shipping-formcontrol-padding-tablet;
            }
            &:nth-child(2n + 2) {
                @include respond-to(tablet) {
                    margin-left: $grm-colright-paddingleft--tablet;
                    margin-right: $grm-colright-paddingright--tablet;
                }
            }
            @include respond-to(desktop) {
                padding: $shipping-formcontrol-padding-desktop;
            }
            &:first-child {
                flex: 0 0 100%;
            }
        }
        .termsAndCond,
        .receiveInfo,
        .captcha-box,
        .cta-container {
            flex: 0 0 100%;
        }
        .captcha-box {
            clear: both;
            margin-bottom: 50px;
            padding-top: 10px;

            // margin-left: 1.21vw;
            @include respond-to(mobile-only) {
                margin-bottom: 7%;
            }

            @media only screen and (max-width: 413px) {
                #rc-imageselect,
                .g-recaptcha {
                    transform: scale(0.9);
                    -webkit-transform: scale(0.9);
                    transform-origin: 0 0;
                    -webkit-transform-origin: 0 0;
                    width: 335px;
                }
            }

            @media only screen and (max-width: 374px) {
                #rc-imageselect,
                .g-recaptcha {
                    transform: scale(0.77);
                    -webkit-transform: scale(0.77);
                    transform-origin: 0 0;
                    -webkit-transform-origin: 0 0;
                    width: 285px;
                }
            }
        }
        .termsAndCond{
            margin-left: 0;
            a{
                padding: 0 4px;
            }
        }
        .captcha-box{
            margin-left: 0;
        }
    }

    .form-control {
        label {
            color: #4a4a4a;
            text-align: left;
            text-transform: uppercase;
            padding: 0 0.21vw 2% 0;
            margin: 0;
            font-weight: normal;
            @include font-vw($mobile: 27px, $tablet: 11px, $desktop: 14px);
            line-height: 1.2;
        }

        .error-message {
            p {
                padding: 0 0.21vw 0vw 0;
                span {
                    @include font-vw($mobile: 14px, $tablet: 10px, $desktop: 10px);
                }
            }
        }

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="password"] {
            @include font-vw(
                $mobile: $grm-input-fs--mobile,
                $tablet: $grm-input-fs--tablet,
                $desktop: $grm-input-fs--desktop
            );
            @include respond-to(mobile-only) {
                padding: 3vw;
            }
        }

        input,
        textarea,
        input[type="checkbox"] {
            line-height: 1.15;
            @include border-radius(20px);
            color: $input-color;
            max-width: 100%;
            background: #f9df74;
            @include box-shadow-none();
            border: none;
            @include font-vw(
                $mobile: $grm-input-fs--mobile,
                $tablet: $grm-input-fs--tablet,
                $desktop: $grm-input-fs--desktop
            );
            padding: $grm-input-padding--mobile;

            @include respond-to(tablet) {
                // @include border-radius(2.42vw);
                padding: $grm-input-padding--tablet;
            }

            /* added by ca*/
            //border-radius: 0;
            //background: none;
            box-sizing: border-box;
            box-shadow: none !important;
            -webkit-appearance: none !important;
            height: auto;
            text-align: left;
            // color: #2c2b26;
            // border-top:1px solid $black;
            // border-bottom:1px solid $black;
            // border-left: none;
            // border-right: none;
            // padding-left: 0;
            // padding-right: 0;
            //@include font-vw($mobile: $shipping-input-fs--mobile, $tablet: $shipping-input-fs--tablet, $desktop: $shipping-input-fs--desktop);
            // padding removed by charu
            //padding: $shipping-input-padding--mobile;
            //line-height: 1.18em;
            font-family: $shipping-input-ff--mobile;

            //letter-spacing: $shipping-input-letterspacing--mobile;
            //color: $shipping-input-color--mobile;
            @include respond-to(tablet) {
                //padding: $shipping-input-padding--tablet;
                //font-family: $shipping-input-ff--tablet;
                //letter-spacing: $shipping-input-letterspacing--tablet;
            }

            @include respond-to(desktop) {
                //padding: $shipping-input-padding--desktop;
                //font-family: $shipping-input-ff--desktop;
                //letter-spacing: $shipping-input-letterspacing--desktop;
            }
        }

        input[type="radio"] {
            width: 12px;
            height: 12px;
            padding: 0;
            margin-top: 4px;
        }
    }

    #shipping-terms,
    #pre-populate {
        label {
            text-align: $shipping-terms-label-textalign--mobile;
            text-transform: none;
            font-family: $shipping-terms-label-ff--mobile;

            @include respond-to(tablet) {
                font-family: $shipping-terms-label-ff--tablet;
                text-align: $shipping-terms-label-textalign--tablet;
            }

            @include font-vw(
                $mobile: $shipping-terms-label-fs--mobile,
                $tablet: $shipping-terms-label-fs--tablet,
                $desktop-lg: $shipping-terms-label-fs--desktop
            );

            a {
                text-decoration: $shipping-terms-label-link-textdecoration--mobile;
                @include font-vw(
                    $mobile: $shipping-terms-label-link-fs--mobile,
                    $tablet: $shipping-terms-label-link-fs--tablet,
                    $desktop-lg: $shipping-terms-label-link-fs--desktop
                );
                font-family: $shipping-terms-label-link-ff--mobile;

                @include respond-to(tablet) {
                    text-decoration: $shipping-terms-label-link-textdecoration--tablet;
                    font-family: $shipping-terms-label-link-ff--tablet;
                }

                @include respond-to(tablet) {
                    text-decoration: $shipping-terms-label-link-textdecoration--desktop;
                }
            }
        }

        input[type="checkbox"] {
            display: none;

            & + label {
                position: relative;
                padding: $shipping-termslabel-padding--mobile;
                margin: $shipping-termslabel-margin--mobile;
                line-height: $shipping-termslabel-lineheight--mobile;

                @include respond-to(tablet) {
                    padding: $shipping-termslabel-padding--tablet;
                }

                @include respond-to(desktop) {
                    padding: $shipping-termslabel-padding--desktop;
                }

                span {
                    display: inline-block;
                    vertical-align: middle;
                    // background-image: $shipping-checkboxempty--mobile;
                    background-repeat: no-repeat;
                    background-size: $shipping-checkboxempty-bgsize--mobile;
                    background-color: $shipping-checkbox-bg-color;
                    cursor: pointer;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: $shipping-checkbox-margintop--mobile;
                    height: $shipping-checkbox-height--mobile;
                    width: $shipping-checkbox-width--mobile;
                    @include border-radius($shipping-checkbox-bradius--mobile);

                    @include respond-to(tablet) {
                        // background-image: $shipping-checkboxempty--tablet;
                        margin-top: $shipping-checkbox-margintop--tablet;
                        height: $shipping-checkbox-height--tablet;
                        width: $shipping-checkbox-width--tablet;
                    }

                    @include respond-to(desktop) {
                        margin-top: $shipping-checkbox-margintop--desktop;
                        height: $shipping-checkbox-height--desktop;
                        width: $shipping-checkbox-width--desktop;
                    }
                }
            }

            &:checked + label span {
                // background-image: $shipping-checkboxchecked--mobile;
                @include respond-to(tablet) {
                    // background-image: $shipping-checkboxchecked--tablet;
                }
            }

            &:focus,
            &:active {
                &.ng-invalid,
                &.ng-valid {
                    &.ng-dirty {
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        outline: 0;
                    }
                }
            }
        }
    }

    .shipping-details-form-sending {
        border-top: $shipping-secondrow-bordertop--mobile;

        @include respond-to(tablet) {
            border-top: $shipping-secondrow-bordertop--tablet;
        }

        * {
            text-shadow: $shipping-secondrow-textshadow--mobile;

            @include respond-to(tablet) {
                text-shadow: $shipping-secondrow-textshadow--tablet;
            }
        }

        &.col-one {
            padding: $shipping-secondrow-padding--mobile;

            @include respond-to(tablet) {
                position: relative;
                top: 237px;
                right: 140px;
            }
        }

        > p {
            margin: $shipping-secondrow-text-margin--mobile;
            @include font-vw(
                $mobile: $shipping-secondrow-text-fs--mobile,
                $tablet: $shipping-secondrow-text-fs--tablet,
                $desktop-lg: $shipping-secondrow-text-fs--desktop
            );

            @include respond-to(tablet) {
                margin: $shipping-secondrow-text-margin--tablet;
                line-height: $shipping-secondrow-text-lineheight--tablet;
            }

            @include respond-to(desktop) {
                margin: $shipping-secondrow-text-margin--desktop;
                line-height: $shipping-secondrow-text-lineheight--desktop;
            }
        }
    }

    button {
        i {
            margin: $shipping-button-i-margin--mobile;
        }
        & + * {
            font-family: $font-andes-regular;
            margin: $shipping-mandatoryfieldsinfo-margin--mobile;
            @include font-vw(
                $mobile: $shipping-mandatoryfieldsinfo-fs--mobile,
                $tablet: $shipping-mandatoryfieldsinfo-fs--tablet,
                $desktop-lg: $shipping-mandatoryfieldsinfo-fs--desktop
            );

            @include respond-to(tablet) {
                margin: $shipping-mandatoryfieldsinfo-margin--tablet;
            }

            @include respond-to(desktop) {
                margin: $shipping-mandatoryfieldsinfo-margin--desktop;
            }
        }
    }

    button span.ng-binding {
        padding: 0;
        display: none;
        vertical-align: top;
        position: static;
        color: #2c2b26 !important;
        &.processing{
            display: inline-block;
        }
        i {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            right: 4vw;

            @include respond-to(tablet) {
                right: 1vw;
            }

            margin: 0 0 0 0.25em;
            vertical-align: middle;

            .ai-white-spin {
                border-color: rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.5) #fff #fff;
            }

            .ai-small {
                height: 13px;
                width: 13px;
                border-width: 2px;
            }

            .ai-circled,
            .ai-small {
                /*height: 32px;
                        width: 32px;*/
                position: relative;
                /*border-width: 5px;*/
                border-style: solid;
                border-radius: 100%;
                -webkit-animation: spin 0.6s linear infinite;
                animation: spin 0.6s linear infinite;
            }

            .ai-indicator,
            .ai-indicator *,
            .ai-indicator :after,
            .ai-indicator :before {
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                display: block;
            }
        }
        @keyframes spin {
            0% {
                -moz-transform: rotateZ(0deg);
                -webkit-transform: rotateZ(0deg);
                -o-transform: rotateZ(0deg);
                -ms-transform: rotateZ(0deg);
            }

            100% {
                -moz-transform: rotateZ(360deg);
                -webkit-transform: rotateZ(360deg);
                -o-transform: rotateZ(360deg);
                -ms-transform: rotateZ(360deg);
            }
        }
        @-webkit-keyframes spin {
            0% {
                -moz-transform: rotateZ(0deg);
                -webkit-transform: rotateZ(0deg);
                -o-transform: rotateZ(0deg);
                -ms-transform: rotateZ(0deg);
            }

            100% {
                -moz-transform: rotateZ(360deg);
                -webkit-transform: rotateZ(360deg);
                -o-transform: rotateZ(360deg);
                -ms-transform: rotateZ(360deg);
            }
        }

        @-moz-keyframes spin {
            0% {
                -moz-transform: rotateZ(0deg);
                -webkit-transform: rotateZ(0deg);
                -o-transform: rotateZ(0deg);
                -ms-transform: rotateZ(0deg);
            }

            100% {
                -moz-transform: rotateZ(360deg);
                -webkit-transform: rotateZ(360deg);
                -o-transform: rotateZ(360deg);
                -ms-transform: rotateZ(360deg);
            }
        }

        @-o-keyframes spin {
            0% {
                -moz-transform: rotateZ(0deg);
                -webkit-transform: rotateZ(0deg);
                -o-transform: rotateZ(0deg);
                -ms-transform: rotateZ(0deg);
            }

            100% {
                -moz-transform: rotateZ(360deg);
                -webkit-transform: rotateZ(360deg);
                -o-transform: rotateZ(360deg);
                -ms-transform: rotateZ(360deg);
            }
        }

        @-ms-keyframes spin {
            0% {
                -moz-transform: rotateZ(0deg);
                -webkit-transform: rotateZ(0deg);
                -o-transform: rotateZ(0deg);
                -ms-transform: rotateZ(0deg);
            }

            100% {
                -moz-transform: rotateZ(360deg);
                -webkit-transform: rotateZ(360deg);
                -o-transform: rotateZ(360deg);
                -ms-transform: rotateZ(360deg);
            }
        }
    }
}

body[pagename="Batchcodeentry"] {
    .container {
        @include respond-to(tablet) {
            padding-left: 4vw;
            padding-right: 4vw;
        }
        @include respond-to(desktop-lg) {
            padding-left: 7vw;
            padding-right: 7vw;
        }
    }
}

.batchcode-page {
    .error * {
        color: $inputError-color;
    }
    .twocols {
        > div {
            &:first-child {
                position: relative;
                &:after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    background: $btn-text-grey;
                    left: 0;
                    bottom: 0;
                    @include respond-to(tablet) {
                        width: 1px;
                        height: 99%;
                        top: 1%;
                        right: -1px;
                        left: auto;
                        bottom: auto;
                    }
                }
                @include respond-to(mobile-only) {
                    margin-bottom: 10vw;
                }
                > div {
                    margin: $homeLeftCol-margin--mobile;
                    @include respond-to(mobile-only) {
                        padding: 0 0 7vw;
                    }
                    @include respond-to(tablet) {
                        margin: $homeLeftCol-margin--tablet;
                    }
                    @include respond-to(desktop) {
                        margin: $homeLeftCol-margin--desktop;
                    }
                }
                h1,
                h2 {
                    @include font-vw($mobile: 36px, $tablet: 16px, $desktop: 22px, $desktop-lg: 25px);
                    color: #000;
                    font-family: $font-bold;
                    font-weight: bold;
                    line-height: 1.4;
                    margin: 2.5vw 0 5vw;
                    @include respond-to(tablet) {
                        margin: $homeLeftColheadertext-margin--tablet;
                    }
                    @include respond-to(desktop) {
                        margin: $homeLeftColheadertext-margin--desktop;
                    }
                }
                p,
                a,
                h4 {
                    line-height: 1.4;
                    font-family: $font-andes-medium;
                    // font-weight: bold;
                    @include font-vw($mobile: 27px, $tablet: 12px, $desktop: 16px);
                    margin: $homeLeftColtext-margin--mobile;
                    @include respond-to(tablet) {
                        margin: $homeLeftColtext-margin--tablet;
                    }
                    @include respond-to(desktop) {
                        margin: $homeLeftColtext-margin--desktop;
                    }
                    &.tnclink {
                        margin: 3vw 0;
                        @include respond-to(tablet) {
                            margin: 1.5vw 0;
                        }
                        a {
                            margin: 0;
                            text-decoration: underline;
                            color: #2c2b26;
                            @include font-vw($mobile: 27px, $tablet: 18px, $desktop: 16px);
                            cursor: pointer;
                        }
                    }
                    &.tnclink + p {
                        margin-bottom: 0;
                    }
                }
                .buy-now-txt {
                    margin: 5vw 0;
                    @include respond-to(tablet) {
                        margin: 2vw 0 0;
                    }
                    div {
                        display: inline;
                        span {
                            text-decoration: underline;
                        }
                    }
                }
                h4 {
                    font-family: $font-bold;
                    @include font-vw($mobile: 30px, $tablet: 20px, $desktop: 20px);
                }
                label {
                    font-family: $font-andes-medium;
                    margin-bottom: 3.4vw;
                    line-height: 1.4;
                    font-weight: bold;
                    @include font-vw($mobile: 27px, $tablet: 14px, $desktop: 20px);
                    @include respond-to(tablet) {
                        margin-bottom: 1.1vw;
                    }
                    @include respond-to(mobile-only){
                        padding-top: 4vw;
                    }
                }
                .error-message p {
                    margin: 0 auto;
                    width: 100%;
                    @include font-vw($mobile: 16px, $tablet: 16px, $desktop: 16px);
                }
                #upcCodeForm {
                    input[type="text"] {
                        text-transform: uppercase;
                        // margin-top: 0.25em;
                        //@include border-radius(6px);
                        box-shadow: none;
                        border: none;
                        @include border-radius(30px);
                        background-color: #f9df74;
                        color: #726841;
                        @include font-vw(
                            $mobile: $input-fs--mobile,
                            $tablet: $input-fs--tablet,
                            $desktop: $input-fs--desktop
                        );
                        margin: $homeLeftColInput-margin--mobile !important;
                        padding: $home-input-padding--mobile;
                        @include respond-to(tablet) {
                            margin: $homeLeftColInput-margin--tablet !important;
                            padding: $home-input-padding--tablet;
                        }
                        @include respond-to(desktop-lg) {
                            padding: $home-input-padding--desktop;
                        }
                    }
                    input::-moz-placeholder {
                        line-height: 1.3;
                    }

                    input:-moz-placeholder {
                        line-height: 1.3;
                    }

                    input:-ms-input-placeholder {
                        line-height: 1.3;
                    }
                    @-moz-document url-prefix() {
                        padding:1vw 1vw 0.6vw;
                      }
                }
                #upcCodeForm input.ng-pristine.ng-invalid::-webkit-input-placeholder {
                    color: #726841;
                }
                #upcCodeForm input.ng-pristine.ng-invalid::placeholder {
                    color: #726841;
                }
                .button {
                    margin: 0 auto;
                    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.11);
                    // max-width: 100% - $leftCol-marginleft--mobile - $leftCol-marginright--mobile;
                    @include border-radius(20px);
                    // @include font-vw(desktop-lg: 38px);
                    div {
                        padding: 0.7em 0.2em 0.75em;
                        @include respond-to(tablet) {
                            padding-bottom: 0.9em;
                        }
                        @include respond-to(desktop) {
                            padding-bottom: 0.35em;
                        }
                    }
                }
                label {
                    @include respond-to(tablet) {
                        //padding: $label-padding--tablet;
                    }
                    @include respond-to(desktop) {
                        //padding: $label-padding--desktop;
                    }
                }
            }
            &:last-child {
                h2,
                h3 {
                    text-transform: uppercase;
                    @include font-vw($mobile: 35px, $tablet: 16px, $desktop: 22px, $desktop-lg: 25px);
                    color: #000;
                    font-family: $font-bold;
                    font-weight: bold;
                    line-height: 1.4;
                    margin: 0;
                }
                picture {
                    display: block;
                    margin: 3vw 0;
                    @include respond-to(tablet) {
                        margin: 2vw 0;
                    }
                }
                img {
                    width: 60%;
                    @include respond-to(tablet) {
                        width: 75%;
                    }
                    @include respond-to(tablet-only) {
                        width: 65%;
                    }
                    margin: 2vw auto;
                }
                .below-can {
                    color: #000;
                    font-family: $font-andes-extraBold;
                    font-weight: bold;
                    @include font-vw($mobile: 24px, $tablet: 14px, $desktop: 17px, $desktop-lg: 20px);
                    line-height: 1.4;
                    @include respond-to(mobile-only) {
                        padding: 0 9.3vw;
                    }
                    @include respond-to(tablet) {
                        padding: 0 6vw 0 3vw;
                    }
                }
            }
        }
        .form-control {
            &:nth-child(3) .upload-receipt {
                padding-bottom: 10px;
                @include respond-to(desktop) {
                    padding-bottom: 15px;
                }
            }
            .upload-receipt {
                text-align: center;
                p {
                    padding-top: 11px;
                    padding-bottom: 4px;
                    margin: 0 auto;
                    @include respond-to(tablet) {
                        padding-top: 7px;
                        padding-bottom: 7px;
                    }
                    text-align: center;
                }
                > span,
                > p span {
                    //color: $home-form-color;
                    @include font-vw($mobile: 22px, $tablet: 16px, $desktop: 16px);
                }
                .file-name,
                .error {
                    padding: 5px 20px 0;
                    line-height: 1.2em;
                    display: block;
                    text-align: center;
                    //font-family: $font-andes-medium;
                }
                .error {
                    color: $inputError-color;
                }
                .file-name {
                    .icon {
                        display: inline-block;
                        background: url("/Content/img/icons/celebrate2022-icons_sprite.png") no-repeat right -80px;
                        width: 21px;
                        height: 19px;
                        margin: -1px 8px 0 0;
                        vertical-align: middle;
                    }
                }
                .error {
                    p {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
                .receipt-wrapper {
                    display: table;
                    margin: 5px auto 0;
                    .button {
                        display: table-cell;
                        padding: 10px 20px;
                        vertical-align: middle;
                        @include border-radius(6px);
                    }
                    .info-wrapper {
                        display: table-cell;
                        vertical-align: middle;
                        .btn-info {
                            height: 30px;
                            width: 30px;
                            border-radius: 50%;
                            border: 2px solid #2c2b26;
                            display: inline-block;
                            margin-left: 10px;
                            padding: 5px;
                            color: #2c2b26;
                            cursor: pointer;
                            font-family: $font-andes-medium;
                            text-align: center;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    button,
    a.button {
        &#homeBtn {
            margin-top: 20px;
            @include respond-to(tablet) {
                margin-top: 2vw;
            }
            .loader-wrapper .loader {
                display: none;
            }
            &.inactive.processing {
                .loader-wrapper .loader {
                    display: block;
                }
            }
        }
    }
    .captcha-box {
        padding-top: 10px;
        @include respond-to(desktop-only) {
            #rc-imageselect,
            .g-recaptcha {
                transform: scale(0.9);
                -webkit-transform: scale(0.9);
                transform-origin: 0 0;
                -webkit-transform-origin: 0 0;
                width: 335px;
            }
        }
        @include respond-to(tablet-only) {
            #rc-imageselect,
            .g-recaptcha {
                transform: scale(0.8);
                -webkit-transform: scale(0.8);
                transform-origin: 0 0;
                -webkit-transform-origin: 0 0;
                width: 300px;
            }
        }

        @media only screen and (max-width: 413px) {
            #rc-imageselect,
            .g-recaptcha {
                transform: scale(0.8);
                -webkit-transform: scale(0.8);
                transform-origin: 0 0;
                -webkit-transform-origin: 0 0;
                width: 300px;
            }
        }
    }
}

.android,
.mac {
    .batchcode-page .twocols > div:first-child #upcCodeForm input[type="text"] {
        @include font-vw($mobile: 30px, $tablet: 13px, $desktop: 17px, $desktop-lg: 20px);
    }
}

.activeOverlay {
    .ng-modal-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: rgba(36, 36, 36, 0.6);
        z-index: 9999;
        opacity: 1;
        height: 100%;
        -webkit-transition: opacity 0.5s ease-out;
        -moz-transition: opacity 0.5s ease-out;
        -o-transition: opacity 0.5s ease-out;
        -ms-transition: opacity 0.5s ease-out;
        transition: opacity 0.5s ease-out;
        overflow: auto;
        .ng-modal {
            position: absolute;
            width: 100%;
            height: 100%;
            .ng-modal-overlay {
                /* A dark translucent div that covers the whole screen */
                position: absolute;
                z-index: 9999;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            .ng-modal-dialog {
                /* A centered div above the overlay with a box shadow. */
                z-index: 10000;
                max-width: 650px;
                height: auto;                
                margin: 60px auto;
                position: relative;
                width: 90%;
                border-radius: 20px;
                //        position: absolute;
                //        width: 50%;
                //        /* Default */
                //        /* Center the dialog */
                //        top: 50%;
                //        left: 50%;
                //        transform: translate(-50%, -50%);
                //        -webkit-transform: translate(-50%, -50%);
                //        -moz-transform: translate(-50%, -50%);
                background-color: #fff;
                //        height:auto !important;
                //        box-shadow: 4px 4px 80px #000;
                @include respond-to(tablet) {
                    margin: 65px auto;
                }
                 @include respond-to(mobile-only) {
                    max-height : 550px;
                    margin: 90px auto;
                }
            }
            .ng-modal-dialog.adjustVertical{
                transform: translateY(-50%);
                top: 50%;
                position: relative;
                margin: 0 auto; 
            }
            .ng-modal-dialog-content {
                max-height: none;
                overflow: hidden;                
                padding: 5% 0;
                text-align: center;
                width: 80%;
                max-width: 850px;
                margin: 0 auto;
                @include respond-to(tablet) {
                    padding: 7% 10px;
                }
                @include respond-to(desktop) {
                    padding: 5% 10px;
                }
                 @include respond-to(mobile-only) {
                    width : 85%;
                    word-wrap: break-word;
                }
                div {
                    &.heading-container {
                        border-bottom: 2px solid #e0e0e0;
                        margin-top: 50px;
                        @include respond-to(tablet) {
                            margin-top: 20px;
                        }
                    }
                    h2 {
                        @include font-vw($mobile: 40px, $tablet: $h2-fs--tablet, $desktop-lg: $h2-fs--desktop);
                        padding-bottom: 4%;
                    }
                }
                .instructions-list {
                    padding-top: 4%;
                     @include respond-to(mobile-only) {                        
                        -webkit-overflow-scrolling: touch;                        
                        margin-top: 15px;
                    }
                    p {
                        padding: 0;  
                        text-align: left; 
                        padding-right: 1vw;
                    }
                    p.subheading {
                        text-align: center;                       
                        margin-top : 5px; 
                        font-weight : bold;
                        @include respond-to(desktop) {
                            margin-top : 20px; 
                        }
                    }
                    ol {
                        text-align: left;
                        display: inline-block;
                        font-family: $font-andes-medium;
                        @include font-vw($mobile: 30px, $tablet: 20px, $desktop-lg: 20px);
                        line-height: 1.4em;
                        padding: 30px 0 0 30px;
                        list-style-type: decimal;
                        li {
                            padding-bottom: 3px;
                        }
                    }
                }
            }
            .ng-modal-close {
                position: absolute;
                top: 15px;
                right: 15px;
                padding: 5px;
                cursor: pointer;
                display: inline-block;
                background-image: url('/Content/img/icons/celebrate2022-close-icon-overlay.png');
                background-repeat: no-repeat;
                background-size: contain;                
                height: 35px;
                width: 35px;
                @include respond-to(tablet) {                   
                    top: 30px;
                    right: 30px;
                }
            }
        }
    }
}
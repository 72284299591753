// =============================================================================
// Mixins
// =============================================================================
//styles border-radius
@mixin border-radius($border-radius) {
  border-radius: $border-radius !important;
  -moz-border-radius: $border-radius !important;
  -webkit-border-radius: $border-radius !important;
}

//styles box-shadow
@mixin box-shadow($horizontall: 0px, $verticall: 0px, $blur: 2px, $spread: 1px, $rgbacolor: rgba(0, 0, 0, 0.11)) {
  -webkit-box-shadow: $horizontall $verticall $blur $spread $rgbacolor;
  -moz-box-shadow: $horizontall $verticall $blur $spread $rgbacolor;
  box-shadow: $horizontall $verticall $blur $spread $rgbacolor;
}

//styles for translate
@mixin translate($translate-percent) {
  -webkit-transform: translateX($translate-percent);
  -moz-transform: translateX($translate-percent);
  -ms-transform: translateX($translate-percent);
  -o-transform: translateX($translate-percent);
  transform: translateX($translate-percent);
}

//styles box-shadow-none
@mixin box-shadow-none() {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

//styles button
@mixin button($button-bg, $copy-color, $button-hover-bg) {
  color: $copy-color;
  background-color: $button-bg;
  background-size: 100%;
  @include border-radius(8px);
  @include respond-to(tablet) {
    @include border-radius(4px);
  }
  span {
    color: $copy-color !important;
  }
  .loader-wrapper .loader,
  i[ng-show="processing"] span {
    border: 2px solid $copy-color;
    border-color: rgba($copy-color, 0.5) rgba($copy-color, 0.5) $copy-color $copy-color;
  }
  i[ng-show="processing"] span {
    color: $copy-color;
  }
  &:hover {
    background-color: $button-hover-bg;
  }
  &.inactive {
    span {
      opacity: 0.65;
    }
    // @include button($button-bg,$button-color);
  }
}

//styles button with gradient bg
@mixin button-grad($button-bg, $button-grad-top, $button-grad-bottom, $copy-color) {
  @include button($button-bg, $copy-color);
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, $button-grad-top),
    color-stop(100%, $button-grad-bottom)
  );
  background-image: -moz-linear-gradient(top, $button-grad-top 0%, $button-grad-bottom 100%);
  background-image: -webkit-linear-gradient(top, $button-grad-top 0%, $button-grad-bottom 100%);
  background-image: linear-gradient(to bottom, $button-grad-top 0%, $button-grad-bottom 100%);
}
@mixin set-container-theme($bgcolor, $copy-color, $label-color: $copy-color) {
  background-color: $bgcolor !important;
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  span,
  label,
  li,
  a {
    color: $copy-color;
  }
  label {
    color: $label-color;
  }
}

@mixin calc($property, $expression) {
  #{$property}: -moz-calc(#{$expression}) !important;
  #{$property}: -webkit-calc(#{$expression}) !important;
  #{$property}: calc(#{$expression}) !important;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

//Use @extend to use these properties
%clearfix {
  *zoom: 1;
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

%visuallyhidden {
  font-size: 0;
  width: 1px;
  height: 1px;
  display: inline-block;
  overflow: hidden;
  position: absolute !important;
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
}

@mixin display-flex() {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}
@mixin display-inlineflex() {
  display: -webkit-inline-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-inline-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-inline-flexbox; /* TWEENER - IE 10 */
  display: -webkit-inline-flex; /* NEW - Chrome */
  display: inline-flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

/// Mixin to manage responsive breakpoints

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Font size mixin
@function px-to-vw($target) {
  $vw-context: (1300 * 0.01) * 1px;
  @return ($target / $vw-context) * 2vw;
}

@mixin font-vw($mobile: false, $tablet: false, $desktop: false, $desktop-lg: false) {
  @if $mobile {
    font-size: $mobile;
    font-size: px-to-vw($mobile);
  }
  @if $tablet {
    @include respond-to(tablet) {
      font-size: $tablet;
      font-size: px-to-vw($tablet);
    }
  }
  @if $desktop {
    @include respond-to(desktop) {
      font-size: $desktop;
    }
  }
  @if $desktop-lg {
    @include respond-to(desktop-lg) {
      font-size: $desktop-lg;
    }
  }
}

// BreakPoints
@mixin respond-to($breakpoints) {
  @if $breakpoints == tablet {
    @media only screen and (min-width: $sm-min) {
      @content;
    }
  } @else if $breakpoints == desktop {
    @media only screen and (min-width: $md-min) {
      @content;
    }
  } @else if $breakpoints == desktop-lg {
    @media only screen and (min-width: $lg-min) {
      @content;
    }
  } @else if $breakpoints == desktop-xl {
    @media only screen and (min-width: $xl-min) {
      @content;
    }
  } @else if $breakpoints == mobile-only {
    @media only screen and (max-width: $sm-max) {
      @content;
    }
  } @else if $breakpoints == tablet-only {
    @media only screen and (min-width: $sm-min) and (max-width: $md-max) {
      @content;
    }
  } @else if $breakpoints == desktop-only {
    @media only screen and (min-width: $md-min) and (max-width: $lg-max) {
      @content;
    }
  } @else if $breakpoints == desktop-lg-only {
    @media only screen and (min-width: $lg-min) and (max-width: $xl-max) {
      @content;
    }
  }
}

.wordsearch-page {
	max-width: 750px;
	.ai-circled {
		margin: 20px auto 0;
	}
	.text {
		padding: 7.5% 0 20%;
		@include respond-to(tablet) {
			padding: 40px 0 5%;
		}
		@include respond-to(desktop) {
			padding-top: 50px;
		}
		h3 {
			@include font-vw($mobile: 28px, $tablet: 29px, $desktop-lg: 29px);
			@include respond-to(tablet) {
				padding-left: 2%;
				padding-right: 2%;
			}
		}
		a {
			width: 68%;
			display: block;
			margin: 8% auto 0;
			@include respond-to(tablet) {
				width: 200px;
				min-width: 30%;
				margin-top: 6%;
			}
		}
		& > h6 {
			text-align: center;
			padding: 0 5%;
			@include font-vw($mobile: 28px, $tablet: 30px, $desktop-lg: 30px);
			line-height: 1.2em;
			margin: 0 auto;
			@include respond-to(tablet) {
				padding: 0;
				max-width: 65%;
			}
		}
		& > p {
			font-family: Andes;
			text-align: center;
			margin: 3% auto 7%;
			padding: 0 5%;
			@include font-vw($mobile: 15px, $tablet: 15px, $desktop-lg: 15px);
			@include respond-to(tablet) {
				margin: 2% auto 0;
				max-width: 55%;
				padding: 0 0 3%;
			}
		}
	}
	.two-col {
		width: 90%;
		margin: 0 auto;
		display: flex;
		@include respond-to(mobile-only) {
			flex-wrap: wrap;
		}
		flex-wrap: nowrap;
		justify-content: center;
		.col-1,
		.col-2 {
			@include respond-to(mobile-only) {
				flex: 0 0 90%;
			}
		}
		@include respond-to(tablet) {
			.col-2 {
				flex: 0 0 35%;
			}
		}
	}
	p {
		color: $site-copy-color;
	}
	.cross-word {
		margin: 0 auto;
		display: block;
		width: 100%;
		@include respond-to(tablet) {
		}
		& + div {
			@include respond-to(tablet) {
				vertical-align: top;
				padding-left: 6%;
			}
		}
		.tabContainer {
			position: relative;
		}
		table {
			position: absolute;
			width: 100%;
			height: inherit;
		}
		canvas {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			cursor: pointer;
		}
		td {
			width: 10%;
			padding: 0;
			cursor: pointer;
			font-size: 20.5px;
			color: $site-copy-color;
			text-align: center;
		}
	}
	.holding-title {
		margin-top: 150px;
		@include respond-to(tablet) {
			margin-top: 13vw;
		}
	}
	.logo-bottom {
		max-width: 100%;
		display: block;
		margin: 0 auto;
		padding-top: 5vw;
	}
	.desc-tab {
		display: none;
		@include respond-to(tablet) {
			display: block;
		}
	}
	.only-mobile {
		display: block;
		@include respond-to(tablet) {
			display: none;
		}
	}
}
.checked-word {
	padding: 10px;
}
/*****************
MOBILE LANDSCAPE
*****************/
/*****************
TABLET
*****************/
/*****************
TABLET - LANDSCAPE
*****************/
/*****************
DESKTOP
*****************/
.wodrIsChecked {
	display: flex;
	align-items: center;
	&after {
		border: solid 2px #cf122e;
	}
	.point {
		background: #cf122e;
		margin-left: 8%;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		display: inline-block;
		width: 1.8vw;
		height: 1.8vw;
		@include respond-to(tablet) {
			width: 0.8em;
			height: 0.8em;
			margin-left: 12%;
		}
	}
}
.crossword {
	.row + .row {
		@include respond-to(tablet) {
			display: table;
			width: 100%;
		}
	}
	.row .tooltip-top-left a {
		margin: 0;
	}
	.padding-p {
		margin: 35px 0 !important;
	}
	.cross-word {
		td {
			vertical-align: middle;
			height: 0em;
			font-family: Andes_Medium;
			@include font-vw($mobile: 24px, $tablet: 20px, $desktop-lg: 24px);
		}
	}
	.words-to-find .tooltip-top-left {
		text-align: left;
		margin-top: 4%;
		@include respond-to(tablet) {
			margin: 0;
		}
		h3 {
			@include font-vw($mobile: 20px, $tablet: 11px, $desktop-lg: 17px);
			margin: 0;
			padding: 1% 0 5% 3.5%;
			color: #cf122e;
			text-align: left;
			text-transform: uppercase;
			@include respond-to(tablet) {
				padding: 0 0 5% 0;
			}
			@include respond-to(tablet-only) {
				font-size: 1.3vw;
			}
		}
		.checked-word {
			p {
				width: 100%;
				padding-bottom: 10px;
				@include respond-to(tablet) {
					min-width: 150px;
				}
			}
		}
		> p {
			width: 100%;
			padding-left: 3.5%;
			column-count: 2;
			@include respond-to(tablet) {
				column-count: 1;
				width: 100%;
				padding-left: 0;
			}
		}
		p {
			font-family: Andes_Medium;
			margin: 0 0 3%;
			@include font-vw($mobile: 20px, $tablet: 14px, $desktop-lg: 14px);
			@include respond-to(tablet) {
				line-height: 1.5;
				margin: 0 !important;
			}
		}
	}
}

.login-info-section {
	@include respond-to(mobile-only) {
		background: url("/Content/img/wordsearch/word-search-mobile.jpg") no-repeat top center;
		background-size: 100%;
		background-color: #0a0a0a;
	}
}

// Colors variables

$white: #ffffff;
$black: #000000;
$black-input: #252525;
$grey: #606060;
$dark-grey: #434343;
$dark-grey2: #404040;
$light-grey: #f4f4f4;
$btn-text-grey: #2c2b26;
$yellow: #ffe006;
$yellow2: #ffe800;
$yellow3: #fed100;
$dark-yellow: #e1c503;
$btn-yellow: #fce30c;
$red: #da2037;
$red2: #bd1924;
$red3: #cf122e;
$red4: #d8243c;
$red5: #ea0808;
$dark-red: #810e16;
$green: #007b3d;
$grey3: #f6f6f6;
$grey4: #b9b9b9;
$grey5: #454545;
$grey6: #d9d9d9;
$grey7: #f1f1f1;
$grey8: #d2d2d2;
$grey9: #463f2a;
$grey10: #dedede;
$grey11: #4a4a4a;
$grey12: #393939;
$blue: #2a99e0;
$navyblue: #4267b2;
$grey2light: $grey; //* colorbase for RGBA mixin *//
$grey2lightRGBA: rgba($grey, 0.68);
$transparent: rgba(255, 255, 255, 0);
$label-bg: #252525;
$errorcolor: #db1919;
// mixin for rgba color
@mixin rgbaForOldBrowser($hexcolor, $opacity) {
	//opacity - beetween 0-100
	color: $hexcolor;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacity)";
	filter: alpha(opacity=$opacity);
	color: rgba($hexcolor, $opacity/100);
}

// mixin for rgba background color
@mixin rgbaBgForOldBrowser($hexcolor, $opacity) {
	//opacity - beetween 0-100
	background-color: $hexcolor !important;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$opacity)" !important;
	filter: alpha(opacity=$opacity) !important;
	background-color: rgba($hexcolor, $opacity/100) !important;
}

/////////////////////////////
///	GLOBAL - COLORS
/////////////////////////////
$colorlead: $green;
$content-hr-color: #2c2b26;
$content-hr-betweeninmobile-color: #2c2b26;

/////////////////////////////
///	SOCIAL - COLORS
/////////////////////////////
$twitter-bg-color: $blue;
$twitter-border-color: $twitter-bg-color;
$twitter-hover-color: #098ce0;

$facebook-bg-color: $navyblue;
$facebook-border-color: $facebook-bg-color;
$facebook-hover-color: #27488c;

$social-icon-color: $white;
$social-bg-color: $blue;

/////////////////////////////
///	FORM - COLORS
/////////////////////////////
$grm-bg-color: $blue;
$placeholder-color: $grey;
$placeholderError-color: $red;

$error-bg-color: $red;
$error-bg-color-grm: $red;
$batch-code-error: $red3;

/////////////////////////////
///	FOOTER - COLORS
/////////////////////////////
//footer colors - mobile

/////////////////////////////
///	YELLOW BUTTON - COLORS
/////////////////////////////
$button-yellow-color: $btn-text-grey;
$button-bgGradientTop: $yellow2;
$button-bgGradientBottom: $yellow3;

$inactiveButton-yellow-color: $dark-grey;
$inactiveButton-bgGradientTop: $yellow2;
$inactiveButton-bgGradientBottom: $yellow3;

/////////////////////////////
///	RED BUTTON - COLORS
/////////////////////////////
$button-red-color: $white;

$inactiveButton-red-color: $white;

/////////////////////////////////
///	TEMPLATES - RED HEADER  - COLORS
/////////////////////////////////
$limit-exceed-header: $btn-text-grey;
$limit-exceed-para: $btn-text-grey;

/////////////////////////////////
///	TEMPLATES - SEMI-WHITE COLUMNS  - COLORS
/////////////////////////////////
$transWhiteCol-bgno-color: $transparent;
$transWhiteCol-text-color: $white;

/////////////////////////////////
///	TEMPLATES - WHITE COLUMNS  - COLORS
/////////////////////////////////
$whiteCol-bg-color: $white;
$whiteCol-text-color: $grey5;

/////////////////////////////////
///	TEMPLATES - LOGIN/REGISTER  - COLORS
/////////////////////////////////
$grm-error-bagckground-color: $red;
$grm-error-color: $red5;

$grm-input-invalid-border-color: $red;
$grm-input-invalid-color: $red;

$placeholder-invalid-color: $red;

/////////////////////////////////
///	SHIPPING  - COLORS
/////////////////////////////////
$shipping-input-color--mobile: $dark-grey;

$shipping-select-color--mobile: $dark-grey;

$shipping-firstrow-afterelement-color--mobile: $red2;
$shipping-firstrow-afterelement-color--mobileex: $red2;

//white

$error-color--mobile: $white;
$error-color--tablet: $white;
$error-color--desktop: $white;

/* spinner loader preloader */
$spinner-layout-bg:$bodybgcolor;
$spinner-transition-property: all;
$spinner-transition-duration: .10s;
$spinner-transition-timing-function: linear;
$spinner-obj-size: 64px;
@mixin hpmSpinner($layout-bg: $spinner-layout-bg, $tProperty: $spinner-transition-property, $tDuration: $spinner-transition-duration, $tTimingFunction: $spinner-transition-timing-function){
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: $layout-bg;
    z-index: 9999;
    transition:$tProperty $tTimingFunction $tDuration;
    &.loaded{
      background: rgba(0,0,0,0.5);
    }
    &.hide{
      opacity:0;
    }
    #spinnerObj{
        @include spinnerObj($size: $spinner-obj-size);
    }
}
@mixin spinnerObj($size: $spinner-obj-size){
    position: relative;
    width: $size;
    height: $size;
    left: 50%;    
    top: 50%;
    transform: translate(-50%, -50%)
    // margin-left: -64px;
    // margin-top: -64px;
    // -moz-transform: scale(0.6);
    // -webkit-transform: scale(0.6);
    // -ms-transform: scale(0.6);
    // -o-transform: scale(0.6);
    // transform: scale(0.6);
}
.hpmSpinner{ @include hpmSpinner(); }
// .hpmSpinner{
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: $layout-bg;
//     z-index: 9999;
//     // -moz-transition:all linear .3s;
//     // -webkit-transition:all linear .3s;
//     // -ms-transition:all linear .3s;
//     // -o-transition:all linear .3s;
//     transition:$tProperty $tTimingFunction $tDuration;
//     &.loaded{
//       background: rgba(0,0,0,0.5);
//     }
// }
// .hpmSpinner.ng-hide{
//   opacity:0;
// }
// #floatingCirclesG {
//   position: relative;
//   width: 128px;
//   height: 128px;
//   left: 50%;
//   margin-left: -64px;
//   top: 50%;
//   margin-top: -64px;
//   -moz-transform: scale(0.6);
//   -webkit-transform: scale(0.6);
//   -ms-transform: scale(0.6);
//   -o-transform: scale(0.6);
//   transform: scale(0.6);
// }
.f_circleG {
  position: absolute;
  background-color: #FFFFFF;
  height: 25%;//23px;
  width: 25%;//23px;
  -moz-border-radius: 100%;
  -moz-animation-name: f_fadeG;
  -moz-animation-duration: 1.04s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-direction: linear;
  -webkit-border-radius: 100%;
  -webkit-animation-name: f_fadeG;
  -webkit-animation-duration: 1.04s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: linear;
  -ms-border-radius: 100%;
  -ms-animation-name: f_fadeG;
  -ms-animation-duration: 1.04s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-direction: linear;
  -o-border-radius: 100%;
  -o-animation-name: f_fadeG;
  -o-animation-duration: 1.04s;
  -o-animation-iteration-count: infinite;
  -o-animation-direction: linear;
  border-radius: 100%;
  animation-name: f_fadeG;
  animation-duration: 1.04s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}
#frotateG_01 {
  left: 50%;
  top: 0;//52px;
  transform: translateX(-50%);
  -moz-animation-delay: 0.39s;
  -webkit-animation-delay: 0.39s;
  -ms-animation-delay: 0.39s;
  -o-animation-delay: 0.39s;
  animation-delay: 0.39s;
}
#frotateG_02 {
  right: 12.5%;//;eft:15px;
  top: 12.5%;//15px;
  transform: translate(12.5%, -12.5%);
  -moz-animation-delay: 0.52s;
  -webkit-animation-delay: 0.52s;
  -ms-animation-delay: 0.52s;
  -o-animation-delay: 0.52s;
  animation-delay: 0.52s;
}
#frotateG_03 {
  //left: 52px;
  // top: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-animation-delay: 0.65s;
  -webkit-animation-delay: 0.65s;
  -ms-animation-delay: 0.65s;
  -o-animation-delay: 0.65s;
  animation-delay: 0.65s;
}
#frotateG_04 {
  // right: 15px;
  // top: 15px;
  right: 12.5%;
  bottom: 12.5%;
  transform: translate(12.5%, 12.5%);
  -moz-animation-delay: 0.78s;
  -webkit-animation-delay: 0.78s;
  -ms-animation-delay: 0.78s;
  -o-animation-delay: 0.78s;
  animation-delay: 0.78s;
}
#frotateG_05 {
  // right: 0;
  // top: 52px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  -moz-animation-delay: 0.91s;
  -webkit-animation-delay: 0.91s;
  -ms-animation-delay: 0.91s;
  -o-animation-delay: 0.91s;
  animation-delay: 0.91s;
}
#frotateG_06 {
  // right: 15px;
  // bottom: 15px;
  left: 12.5%;
  bottom: 12.5%;
  transform: translate(-12.5%, 12.5%);
  -moz-animation-delay: 1.04s;
  -webkit-animation-delay: 1.04s;
  -ms-animation-delay: 1.04s;
  -o-animation-delay: 1.04s;
  animation-delay: 1.04s;
}
#frotateG_07 {
  // left: 52px;
  // bottom: 0;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-animation-delay: 1.17s;
  -webkit-animation-delay: 1.17s;
  -ms-animation-delay: 1.17s;
  -o-animation-delay: 1.17s;
  animation-delay: 1.17s;
}
#frotateG_08 {
  // left: 15px;
  // bottom: 15px;
  left: 12.5%;
  top: 12.5%;
  transform: translate(-12.5%, -12.5%);
  -moz-animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
  -ms-animation-delay: 1.3s;
  -o-animation-delay: 1.3s;
  animation-delay: 1.3s;
}
@-moz-keyframes f_fadeG {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: #FFFFFF;
  }
}
@-webkit-keyframes f_fadeG {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: #FFFFFF;
  }
}
@-ms-keyframes f_fadeG {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: #FFFFFF;
  }
}
@-o-keyframes f_fadeG {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: #FFFFFF;
  }
}
@keyframes f_fadeG {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: #FFFFFF;
  }
}

#reg-container {
    .KSTL-Registration {
        form {
            @include respond-to(mobile-only) {
                padding: 0 2px;
            }
        }
        .pod {
            .ng-form {
                > div > div {
                    margin-bottom: $grm-fcontrol-marginbottom--mobile;

                    @include respond-to(tablet) {
                        margin-bottom: $grm-fcontrol-marginbottom--tablet;
                        padding-bottom: 15px;
                    }

                    &#KSTL-Registration-wrapper-ContinueButton,
                    &#KSTL-Registration-wrapper-SendEmailButton {
                        margin-bottom: 0;
                    }
                }
                [id*=KSTL-Registration-wrapper-]{
                    >div:first-child>div>label>span:first-child{
                        display: none;
                    }
                }
                button {
                    > span {
                        padding: $button-padding--mobile;
                    }
                }

                &.terms-privacypolicy {
                    label {
                        text-transform: inherit;
                    }
                }

                #KSTL-Registration-wrapper-ContinueLink {
                    button {
                        @include respond-to(tablet) {
                            overflow: hidden;
                        }
                    }
                }

                #KSTL-Registration-wrapper-ItalyMediaURL1,
                #KSTL-Registration-wrapper-ItalyMediaURL2,
                #KSTL-Registration-wrapper-ItalyMediaURL3,
                #KSTL-Registration-wrapper-ItalyBatchCode1_20,
                #KSTL-Registration-wrapper-ItalyBatchCode2_20,
                #KSTL-Registration-wrapper-ItalyBatchCode3_20,
                #KSTL-Registration-wrapper-ItalyUPC1,
                #KSTL-Registration-wrapper-ItalyUPC2 {
                    display: none;
                }

                #KSTL-Registration-wrapper-Password,
                #KSTL-Registration-wrapper-PasswordConfirm,
                #KSTL-Registration-wrapper-Country {
                    > div > div:nth-of-type(2) {
                        display: flex;
                        flex-direction: column;
                        div:not(.error-field),.error-field {
                            order: 2;
                         }
                    }
                }
                #KSTL-Registration-wrapper-Country {
                    > div > div:nth-of-type(2) {
                       /* flex-direction: row;*/
                       flex-direction: column-reverse;
                    }
                }
                #KSTL-Registration-wrapper-TermsConditions1,
                #KSTL-Registration-wrapper-PrivacyPolicy,
                #KSTL-Registration-wrapper-WinnerConsent {
                    > div {
                        display: flex;
                        flex-direction: column;
                        .error-field {
                            order: 2;
                            p {
                                padding-bottom: 0;
                            }
                        }
                    }
                }

                // terms and receive information
                #KSTL-Registration-wrapper-TermsConditions,
                #KSTL-Registration-wrapper-TermsandConditions {
                    margin-top: $grm-terms-margintop--mobile;

                    @include respond-to(desktop) {
                        margin-top: $grm-terms-margintop--desktop;
                    }
                }

                #KSTL-Registration-wrapper-TermsConditions,
                #KSTL-Registration-wrapper-TermsandConditions,
                #KSTL-Registration-wrapper-HeaderSubscription,
                #KSTL-Registration-wrapper-PrivacyPolicy,
                #KSTL-Registration-wrapper-TermsConditionPrvcyPolicyCombo,
                #KSTL-Registration-wrapper-TermsConditions1,
                #KSTL-Registration-wrapper-Header_additional_label,
                #KSTL-Registration-wrapper-EmailMsgLabel,
                #KSTL-Registration-wrapper-ContinueButton1,
                #KSTL-Registration-wrapper-WinnerConsent {
                    flex: 0 0 100%;
                    margin-left: 0;
                    margin-right: 0;
                    padding: $grm-login-label-padding--mobile;

                    @include respond-to(tablet) {
                        padding: $grm-login-label-padding--tablet;
                    }

                    > div {
                        @include respond-to(tablet) {
                            margin: 0;
                            width: $grm-wraperterms-width--tablet;
                        }
                    }

                    label {
                        text-align: left;
                        text-transform: none;
                        font-family: $font-andes-regular;
                        width: 100%;
                        max-width: 100%;
                        @include font-vw(
                            $mobile: $grm-checkbox-label-fs--mobile,
                            $tablet: $grm-checkbox-label-fs--tablet,
                            $desktop: $grm-checkbox-label-fs--desktop
                        );

                        a {
                            display: inline;
                            text-transform: none;
                            vertical-align: baseline;
                            font-family: $font-andes-regular;
                            color: $grm-link-color;
                            padding: 0;
                            @include font-vw(
                                $mobile: $grm-checkbox-label-fs--mobile,
                                $tablet: $grm-checkbox-label-fs--tablet,
                                $desktop: $grm-checkbox-label-fs--desktop
                            );
                        }

                        a:hover {
                            text-decoration: underline;
                        }
                    }

                    div.subscription,
                    div.privacypolicy,
                    div.terms {
                        border: 0;
                        padding: 0.5em 0 0;
                        min-height: 3.2em;
                    }

                    div.subscription.subscription-EM {
                        background-image: none;

                        &:nth-child(2) {
                            display: none;
                        }
                    }

                    .error-field {
                        @include respond-to(tablet) {
                            padding-left: 0;
                        }

                        p {
                            font-size: 12px;
                            @include respond-to(tablet) {
                                margin: -1.2em 0 $grm-checkbox-margintop--tablet -0.2em;
                            }

                            @include respond-to(desktop) {
                                margin: -0.2em 0 $grm-checkbox-margintop--desktop;
                            }
                        }
                    }
                }

                #KSTL-Registration-wrapper-EmailMsgLabel {
                    text-align: center;
                    margin-bottom: 20px;
                    > div {
                        @include font-vw($mobile: 26px, $tablet: 14px, $desktop: 15px);
                    }
                }
                #KSTL-Registration-wrapper-ContinueButton1 {
                    min-width: 256px;
                }
                #KSTL-Registration-wrapper-ContinueButton,
                #KSTL-Registration-wrapper-ContinueButton1,
                #KSTL-Registration-wrapper-SendEmailButton {
                    padding-bottom: 0;

                    @include respond-to(mobile-only) {
                        padding-left: 0;
                    }

                    .KSTL-Registration-button {
                        > p {
                            margin: 0;
                            padding: 0;
                            text-align: center;

                            > button {
                                @include respond-to(mobile-only) {
                                    max-width: 100%;
                                }
                            }
                        }
                    }
                }

                // checkbox receive and terms
                input[type="text"],
                input[type="email"],
                input[type="tel"],
                input[type="password"] {
                    @include font-vw(
                        $mobile: $grm-input-fs--mobile,
                        $tablet: $grm-input-fs--tablet,
                        $desktop: $grm-input-fs--desktop
                    );
                    @include respond-to(mobile-only) {
                        padding: 3vw;
                    }
                }
                input[type="checkbox"],
                input[type="radio"] {
                    position: absolute;
                    border: 0;

                    & + label,
                    & + div.error-field + label {
                        position: relative;
                        padding: $grm-termslabel-padding--mobile;
                        margin: 0;

                        @include respond-to(tablet) {
                            padding: $grm-termslabel-padding--tablet;
                        }

                        @include respond-to(desktop) {
                            padding: $grm-termslabel-padding--desktop;
                        }

                        span {
                            display: inline-block;
                            vertical-align: middle;
                            background-image: url("/Content/img/icons/celebrate2022-icons_sprite.png");
                            background-repeat: no-repeat;
                            background-position: 0 0;
                            cursor: pointer;
                            position: absolute;
                            left: 1px;
                            top: 0;
                            height: 31px;
                            width: 31px;
                        }
                    }

                    &:checked + label span,
                    &:checked + div.error-field + label span {
                        background-position: 0 -39px;
                    }

                    &:focus,
                    &:active {
                        &.ng-invalid,
                        &.ng-valid {
                            &.ng-dirty {
                                -webkit-box-shadow: none;
                                box-shadow: none;
                            }
                        }
                    }
                }

                input[type="checkbox"] {
                    height: 25px;
                    padding: 15px;
                    margin-top: 1px;
                    width: 25px;
                    margin-left: 2px;
                }

                input[type="checkbox"]:checked {
                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                        width: 0;
                    }
                }

                input[type="radio"] {
                    width: 12px;
                    height: 12px;
                    padding: 0;
                    margin-top: 4px;
                    border-radius: 10px !important;
                    margin-left: 4px;
                }

                #KSTL-Registration-wrapper-Country {
                    @include respond-to(tablet) {
                        .error-field {
                            height: auto;
                            padding-bottom: 10px;
                        }
                    }
                }

                #KSTL-Registration-wrapper-Country + #KSTL-Registration-wrapper-CountryAdditionalLabel {
                    text-align: left;
                    flex: none;
                    @include respond-to(tablet) {
                        position: relative;
                        margin-left: 0;
                        // margin-right: 53%;
                    }
                    a{
                        color: #2c2b26;
                    }
                }

                #KSTL-Registration-wrapper-Country {
                    text-align: left;

                    > div {
                        display: inline-block;
                        vertical-align: top;
                        margin-bottom: 25px;
                    }
                    label[for="KSTL-Registration-Country"] {
                        margin-bottom: 10px;
                    }

                    input[type="radio"] + label:before {
                        content: "";
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background: url("/Content/img/icons/celebrate2022-icons_sprite.png") no-repeat;
                        background-position: right 0;
                    }

                    input[type="radio"] + label {
                        padding: 0 35px;
                        line-height: 20px;
                        text-transform: none;
                        @include font-vw(
                            $mobile: $grm-checkbox-label-fs--mobile,
                            $tablet: $grm-checkbox-label-fs--tablet,
                            $desktop: $grm-checkbox-label-fs--desktop
                        );
                    }

                    input[type="radio"]:checked + label:before {
                        content: "";
                        background-position: right -25px;
                    }
                }

                // terms and receive information
                #KSTL-Registration-wrapper-TermsConditions1,
                #KSTL-Registration-wrapper-TermsConditionPrvcyPolicyCombo {
                    margin-top: 0;

                    @include respond-to(tablet) {
                        margin-top: 0;
                    }
                }

                #KSTL-Registration-wrapper-TermsConditionPrvcyPolicyCombo {
                    label {
                        text-transform: inherit;
                    }
                }

                #KSTL-Registration-wrapper-HeaderSubscription {
                    padding-top: 0;
                    margin-left: 0;

                    @include respond-to(tablet) {
                        margin-bottom: 0;
                        min-height: 0;
                    }
                    b {
                        display: none !important;
                    }

                    .error-field {
                        height: auto;
                    }

                    .ng-scope {
                        div[display-field] {
                            .ng-binding {
                                display: none;
                            }
                        }
                    }

                    b,
                    br {
                        display: none;
                    }
                }

                .privacypolicy,
                .terms {
                    @include respond-to(tablet) {
                        min-height: $grm-terms-mheight--tablet; //neded? checked..
                    }
                }

                #KSTL-Registration-wrapper-TermsConditions1,
                #KSTL-Registration-wrapper-PrivacyPolicy,
                #KSTL-Registration-wrapper-HeaderSubscription,
                #KSTL-Registration-wrapper-TermsConditionPrvcyPolicyCombo,
                #KSTL-Registration-wrapper-WinnerConsent {
                    min-height: 30px;
                    margin-bottom: 0;

                    div.privacypolicy,
                    div.subscription,
                    div.terms {
                        padding-top: 0;
                        min-height: auto;
                    }

                    label {
                        min-height: 31px;
                        padding-top: 0;

                        @include respond-to(desktop) {
                            padding-top: 5px;
                        }
                    }
                }
                #KSTL-Registration-wrapper-TermsConditions1,
                #KSTL-Registration-wrapper-PrivacyPolicy,
                #KSTL-Registration-wrapper-HeaderSubscription {
                    padding-top: 0;
                    .label-after {
                        b {
                            display: inline-flex;
                            padding-top: 5px;
                        }
                        span:last-of-type {
                            width: 80%;
                            height: 32px;
                            // display: inline-flex;
                            // flex-wrap: wrap;
                            align-items: center;
                            margin: 0 0 0 52px;
                            background: none;
                            @include respond-to(tablet) {
                                width: 90%;
                                margin-top: 6px;

                            }
                        }
                    }
                    .subscription {
                        .error-field {
                            padding: 0;
                        }

                        .label-after {
                            @include respond-to(mobile-only) {
                                padding-top: 0;
                            }
                        }
                    }
                }

                #KSTL-Registration-wrapper-PrivacyPolicy {
                    label.label-after {
                        padding-top: 5px;
                        @include respond-to(tablet) {
                            padding-top: 0;
                        }

                        @include respond-to(desktop) {
                            padding-top: 5px;
                        }
                        span:last-of-type{
                            margin-top: 6px;
                        }
                    }
                }
            }
            
            // capcha style
            #KSTL-Registration-recaptcha-Registration {
                > div > div {
                    margin: 0 auto;
                }

                margin: 0;

                &.recaptcha {
                    @media only screen and (max-width: 413px) {
                        transform: scale(0.9);
                        -webkit-transform: scale(0.9);
                        transform-origin: 0 0;
                        -webkit-transform-origin: 0 0;
                        width: 335px;
                    }

                    @media only screen and (max-width: 374px) {
                        transform: scale(0.77);
                        -webkit-transform: scale(0.77);
                        transform-origin: 0 0;
                        -webkit-transform-origin: 0 0;
                        width: 285px;
                    }
                }
            }
        }
    }
}

/////////////////////////////
/// REGISTER - STYLE
/////////////////////////////
// only register page style
.register-page {
    h1,
    h3 {
        font-weight: bold;
        @include respond-to(mobile-only) {
            margin: 2.5vw;
        }

        margin-bottom: 2.5vw;
        text-align: center;
        @include font-vw($mobile: 36px, $tablet: 25px, $desktop: 30px);
    }

    .KSTL-Registration .pod .success-message p {
        color: #b94a48;
    }

    h1,
    .register-heading {
        text-transform: none;
        @include font-vw($mobile: 36px, $tablet: 25px, $desktop: 30px);
        font-family: $font-andes-medium;

        @include respond-to(tablet) {
            margin-bottom: 1.5vw;
        }
    }

    p {
        line-height: $register-text-lineheight--mobile;
        text-align: $register-text-textalign--mobile;
        @include font-vw(
            $mobile: $register-text-fs--mobile,
            $tablet: $register-text-fs--tablet,
            $desktop: $register-text-fs--desktop
        );

        @include respond-to(tablet) {
            padding: $register-text-padding--tablet;
            text-align: center;
        }
        @include respond-to(mobile-only) {
            margin: 2vw 0;
        }
    }

    .hiding {
        width: 0;
        height: 0;
        opacity: 0;
    }

    .hide {
        display: none;
    }

    p {
        line-height: $register-text-lineheight--mobile;
        text-align: $register-text-textalign--mobile;
        margin-bottom: 0;
        padding-bottom: 0;
        @include font-vw(
            $mobile: $register-text-fs--mobile,
            $tablet: $register-text-fs--tablet,
            $desktop: $register-text-fs--desktop
        );

        @include respond-to(tablet) {
            padding: $register-text-padding--tablet;
            text-align: center;
            padding-bottom: 0;
        }
    }
    p.mndtry-desc{
        margin-bottom: 20px;
    }
    // two cols grm register form
    .register-form {
        .form-group {
            display: flex;
            flex-wrap: wrap;
        }
        .KSTL-Registration-Registration div[name="signupForm"] > div {
            display: flex;
            flex-flow: wrap;

            @include respond-to(tablet) {
                margin-top: 1vw;
            }
        }

        [id*="KSTL-Registration-wrapper"] {
            position: relative;
            flex: 0 0 100%;
            color: $site-copy-color;
            font-family: $font-andes-regular;

            @include respond-to(tablet) {
                flex: 0 0 47%;
                margin-left: $grm-colleft-paddingleft--tablet;
            }

            &:nth-child(2n + 2) {
                @include respond-to(tablet) {
                    margin-left: $grm-colright-paddingleft--tablet;
                    margin-right: $grm-colright-paddingright--tablet;
                }
            }
        }
    }
    .error-form {
        p {
            margin: 3vw 0 0;
            padding: 0;
        }
    }
}
#reg-container .KSTL-Registration .pod .ng-form {
    #KSTL-Registration-wrapper-PrivacyPolicy .PrivacyPolicy{
        &:focus,
        &:active {
        border: 2px solid $black;
        }
    }
    button{
        >span{
        display: inline-block;
        >i{
            right: -50%;
            transform: translateX(50%);
        }
    }
    
}
}